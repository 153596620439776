import React from "react";
import SectionTitle from "../../../components/section-title";
import ServiceBox from "../../../components/service-box";
import ServiceData from "../../../data/service.json";
import { useTranslation } from "react-i18next";
import Button from "../../../components/button";

const ServiceListContainer = () => {
    const { t } = useTranslation();
    return (
        <div className="service-section section-pt position-relative mb-10">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-8 mx-auto mt-10 mb-10" style={{textAlign:'center'}}>
                        <SectionTitle
                            classOption="title-section mb-5  text-center"
                            title={t("service-section-title")}
                            excerptClassOption="null"
                            excerpt={t("service-section-excerpt")}
                        />
                        <Button
                            classOption="btn btn-lg btn-dark btn-hover-dark mb-10"
                            text={t("icon-box-container-btn")}
                            path={
                                process.env.PUBLIC_URL +
                                "/service-details/1"
                            }
                        />
                    </div>
                </div>

                <div className="row mb-n7 align-items-center">
                    <div className="col-md-6 col-xl-4 mb-7">
                        <div className="service-media-wrapper media-spacing-left">
                            {ServiceData &&
                                ServiceData.slice(0, 3).map((single, key) => {
                                    return (
                                        <ServiceBox data={single} key={key} />
                                    );
                                })}
                        </div>
                    </div>

                    <div className="col-xl-4 mb-7 order-md-1 order-xl-0">
                        <div className="service-media-img text-center">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/service/harvest.jpg`}
                                alt="images_not_found"
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-4 mb-7">
                        <div className="service-media-wrapper media-spacing-right">
                            {ServiceData &&
                                ServiceData.slice(3, 6).map((single, key) => {
                                    return (
                                        <ServiceBox data={single} key={key} />
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceListContainer;
