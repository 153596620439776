import React from "react";
import "../assets/scss/_btms.scss"; // Adjust the path if necessary

const VerifyEmail = () => {
    return (
        <div className="verify-email-container">
            <div className="message-box">
                <h1>Verify Your Email</h1>
                <p>
                    Please check your email inbox for the confirmation link to
                    continue the registration process.
                </p>
                <p>
                    Don not forget to check your spam or bin folder if you can
                    not find the email.
                </p>
            </div>
        </div>
    );
};

export default VerifyEmail;
