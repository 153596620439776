import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/scss/_btms.scss';

const BTMSLanding = () => {
    return (
        <div className='landing'>
            <h1>Welcome to BTMS</h1>
            <div className="links-container">
                <Link to="/btms/login" className="landing-link">I already have an account</Link>
                <Link to="/btms/signup" className="landing-link">I am a new user</Link>
            </div>
        </div>
    );
};

export default BTMSLanding;
