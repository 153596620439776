import React from "react";
import SwiperCore, { Autoplay } from 'swiper'; 
import { Swiper, SwiperSlide } from "swiper/react";
import HomeData from "../../../data/home.json";
import Brand from "../../../components/brand/index";
import SectionTitle from "../../../components/section-title";
import { useTranslation } from "react-i18next";

SwiperCore.use([Autoplay]);

const BrandContainer = () => {
    const { t } = useTranslation();
    const swiperOption = {
        loop: true,
        speed: 1000,
        autoplay: {
            delay: 1200,
            disableOnInteraction: false
        },
        slidesPerView: 4,
        spaceBetween: 0,
        pagination: false,
        navigation: false,
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 3,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 3,
            },
            // when window width is >= 640px
            768: {
                slidesPerView: 5,
            },

            // when window width is >= 640px
            992: {
                slidesPerView: 6,
            },
        },
    };
    return (
        <div className="brand-section section-py">
            <div className="container">
                <SectionTitle
                    classOption="title-section text-center"
                    title={t("brand-title")}
                />

                <div className="row mt-10">
                    <div className="col-12 d-flex">
                        <Swiper className="brand-carousel" {...swiperOption}>
                            {HomeData[1].brand &&
                                HomeData[1].brand.map((single, key) => {
                                    return (
                                        <SwiperSlide key={key}>
                                            <Brand key={key} data={single} />
                                        </SwiperSlide>
                                    );
                                })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrandContainer;
