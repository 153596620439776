import React, { useState, useEffect, useRef, useContext } from "react";
import Visas from "../components/Visas";
import Hotels from "../components/Hotels";
import Tickets from "../components/Tickets";
import ProgressBar from "../components/ProgressBar";
import Budget from "../components/Budget";
import { Toast } from "react-bootstrap";
import { BTMSContext } from "../BTMSContext";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import sendEmailToManager from "../utils/emailService";
import Confirmation from "../components/Confirmation";
import EmployeeModal from "../components/EmployeeModal";
import ApplicantModal from "../components/ApplicantModal";
import { useHistory } from "react-router-dom";
import { use } from "i18next";
import { useParams } from "react-router-dom";
import CustomModal from "../components/CustomModal";

const Application = () => {
    const baseURL = "https://jrbtms.fly.dev";
    const mongoUserId = sessionStorage.getItem("mongoUserId");
    const [submitted, setSubmitted] = useState(false);
    const [showApplicantModal, setShowApplicantModal] = useState(false);
    const [applicants, setApplicants] = useState([]);
    const [currentApplicant, setCurrentApplicant] = useState(null);
    const [returnToDeparture, setReturnToDeparture] = useState(false);
    const [returnDate, setReturnDate] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [sentApplication, setSentApplication] = useState(false);
    const { applicationId } = useParams();
    const { initialApplicantState, goBack } = useContext(BTMSContext);
    const history = useHistory();

    // Handle modal component
    const [showCustomModal, setShowCustomModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    // Edit mode
    const isEditMode = applicationId != null;
    const [budget, setBudget] = useState({
        travel: "",
        hotel: "",
        social: "",
        subsidy: "",
        communication: "",
        other: "",
        currency: "Euro",
    });
    const formatDateForInput = (date) => {
        if (!date) return "";

        const d = new Date(date);
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    useEffect(() => {
        if (isEditMode) {
            fetch(`${baseURL}/application/${applicationId}`)
                .then((response) => response.json())
                .then((data) => {
                    // Example for simple fields
                    setApplyFor(data.applyFor);
                    setApplicationType(data.applicationType);

                    const updatedSelectedServices = {};

                    if (data.visa?.visas && data.visa?.visas.length > 0) {
                        updatedSelectedServices.visa = true;
                        setVisaSegment(data.visa.visas);
                        const formattedSegments = data.visa.visas.map(
                            (segment) => ({
                                ...segment,
                                departureDate: segment.departureDate
                                    ? formatDateForInput(segment.departureDate)
                                    : null,
                            })
                        );
                        setVisaSegment(formattedSegments);
                    }
                    if (
                        data.flight?.segments &&
                        data.flight?.segments.length > 0
                    ) {
                        updatedSelectedServices.flight = true;
                        setFlightClass(data.flight.class);
                        setFlightType(data.flight.type);
                        setSegments(data.flight.segments);
                        setFlightComments(data.flight.comments);
                        const formattedSegments = data.flight.segments.map(
                            (segment) => ({
                                ...segment,
                                departureDate: segment.departureDate
                                    ? formatDateForInput(segment.departureDate)
                                    : null,
                                leaveDate: segment.leaveDate
                                    ? formatDateForInput(segment.leaveDate)
                                    : null,
                            })
                        );
                        setSegments(formattedSegments);
                    }
                    if (data.hotel?.stays && data.hotel?.stays.length > 0) {
                        updatedSelectedServices.hotel = true;
                        setHotelSegments(data.hotel.stays);
                        const formattedSegments = data.hotel.stays.map(
                            (segment) => ({
                                ...segment,
                                checkInDate: segment.checkInDate
                                    ? formatDateForInput(segment.checkInDate)
                                    : null,
                                checkOutDate: segment.checkOutDate
                                    ? formatDateForInput(segment.checkOutDate)
                                    : null,
                            })
                        );
                        setHotelSegments(formattedSegments);
                    }
                    if (
                        data.ticket?.tickets &&
                        data.ticket?.tickets.length > 0
                    ) {
                        updatedSelectedServices.ticket = true;
                        setTickets(data.ticket.tickets);
                        const formattedSegments = data.ticket.tickets.map(
                            (segment) => ({
                                ...segment,
                                eventDate: segment.eventDate
                                    ? formatDateForInput(segment.eventDate)
                                    : null,
                            })
                        );
                        setTickets(formattedSegments);
                    }

                    // Update the selected services state once with all changes
                    setSelectedServices((prevServices) => ({
                        ...prevServices,
                        ...updatedSelectedServices,
                    }));

                    if (data.budget) {
                        setBudget(data.budget);
                    }
                })
                .catch((error) =>
                    console.error("Error fetching application data:", error)
                );
        }
    }, [applicationId, isEditMode, baseURL]);

    // Progress Bar
    const [currentStep, setCurrentStep] = useState(1);

    //Type of business trip
    const [applyFor, setApplyFor] = useState("");
    const [applicationType, setApplicationType] = useState("");

    const [selectedServices, setSelectedServices] = useState({
        visa: false,
        flight: false,
        hotel: false,
        ticket: false,
    });

    //Segments
    const [visaSegment, setVisaSegment] = useState([
        {
            to: "",
            departureDate: null,
        },
    ]);

    const addVisaSegment = () => {
        setVisaSegment((prevSegments) => [
            ...prevSegments,
            { to: "", departureDate: null },
        ]);
    };

    const deleteVisaSegment = (index) => {
        if (visaSegment.length > 1) {
            const updatedSegments = visaSegment.filter((_, i) => i !== index);
            setVisaSegment(updatedSegments);
        }
    };

    const updateVisaSegment = (index, field, value) => {
        const updatedSegments = visaSegment.map((segment, i) => {
            if (i === index) {
                return { ...segment, [field]: value };
            }
            return segment;
        });
        setVisaSegment(updatedSegments);
    };

    const [showHint, setShowHint] = useState(false);
    const [showHint1, setShowHint1] = useState(false);
    const [departureCitySelected, setDepartureCitySelected] = useState(false);
    const [destinationSelected, setDestinationSelected] = useState([]);
    const [locationSelected, setLocationSelected] = useState("");

    const [segments, setSegments] = useState([
        {
            from: "",
            to: "",
            departureDate: null,
            leaveDate: null,
        },
    ]);

    const [hotelSegments, setHotelSegments] = useState([
        {
            location: "",
            hotelComments: "",
            checkInDate: null,
            checkOutDate: null,
        },
    ]);

    const addHotelSegment = () => {
        setHotelSegments((prevSegments) => [
            ...prevSegments,
            {
                location: "",
                hotelComments: "",
                checkInDate: null,
                checkOutDate: null,
            },
        ]);
    };

    const deleteHotelSegment = (index) => {
        if (hotelSegments.length > 1) {
            const updatedSegments = hotelSegments.filter((_, i) => i !== index);
            setHotelSegments(updatedSegments);
        }
    };

    const updateHotelSegment = (index, field, value) => {
        const updatedSegments = hotelSegments.map((segment, i) => {
            if (i === index) {
                return { ...segment, [field]: value };
            }
            return segment;
        });
        setHotelSegments(updatedSegments);
    };

    // Handle geo-autocomplete
    const departureCityRef = useRef([]);
    const destinationRefs = useRef([]);
    const locationRef = useRef([]);

    useEffect(() => {
        // Initialize Google Places for each departure city input
        departureCityRef.current.forEach((ref, index) => {
            if (ref) {
                const autocomplete = new google.maps.places.Autocomplete(ref);
                autocomplete.addListener("place_changed", () => {
                    const place = autocomplete.getPlace();
                    if (place && place.formatted_address) {
                        updateSegment(index, "from", place.formatted_address);
                    }
                });
            }
        });

        // Initialize Google Places for each destination input
        destinationRefs.current.forEach((ref, index) => {
            if (ref) {
                const autocomplete = new google.maps.places.Autocomplete(ref);
                autocomplete.addListener("place_changed", () => {
                    const place = autocomplete.getPlace();
                    if (place && place.formatted_address) {
                        updateSegment(index, "to", place.formatted_address);
                    }
                });
            }
        });

        locationRef.current.forEach((ref, index) => {
            if (ref) {
                const autocomplete = new google.maps.places.Autocomplete(ref);
                autocomplete.addListener("place_changed", () => {
                    const place = autocomplete.getPlace();
                    if (place && place.formatted_address) {
                        updateHotelSegment(
                            index,
                            "location",
                            place.formatted_address
                        );
                    }
                });
            }
        });
    }, [segments, hotelSegments]);

    const [flightComments, setFlightComments] = useState("");

    const addSegment = () => {
        const lastSegment = segments[segments.length - 1];
        setSegments((prevSegments) => [
            ...prevSegments,
            { from: lastSegment.to, to: "", departureDate: "", leaveDate: "" },
        ]);
    };

    const deleteSegment = (index) => {
        if (segments.length > 1) {
            const updatedSegments = segments.filter((_, i) => i !== index);
            setSegments(updatedSegments);
        }
    };

    const updateSegment = (index, field, value) => {
        const updatedSegments = segments.map((segment, i) => {
            if (i === index) {
                return { ...segment, [field]: value };
            }
            return segment;
        });
        setSegments(updatedSegments);
    };

    const handleFlightTypeChange = (event) => {
        const newFlightType = event.target.value;
        setFlightType(newFlightType);

        if (newFlightType === "multi-city" && segments.length === 1) {
            addSegment();
        }
    };

    const handleDepartureDateBlur = (index, date) => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Set to start of the day for comparison
        const oneYearFromNow = new Date(currentDate);
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

        const departureDate = new Date(date);

        const daysTillDeparture =
            (new Date(segments[0].departureDate) - currentDate) /
            (1000 * 60 * 60 * 24);

        if (departureDate < currentDate) {
            setModalMessage("Departure date must be after today.");
            setShowCustomModal(true);

            updateSegment(index, "departureDate", null); // Set departure date to null
        } else if (departureDate > oneYearFromNow) {
            setModalMessage(
                "Departure date cannot be more than one year from today."
            );
            setShowCustomModal(true);
            updateSegment(index, "departureDate", null); // Set departure date to null
        } else if (daysTillDeparture <= 3) {
            setModalMessage(
                "Reminder: This is an emergency flight booking. Additional charges will apply and booking is not guaranteed."
            );
            setShowCustomModal(true);
        }
    };

    const handleLeaveDateBlur = (index, checkinDate, leaveDate) => {
        const checkin = new Date(checkinDate);
        const leave = new Date(leaveDate);

        if (leave < checkin) {
            setModalMessage(
                "Your leave date cannot be earlier than your check-in date. Please adjust the dates."
            );
            setShowCustomModal(true);
            updateSegment(index, "leaveDate", null); // Set leave date to null
        }
    };

    // Handle services
    const { convertDateToInputFormat } = useContext(BTMSContext);
    const [flightType, setFlightType] = useState("return");
    const [flightClass, setFlightClass] = useState("Economy");

    const handleCheckboxChange = (service) => {
        if (service === "visa" && selectedServices.visa === false) {
            setModalMessage(
                "Reminder: Each consulate has specific document requirements. Our dedicated representative will contact you for guidance and assistance."
            );
            setShowCustomModal(true);
        }

        setSelectedServices((prevState) => ({
            ...prevState,
            [service]: !prevState[service],
        }));
    };

    const [tickets, setTickets] = useState([
        {
            eventDate: null,
            location: "",
            requirement: "",
        },
    ]);

    const addTicketSegment = () => {
        setTickets((prevSegments) => [
            ...prevSegments,
            { eventDate: null, location: "", requirement: "" },
        ]);
    };

    const deleteTicketSegment = (index) => {
        console.log("clicked");
        if (tickets.length > 1) {
            const updatedSegments = tickets.filter((_, i) => i !== index);
            setTickets(updatedSegments);
        }
    };

    const updateTicketSegment = (index, field, value) => {
        const updatedSegments = tickets.map((segment, i) => {
            if (i === index) {
                return { ...segment, [field]: value };
            }
            return segment;
        });
        setTickets(updatedSegments);
    };

    //Symbols
    const fromSymbol = (
        <svg
            className="input-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path d="M18.33 3.57L5.7 9.955l.79.07-1.96-1.478a.75.75 0 0 0-.753-.087l-2.1.925C.73 9.856.359 10.967.817 11.88c.11.22.263.417.45.577l3.997 3.402a2.94 2.94 0 0 0 3.22.4l3.62-1.8-1.084-.671v5.587a1.833 1.833 0 0 0 2.654 1.657l1.88-.932a1.85 1.85 0 0 0 .975-1.226l1.87-7.839-.396.498 3.441-1.707a3.494 3.494 0 1 0-3.11-6.259zm.672 1.342a1.994 1.994 0 0 1 1.775 3.571l-3.44 1.707a.75.75 0 0 0-.396.498l-1.87 7.838a.35.35 0 0 1-.185.232l-1.88.932a.335.335 0 0 1-.486-.304V13.79a.75.75 0 0 0-1.084-.672l-3.62 1.8a1.44 1.44 0 0 1-1.578-.197l-3.997-3.402a.35.35 0 0 1 .073-.577l2.067-.91-.754-.087 1.96 1.478a.75.75 0 0 0 .79.07l12.63-6.383zm-3.272.319l-4.46-2.26a1.852 1.852 0 0 0-1.656-.001l-1.846.912a1.85 1.85 0 0 0-.295 3.128l2.573 1.955a.75.75 0 1 0 .908-1.194L8.38 5.816a.35.35 0 0 1 .055-.591l1.845-.912a.351.351 0 0 1 .315 0l4.456 2.256a.75.75 0 0 0 .678-1.338z"></path>
        </svg>
    );

    const toSymbol = (
        <svg
            className="input-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path d="M19.906 12.996l-12.72-6.2.421.672-.006-2.457a.75.75 0 0 0-.386-.654L5.207 3.244c-.95-.468-2.058-.091-2.51.826a1.85 1.85 0 0 0-.187.706l-.295 5.242a2.95 2.95 0 0 0 1.63 2.802l3.618 1.8-.117-1.27-4.461 3.359a1.85 1.85 0 0 0 .289 3.13l1.883.933a1.85 1.85 0 0 0 1.564.037l7.381-3.248-.636-.014 3.44 1.71a3.494 3.494 0 1 0 3.11-6.259.75.75 0 0 0-.334-.078h-.005v.75l.329-.674zm-.658 1.348a.75.75 0 0 0 .329.076h.005v-.75l-.334.672a1.994 1.994 0 1 1-1.774 3.571l-3.44-1.71a.75.75 0 0 0-.636-.014l-7.38 3.247a.35.35 0 0 1-.295-.008l-1.881-.932a.35.35 0 0 1-.053-.59l4.46-3.358a.75.75 0 0 0-.118-1.27l-3.618-1.8a1.45 1.45 0 0 1-.8-1.377l.295-5.238a.35.35 0 0 1 .504-.29l1.975 1.096-.386-.654.006 2.457a.75.75 0 0 0 .421.672l12.72 6.2zm-2.234-2.419l-.9-4.918a1.85 1.85 0 0 0-.995-1.32l-1.853-.92a1.85 1.85 0 0 0-2.669 1.657v3.234a.75.75 0 0 0 1.5 0V6.425a.35.35 0 0 1 .505-.313l1.85.918a.35.35 0 0 1 .187.249l.9 4.916a.75.75 0 1 0 1.475-.27z"></path>
        </svg>
    );

    const symbol = (
        <svg
            className="input-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path d="M12 15.75A1.125 1.125 0 1 0 12 18a1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zm.75-2.25V5.25a.75.75 0 0 0-1.5 0v8.25a.75.75 0 0 0 1.5 0zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z"></path>
        </svg>
    );

    // Handle applicant profile
    const [applicantProfileData, setApplicantProfileData] = useState(
        initialApplicantState
    );

    useEffect(() => {
        setApplicantProfileData((prevState) => ({
            ...prevState,
            userId: mongoUserId,
        }));
    }, [mongoUserId]);

    useEffect(() => {
        fetch(`${baseURL}/applicant`)
            .then((response) => response.json())
            .then((data) => setApplicants(data))
            .catch((error) =>
                console.error("Error fetching companies:", error)
            );
    }, []);

    useEffect(() => {
        if (applicants) {
            const applicantsOfUser = applicants.filter(
                (app) => app.userId?._id == mongoUserId
            );

            setCurrentApplicant(applicantsOfUser);
        }
    }, [applicants]);

    useEffect(() => {
        console.log("currentApplicants are: ", currentApplicant);
    }, [currentApplicant]);

    const handleApplicantSubmit = async (e) => {
        e.preventDefault();

        const method = applicantProfileData._id ? "PUT" : "POST";
        const url = applicantProfileData._id
            ? `${baseURL}/applicant/${applicantProfileData._id}`
            : `${baseURL}/applicant`;

        const dataToSend = {
            ...applicantProfileData,
            userId: mongoUserId,
        };

        try {
            const response = await fetch(url, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ applicantProfileData: dataToSend }),
            });

            if (!response.ok) {
                throw new Error("Failed to process applicant");
            }

            const data = await response.json();
            console.log("Applicant processed successfully!", data);

            const applicantId = data._id;
            const applicationIdForBudget =
                sessionStorage.getItem("applicationId");

            console.log("userId", mongoUserId);

            const userResponse = await fetch(
                `${baseURL}/profile/${mongoUserId}`,
                {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                }
            );
            if (!userResponse.ok) {
                throw new Error("Failed to fetch user data", mongoUserId);
            }
            const userData = await userResponse.json();
            if (!userData.applicants.includes(applicantId)) {
                await fetch(`${baseURL}/profile/${mongoUserId}`, {
                    method: "PATCH",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ applicants: applicantId }),
                });
            }

            await fetch(`${baseURL}/application/${applicationIdForBudget}`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ $push: { applicants: applicantId } }),
            });

            const addMore = window.confirm(
                "Do you want to add more applicants?"
            );
            if (addMore) {
                setShowApplicantModal(true);
                // Reset applicantProfileData or set it to a new applicant template
                setApplicantProfileData(initialApplicantState);
            } else {
                // Proceed to the next step
                setShowApplicantModal(false);
                setCurrentStep(2);
            }
        } catch (error) {
            console.error("Error updating profile: ", applicantProfileData);
        }
    };

    const validateSegments = () => {
        if (selectedServices.visa) {
            const isValidVisa = visaSegment.every((segment) => {
                return (
                    segment.to.trim() !== "" && segment.departureDate !== null
                );
            });

            if (!isValidVisa) {
                return {
                    isValid: false,
                    message: "Visa segment validation failed",
                };
            } else {
                return {
                    isValid: true,
                    message: "Visa segment validation passed",
                };
            }
        }
        if (selectedServices.flight) {
            const isValidFlight = segments.every((segment) => {
                // Common validation for all flight types
                const isValidCommon =
                    segment.from.trim() !== "" &&
                    segment.to.trim() !== "" &&
                    segment.checkInDate !== null &&
                    flightComments.trim() !== "";

                // Additional validation for 'return' flight type
                const isValidReturn =
                    flightType === "return" ? segment.leaveDate !== null : true;

                return isValidCommon && isValidReturn;
            });

            if (!isValidFlight) {
                return {
                    isValid: false,
                    message: "Flight segment validation failed",
                };
            } else {
                return {
                    isValid: true,
                    message: "Flight segment validation passed",
                };
            }
        }

        // Validate hotel segments if the hotel service is selected
        if (selectedServices.hotel) {
            console.log(hotelSegments);
            const isValidHotel = hotelSegments.every((segment) => {
                return (
                    segment.location.trim() !== "" &&
                    segment.hotelComments.trim() !== "" &&
                    segment.checkInDate !== null &&
                    segment.checkOutDate !== null
                );
            });

            if (!isValidHotel) {
                return {
                    isValid: false,
                    message: "Hotel segment validation failed",
                };
            } else {
                return {
                    isValid: true,
                    message: "Hotel segment validation passed",
                };
            }
        }

        if (selectedServices.ticket) {
            const isValidTicket = tickets.every((ticket) => {
                return (
                    ticket.eventDate !== null &&
                    ticket.location.trim() !== "" &&
                    ticket.requirement.trim() !== ""
                );
            });

            if (!isValidTicket) {
                return {
                    isValid: false,
                    message: "Ticket segment validation failed",
                };
            } else {
                return {
                    isValid: true,
                    message: "Ticket segment validation passed",
                };
            }
        }
    };

    // Handle errors message
    const [showToast, setShowToast] = useState(false);

    const handleSendApplication = async (showModalOnSuccess = false) => {
        const validationResults = validateSegments();

        if (validationResults.isValid) {
            try {
                const mongoUserId = sessionStorage.getItem("mongoUserId");
                const username = sessionStorage.getItem("username");

                // Flight data
                let flightId = null;

                const sanitizedFlightSegments = segments;

                if (flightType === "multi-city") {
                    for (let i = 1; i < sanitizedFlightSegments.length; i++) {
                        sanitizedFlightSegments[i].from =
                            sanitizedFlightSegments[i - 1].to;
                    }
                }

                if (selectedServices.flight) {
                    if (returnToDeparture) {
                        const returnSegment = {
                            from: sanitizedFlightSegments[
                                sanitizedFlightSegments.length - 1
                            ].to,
                            to: sanitizedFlightSegments[0].from,
                            departureDate: returnDate,
                        };
                        sanitizedFlightSegments.push(returnSegment);
                    }
                    const flightData = {
                        mongoUserId,
                        segments: sanitizedFlightSegments,
                        type: flightType,
                        class: flightClass,
                        comments: flightComments,
                    };

                    const currentDate = new Date();
                    const oneYearFromNow = new Date(currentDate);
                    oneYearFromNow.setFullYear(
                        oneYearFromNow.getFullYear() + 1
                    );

                    const flightResponse = await fetch(`${baseURL}/flights`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(flightData),
                    });

                    if (!flightResponse.ok) {
                        throw new Error(
                            `Failed to save flight data. Server responded with ${flightResponse.status}`
                        );
                    }

                    const flightResult = await flightResponse.json();
                    flightId = flightResult._id; // Assuming the returned object has an _id field
                }

                // Hotel data
                let hotelId = null;

                if (selectedServices.hotel) {
                    const hotelData = {
                        mongoUserId,
                        stays: hotelSegments,
                    };

                    // Assuming you have an endpoint for saving hotel data, similar to the flight one
                    const hotelResponse = await fetch(`${baseURL}/hotels`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(hotelData),
                    });

                    if (!hotelResponse.ok) {
                        throw new Error(
                            `Failed to save hotel data. Server responded with ${hotelResponse.status}`
                        );
                    }

                    const hotelResult = await hotelResponse.json();
                    // Assuming you need the hotelId for some other operations or for referencing
                    hotelId = hotelResult._id;
                }

                // Ticket data
                let ticketId = null;

                if (selectedServices.ticket) {
                    const ticketData = {
                        mongoUserId,
                        tickets: tickets,
                    };

                    // Assuming you have an endpoint for saving hotel data, similar to the flight one
                    const ticketResponse = await fetch(`${baseURL}/tickets`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(ticketData),
                    });

                    if (!ticketResponse.ok) {
                        throw new Error(
                            `Failed to save ticket data. Server responded with ${ticketResponse.status}`
                        );
                    }

                    const ticketResult = await ticketResponse.json();
                    // Assuming you need the hotelId for some other operations or for referencing
                    ticketId = ticketResult._id;
                }

                // Visa data
                let visaId = null;

                if (selectedServices.visa) {
                    const visaData = {
                        mongoUserId,
                        visas: visaSegment,
                    };

                    const visaResponse = await fetch(`${baseURL}/visas`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(visaData),
                    });

                    if (!visaResponse.ok) {
                        throw new Error(
                            `Failed to save visa data. Server responded with ${visaResponse.status}`
                        );
                    }

                    const visaResult = await visaResponse.json();
                    visaId = visaResult._id;
                }

                // Generate a name for the application
                let applicationName = `${username} - Trip to `;
                let destinations = [];

                if (selectedServices.flight) {
                    destinations = segments.map((segment) => segment.to);
                } else if (selectedServices.hotel) {
                    destinations = hotelSegments.map((hotel) => hotel.location);
                } else if (selectedServices.visa) {
                    destinations = visaSegment.map((visa) => visa.to);
                } else if (selectedServices.ticket) {
                    destinations = tickets.map((ticket) => ticket.location);
                }

                if (destinations.length === 1) {
                    applicationName += `${destinations[0]}`;
                } else if (destinations.length > 1) {
                    // Join all but the last destination with ", ", and add " and " before the last destination
                    applicationName += `${destinations
                        .slice(0, -1)
                        .join(", ")} and ${
                        destinations[destinations.length - 1]
                    }`;
                }
                let method;
                let url;

                if (!isEditMode && sentApplication === true) {
                    const applicationId =
                        sessionStorage.getItem("applicationId");

                    // Determine if we are creating a new application or updating an existing one
                    method = "PUT";
                    url = `${baseURL}/application/${applicationId}`;
                } else if (isEditMode) {
                    method = "PUT";
                    url = `${baseURL}/application/${applicationId}`;
                } else {
                    method = "POST";
                    url = `${baseURL}/application`;
                }

                const applicationData = {
                    name: applicationName,
                    userId: mongoUserId,
                    applyFor,
                    applicationType,
                    segments: sanitizedFlightSegments,
                    flight: flightId,
                    hotel: hotelId,
                    ticket: ticketId,
                    visa: visaId,
                };

                console.log(method, url);

                const applicationResponse = await fetch(url, {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(applicationData),
                });

                if (!applicationResponse.ok) {
                    throw new Error(
                        `Failed to send application. Server responded with ${applicationResponse.status}`
                    );
                }
                if (showModalOnSuccess) {
                    setShowConfirmModal(true); // Show modal only if showModalOnSuccess is true
                } else {
                    if (applyFor !== "myself") {
                        setShowApplicantModal(true);
                    } else {
                        setShowModal(true);
                    }
                }
                setSentApplication(true);
                setShowToast(true);
                const data = await applicationResponse.json();
                sessionStorage.setItem("applicationId", data._id);
            } catch (error) {
                console.error("Failed to send application:", error);
            }
        } else {
            console.log("validate failed", validationResults.message);
            setSubmitted(true); // Show validation errors
        }
    };

    const handleSaveAndLeave = async () => {
        await handleSendApplication(true);
    };

    const confirmSaveAndLeave = () => {
        setShowConfirmModal(false);
        sessionStorage.removeItem("applicationId");
        history.push("/btms/frontpage");
    };

    const handleNext = async () => {
        await handleSendApplication(false);
    };

    // About Modal
    const { initialState } = useContext(BTMSContext);
    const [profileData, setProfileData] = useState(initialState);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (!mongoUserId) {
            console.log("User ID not available yet");
            return; // Exit the useEffect if mongoUserId is not available
        }

        const fetchProfileData = async () => {
            try {
                const response = await fetch(
                    `${baseURL}/profile/${mongoUserId}`
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch profile data");
                }
                const data = await response.json();

                setProfileData((prevState) => ({
                    ...prevState,
                    ...data,
                    name: data?.name || "",
                    birth: data?.birth
                        ? convertDateToInputFormat(data?.birth)
                        : null,
                    sex: data?.sex || "",
                    nationality: data?.nationality || "",
                    contact: {
                        tel: (data.contact && data.contact?.tel) || "",
                    },
                    passportInfo: {
                        number:
                            (data.passportInfo && data.passportInfo?.number) ||
                            "",
                        expiryDate: data.passportInfo?.expiryDate
                            ? convertDateToInputFormat(
                                  data.passportInfo.expiryDate
                              )
                            : null,
                    },
                    idInfo: {
                        number: (data.idInfo && data.idInfo?.number) || "",
                        expiryDate:
                            data.idInfo && data.idInfo?.expiryDate
                                ? convertDateToInputFormat(
                                      data.idInfo.expiryDate
                                  )
                                : null,
                    },
                }));
            } catch (error) {
                console.error("Error fetching profile data: ", error);
            }
        };

        fetchProfileData();
    }, [mongoUserId]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Validation about age and issue date
            const today = new Date();
            const birthDate = new Date(profileData.birth);
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();

            if (
                monthDifference < 0 ||
                (monthDifference === 0 && today.getDate() < birthDate.getDate())
            ) {
                age--;
            }

            const response = await fetch(`${baseURL}/profile/${mongoUserId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    profileData,
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to update profile");
            }

            console.log("Profile updated successfully!");
            setShowModal(false);
            setCurrentStep(2);
        } catch (error) {
            console.error("Error updating profile: ", error);
        }
    };

    const handleBudgetSubmit = async (budgetData) => {
        const applicationIdForBudget = sessionStorage.getItem("applicationId");
        try {
            const response = await fetch(
                `${baseURL}/application/${applicationIdForBudget}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        budget: budgetData,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to update budget");
            }
            setShowSubmitModal(true);
            if (applicationType === "private") {
                try {
                    const response = await fetch(
                        `${baseURL}/application/${applicationIdForBudget}`,
                        {
                            method: "PATCH",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                status: "Approved",
                            }),
                        }
                    );
                    if (response.ok) {
                        console.log(response.body);
                        setCurrentStep(3);
                    } else {
                        console.log("fail to update status");
                    }
                } catch (error) {
                    console.error("Error updating budget: ", error);
                }
            }
        } catch (error) {
            console.error("Error updating budget: ", error);
        }
    };

    // About submit to manger
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [managementList, setManagementList] = useState([]);
    const [selectedManager, setSelectedManager] = useState("");
    const [managerEmail, setManagerEmail] = useState("");

    useEffect(() => {
        const userCompanyId = sessionStorage.getItem("companyId");
        const userRole = sessionStorage.getItem("userRole");

        fetch(`${baseURL}/company/${userCompanyId}`)
            .then((response) => response.json())
            .then((data) => {
                // Based on the role, set the list of people the user can submit to
                if (userRole === "user") {
                    setManagementList(data.manager);
                } else if (userRole === "manager") {
                    setManagementList(data.director);
                } else if (userRole === "director") {
                    // Directors submit to themselves - you may need to handle this scenario differently
                    setManagementList(data.director);
                }
            })
            .catch((error) =>
                console.error("Error fetching company details:", error)
            );
        console.log("retrieve the company details", managementList);
    }, []);

    useEffect(() => {
        console.log("the selected manager is: ", selectedManager);

        const fetchManagerData = async () => {
            try {
                if (selectedManager) {
                    const response = await fetch(
                        `${baseURL}/profile/${selectedManager}`
                    );
                    if (!response.ok) {
                        throw new Error("Failed to fetch manager data");
                    }
                    const data = await response.json();
                    setManagerEmail(data.userEmail);
                    console.log("retrieve the manager email!");
                }
            } catch (error) {
                console.error("Error fetching manager data: ", error);
            }
            console.log("the manager email is:", managerEmail);
        };

        fetchManagerData();
    }, [selectedManager]);

    const handleSendEmail = async () => {
        const applicationIdForBudget = sessionStorage.getItem("applicationId");

        // Make an HTTP GET request to retrieve the application data
        const response = await fetch(
            `${baseURL}/application/${applicationIdForBudget}`
        );
        const application = await response.json();

        // Construct the email content based on the application data
        const applicationDetails = `
        <div sytle="padding: 1rem">

    <h2>${application.name}</h2>
    <h3 style="color: gray;">Submission Date: ${new Date(
        application.createTime
    ).toLocaleDateString()}</h3>
    <p>Apply for: ${application.applyFor}</p>
    ${
        application.applicants
            ? application.applicants
                  .map(
                      (applicant) => `
    <p><strong>Applicant:</strong> ${application.userId.name}</p><br>
    `
                  )
                  .join("")
            : ""
    }
    <br>
    <h3>Application Detail</h3>
    <table style="width: 100%; border-collapse: collapse;">
        ${
            application.flight
                ? application.flight.segments
                      .map(
                          (segment) => `
            <tr>
                <td style="border: 1px solid #ddd; padding: 8px;"><strong>Flight:</strong></td>
                <td style="border: 1px solid #ddd; padding: 8px;">${
                    application.flight.type
                } flight</td>
                <td style="border: 1px solid #ddd; padding: 8px;">From: ${
                    segment.from
                } to ${segment.to}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">Departure: ${new Date(
                    segment.departureDate
                ).toLocaleDateString()}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${
                    segment.leaveDate
                        ? `Return: ${new Date(
                              segment.leaveDate
                          ).toLocaleDateString()}`
                        : ""
                }</td>
            </tr>
        `
                      )
                      .join("")
                : ""
        }
        ${
            application.hotel
                ? application.hotel.stays
                      .map(
                          (hotel) => `
            <tr>
                <td style="border: 1px solid #ddd; padding: 8px;"><strong>Hotel:</strong></td>
                <td style="border: 1px solid #ddd; padding: 8px;"> Location: ${
                    hotel.location
                }</td>
                <td style="border: 1px solid #ddd; padding: 8px;"> Check-in: ${new Date(
                    hotel.checkInDate
                ).toLocaleDateString()}   |   Check-out: ${new Date(
                              hotel.checkOutDate
                          ).toLocaleDateString()}</td>
               
            </tr>
        `
                      )
                      .join("")
                : ""
        }
        ${
            application.visa
                ? application.visa.visas
                      .map(
                          (visa) => `
            <tr>
                <td style="border: 1px solid #ddd; padding: 8px;"><strong>Visa:</strong></td>
                <td style="border: 1px solid #ddd; padding: 8px;">To: ${
                    visa.to
                }</td> 
                <td style="border: 1px solid #ddd; padding: 8px;">Departure: ${new Date(
                    visa.departureDate
                ).toLocaleDateString()}</td>
            </tr>
        `
                      )
                      .join("")
                : ""
        }
        ${
            application.ticket
                ? application.ticket.tickets
                      .map(
                          (ticket) => `
            <tr>
                <td style="border: 1px solid #ddd; padding: 8px;"><strong>Ticket:</strong></td>
                <td style="border: 1px solid #ddd; padding: 8px;">Date: ${new Date(
                    ticket.eventDate
                ).toLocaleDateString()}</td> 
                <td style="border: 1px solid #ddd; padding: 8px;">Location: ${
                    ticket.location
                }</td> 
                <td style="border: 1px solid #ddd; padding: 8px;">Requirement: ${
                    ticket.requirement
                }</td>
            </tr>
        `
                      )
                      .join("")
                : ""
        }
        <tr>
            <td style="border: 1px solid #ddd; padding: 8px;"><strong>Budget:</strong></td>
            <td colspan="4" style="border: 1px solid #ddd; padding: 8px;">
                ${
                    application.budget.travel
                        ? `Travel: ${application.budget.travel} ${application.budget.currency}   `
                        : ""
                } 
                ${
                    application.budget.hotel
                        ? `Hotel: ${application.budget.hotel} ${application.budget.currency}   `
                        : ""
                }
                ${
                    application.budget.social
                        ? `Social: ${application.budget.social} ${application.budget.currency}   `
                        : ""
                }
                ${
                    application.budget.subsidy
                        ? `Subsidy: ${application.budget.subsidy} ${application.budget.currency}`
                        : ""
                }
            </td>
        </tr>
    </table>
    <br>
    <a href="https://jrbtms.fly.dev/application/approve/${
        application._id
    }" target="_blank" style="background-color: #0071c2; color: white; padding: 10px 15px; text-decoration: none; border-radius: 5px; margin-right: 10px; cursor: pointer; margin-bottom: 1rem;">Approve</a>
    <a href="https://jrbtms.fly.dev/application/reject/${
        application._id
    }" target="_blank" id="cancel" style="background-color: rgb(255, 149, 0); color: white; padding: 10px 15px; text-decoration: none; border-radius: 5px; margin-left: 10px; cursor: pointer; margin-bottom: 1rem;">Reject</a>
    </div>
`;

        await sendEmailToManager(managerEmail, applicationDetails);
    };

    const handleSubmitToManager = async () => {
        try {
            const applicationIdForBudget =
                sessionStorage.getItem("applicationId");

            const applicationData = {
                managerId: selectedManager,
            };

            const applicationResponse = await fetch(
                `${baseURL}/application/${applicationIdForBudget}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(applicationData),
                }
            );

            if (!managerEmail) {
                console.error("Manager email is not set yet.");
                return;
            }

            if (!applicationResponse.ok) {
                throw new Error(
                    `Failed to send application. Server responded with ${applicationResponse.status}`
                );
            }

            // Send email to manager - If the managerId is updated in MongoDB, send an email to the manager
            console.log("manager email is:", managerEmail);
            await handleSendEmail();
            sessionStorage.removeItem("applicationId");
            setShowSubmitModal(false);
            setCurrentStep(3);
        } catch (error) {
            console.error("Error in handleSubmitToManager: ", error);
        }
    };

    return (
        <div className="application-container">
            <ProgressBar step={currentStep} />

            {currentStep === 1 && (
                <>
                    <h1>New Application</h1>
                    <h2>Type of business trip</h2>
                    <div className="type-information">
                        <div className="container">
                            <span>Apply for: </span>
                            <select
                                value={applyFor}
                                onChange={(e) => setApplyFor(e.target.value)}
                            >
                                <option value="" disabled>
                                    Select an option
                                </option>
                                <option value="myself">Myself</option>
                                <option value="family">Family</option>
                                <option value="colleague">Colleague</option>
                                <option value="customer">Customer</option>
                            </select>
                        </div>

                        <div className="container">
                            <span>Application type: </span>
                            <select
                                value={applicationType}
                                onChange={(e) =>
                                    setApplicationType(e.target.value)
                                }
                            >
                                <option value="" disabled>
                                    Select an option
                                </option>
                                <option value="private">Private</option>
                                <option value="business">Business</option>
                            </select>
                        </div>
                    </div>
                    {applyFor && applicationType && (
                        <>
                            <h2>Service Information</h2>
                            <div className="service-selection">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={selectedServices.flight}
                                        onChange={() =>
                                            handleCheckboxChange("flight")
                                        }
                                    />
                                    Flight
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={selectedServices.hotel}
                                        onChange={() =>
                                            handleCheckboxChange("hotel")
                                        }
                                    />
                                    Hotel
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={selectedServices.visa}
                                        onChange={() =>
                                            handleCheckboxChange("visa")
                                        }
                                    />
                                    Visa
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={selectedServices.ticket}
                                        onChange={() =>
                                            handleCheckboxChange("ticket")
                                        }
                                    />
                                    Ticket
                                </label>
                            </div>

                            <div className="services-content">
                                {selectedServices.visa && <h3>Visa</h3>}
                                {selectedServices.visa &&
                                    visaSegment.map((segment, index) => (
                                        <div key={index}>
                                            <Visas
                                                submitted={submitted}
                                                segment={segment}
                                                segmentIndex={index}
                                                updateSegment={
                                                    updateVisaSegment
                                                }
                                                flightSegments={segments}
                                                flightType={flightType}
                                            />
                                            <div className="button-group">
                                                {index ===
                                                    visaSegment.length - 1 && (
                                                    <button
                                                        onClick={() =>
                                                            addVisaSegment()
                                                        }
                                                    >
                                                        Add Visa
                                                    </button>
                                                )}
                                                {index > 0 &&
                                                    index ===
                                                        visaSegment.length -
                                                            1 && (
                                                        <button
                                                            id="delete-btn"
                                                            onClick={() =>
                                                                deleteVisaSegment(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            Delete Visa
                                                        </button>
                                                    )}
                                            </div>
                                        </div>
                                    ))}

                                {selectedServices.flight && (
                                    <>
                                        <h3>Flight Type and Class</h3>
                                        <div className="flight-type-selection">
                                            <div className="radio-group">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="flightType"
                                                        value="return"
                                                        checked={
                                                            flightType ===
                                                            "return"
                                                        }
                                                        onChange={(e) =>
                                                            setFlightType(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    Return
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="flightType"
                                                        value="single"
                                                        checked={
                                                            flightType ===
                                                            "single"
                                                        }
                                                        onChange={(e) =>
                                                            setFlightType(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    Single
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="flightType"
                                                        value="multi-city"
                                                        checked={
                                                            flightType ===
                                                            "multi-city"
                                                        }
                                                        onChange={
                                                            handleFlightTypeChange
                                                        }
                                                    />
                                                    Multi-city
                                                </label>
                                            </div>
                                            <div className="class-selection">
                                                <label>
                                                    Class:
                                                    <select
                                                        value={flightClass}
                                                        onChange={(e) =>
                                                            setFlightClass(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <option value="Economy">
                                                            Economy
                                                        </option>
                                                        <option value="PremiumEconomy">
                                                            Premium Economy
                                                        </option>
                                                        <option value="Business">
                                                            Business
                                                        </option>
                                                        <option value="First">
                                                            First
                                                        </option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="flight-container">
                                            {segments.map((segment, index) => (
                                                <div
                                                    key={index}
                                                    className="segment"
                                                >
                                                    <div className="trip-information">
                                                        <label>
                                                            <div className="input-wrapper">
                                                                <input
                                                                    className={`city-input ${
                                                                        submitted &&
                                                                        !segment.from &&
                                                                        "error"
                                                                    }`}
                                                                    type="text"
                                                                    value={
                                                                        segment.from
                                                                    }
                                                                    placeholder="From which city?"
                                                                    ref={(
                                                                        ref
                                                                    ) =>
                                                                        (departureCityRef.current[
                                                                            index
                                                                        ] = ref)
                                                                    }
                                                                    onFocus={() =>
                                                                        setShowHint(
                                                                            true
                                                                        )
                                                                    }
                                                                    onBlur={() => {
                                                                        if (
                                                                            !departureCitySelected[
                                                                                index
                                                                            ]
                                                                        ) {
                                                                            updateSegment(
                                                                                0,
                                                                                "from",
                                                                                ""
                                                                            );
                                                                        }
                                                                        setDepartureCitySelected(
                                                                            false
                                                                        );
                                                                        setShowHint(
                                                                            false
                                                                        );
                                                                    }}
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        updateSegment(
                                                                            index,
                                                                            "from",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                {fromSymbol}
                                                                {submitted &&
                                                                    !segment.from && (
                                                                        <p className="error-message">
                                                                            Departure
                                                                            city
                                                                            is
                                                                            required.
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </label>
                                                        <label>
                                                            <div className="input-wrapper">
                                                                <input
                                                                    className={`city-input ${
                                                                        submitted &&
                                                                        !segment.to &&
                                                                        "error"
                                                                    }`}
                                                                    type="text"
                                                                    value={
                                                                        segment.to
                                                                    }
                                                                    placeholder="To which city?"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        updateSegment(
                                                                            index,
                                                                            "to",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    ref={(
                                                                        ref
                                                                    ) =>
                                                                        (destinationRefs.current[
                                                                            index
                                                                        ] = ref)
                                                                    }
                                                                    onFocus={() =>
                                                                        setShowHint1(
                                                                            true
                                                                        )
                                                                    }
                                                                    onBlur={() => {
                                                                        if (
                                                                            !destinationSelected[
                                                                                index
                                                                            ]
                                                                        ) {
                                                                            updateSegment(
                                                                                index,
                                                                                "to",
                                                                                ""
                                                                            );
                                                                        }
                                                                        let newDestinationSelected =
                                                                            [
                                                                                ...destinationSelected,
                                                                            ];
                                                                        newDestinationSelected[
                                                                            index
                                                                        ] = false;
                                                                        setDestinationSelected(
                                                                            newDestinationSelected
                                                                        );
                                                                        setShowHint1(
                                                                            false
                                                                        );
                                                                    }}
                                                                />
                                                                {toSymbol}
                                                                {submitted &&
                                                                    !segment.to && (
                                                                        <p className="error-message">
                                                                            Destination
                                                                            city
                                                                            is
                                                                            required.
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </label>
                                                        {flightType !==
                                                            "return" && (
                                                            <label>
                                                                <input
                                                                    style={{
                                                                        width: "20rem",
                                                                        paddingLeft:
                                                                            "6rem",
                                                                    }}
                                                                    className={`segment-input ${
                                                                        submitted &&
                                                                        !segment.departureDate &&
                                                                        "error"
                                                                    }`}
                                                                    type="date"
                                                                    value={
                                                                        segment.departureDate
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        updateSegment(
                                                                            index,
                                                                            "departureDate",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    placeholder={new Date(
                                                                        segment?.departureDate
                                                                    ).toLocaleDateString()}
                                                                    onBlur={(
                                                                        e
                                                                    ) =>
                                                                        handleDepartureDateBlur(
                                                                            index,
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                {submitted &&
                                                                    !segment.departureDate && (
                                                                        <p className="error-message">
                                                                            Departure
                                                                            date
                                                                            is
                                                                            required.
                                                                        </p>
                                                                    )}
                                                            </label>
                                                        )}
                                                        {flightType ===
                                                            "return" && (
                                                            <>
                                                                <label>
                                                                    <input
                                                                        className={`segment-input ${
                                                                            submitted &&
                                                                            !segment.departureDate &&
                                                                            "error"
                                                                        }`}
                                                                        type="date"
                                                                        placeholder={
                                                                            segment.departureDate
                                                                        }
                                                                        value={
                                                                            segment.departureDate
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            updateSegment(
                                                                                index,
                                                                                "departureDate",
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        onBlur={(
                                                                            e
                                                                        ) =>
                                                                            handleDepartureDateBlur(
                                                                                index,
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />

                                                                    {submitted &&
                                                                        !segment.departureDate && (
                                                                            <p className="error-message">
                                                                                Departure
                                                                                date
                                                                                is
                                                                                required.
                                                                            </p>
                                                                        )}
                                                                </label>

                                                                <label>
                                                                    <input
                                                                        className={`segment-input ${
                                                                            submitted &&
                                                                            !segment.leaveDate &&
                                                                            "error"
                                                                        }`}
                                                                        type="date"
                                                                        value={
                                                                            segment.leaveDate
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            updateSegment(
                                                                                index,
                                                                                "leaveDate",
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        onBlur={(
                                                                            e
                                                                        ) =>
                                                                            handleLeaveDateBlur(
                                                                                index,
                                                                                segment.departureDate,
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                    {submitted &&
                                                                        !segment.leaveDate && (
                                                                            <p className="error-message">
                                                                                Return
                                                                                date
                                                                                is
                                                                                required.
                                                                            </p>
                                                                        )}
                                                                </label>
                                                            </>
                                                        )}
                                                        {segments.length >
                                                            1 && (
                                                            <button
                                                                className="delete-segment-button"
                                                                onClick={() =>
                                                                    deleteSegment(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                ×
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}

                                            {flightType === "multi-city" && (
                                                <button
                                                    id="add-flight-button"
                                                    onClick={addSegment}
                                                >
                                                    Add a trip
                                                </button>
                                            )}
                                            {flightType === "multi-city" &&
                                                isEditMode === false && (
                                                    <label>
                                                        Return to{" "}
                                                        {segments[0].from} ?
                                                        <input
                                                            style={{
                                                                marginLeft:
                                                                    "1rem",
                                                            }}
                                                            id="returnToDeparture"
                                                            type="checkbox"
                                                            checked={
                                                                returnToDeparture
                                                            }
                                                            onChange={(e) =>
                                                                setReturnToDeparture(
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                        />
                                                    </label>
                                                )}
                                            {returnToDeparture && (
                                                <div className="return-date-input">
                                                    <label>
                                                        Return Date:
                                                        <input
                                                            style={{
                                                                marginLeft:
                                                                    "1rem",
                                                                padding:
                                                                    "0.2rem",
                                                            }}
                                                            type="date"
                                                            value={returnDate}
                                                            onChange={(e) =>
                                                                setReturnDate(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flightRequirement">
                                            <label>
                                                Requirements:
                                                <textarea
                                                    type="text"
                                                    value={flightComments}
                                                    onChange={(e) =>
                                                        setFlightComments(
                                                            e.target.value
                                                        )
                                                    }
                                                    className={`${
                                                        submitted &&
                                                        !flightComments &&
                                                        "error"
                                                    }`}
                                                    placeholder={
                                                        submitted &&
                                                        !flightComments
                                                            ? "Flight comments is required"
                                                            : "同行人、優選航班、時間、航空公司、座位、飲食、其他需求"
                                                    }
                                                />
                                            </label>
                                            {submitted && !flightComments && (
                                                <p className="error-message">
                                                    Flight comments is required.
                                                </p>
                                            )}
                                        </div>
                                    </>
                                )}

                                {selectedServices.hotel && (
                                    <h3 style={{ textAlign: "center" }}>
                                        Hotels
                                    </h3>
                                )}
                                {selectedServices.hotel &&
                                    hotelSegments.map((segment, index) => (
                                        <>
                                            <h4
                                                style={{
                                                    textAlign: "left",
                                                    width: "93%",
                                                }}
                                            >
                                                Hotel {index + 1}
                                            </h4>
                                            <div key={index}>
                                                <Hotels
                                                    segmentIndex={index}
                                                    segment={segment}
                                                    updateSegment={
                                                        updateHotelSegment
                                                    }
                                                    submitted={submitted}
                                                    locationSelected={
                                                        locationSelected
                                                    }
                                                    setLocationSelected={
                                                        setLocationSelected
                                                    }
                                                    locationRef={locationRef}
                                                    showHint1={showHint1}
                                                    setShowHint1={setShowHint1}
                                                    flightSegments={segments}
                                                    flightType={flightType}
                                                />
                                                {index ===
                                                    hotelSegments.length -
                                                        1 && (
                                                    <button
                                                        onClick={() =>
                                                            addHotelSegment()
                                                        }
                                                    >
                                                        Add Hotel
                                                    </button>
                                                )}
                                                {index > 0 &&
                                                    index ===
                                                        hotelSegments.length -
                                                            1 && (
                                                        <button
                                                            id="delete-btn"
                                                            onClick={() =>
                                                                deleteHotelSegment(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            Delete Hotel
                                                        </button>
                                                    )}
                                            </div>
                                        </>
                                    ))}

                                {selectedServices.ticket && <h3>Ticket</h3>}
                                {selectedServices.ticket &&
                                    tickets.map((ticket, index) => (
                                        <>
                                            <h4
                                                style={{
                                                    textAlign: "left",
                                                    width: "93%",
                                                }}
                                            >
                                                Ticket {index + 1}
                                            </h4>
                                            <div key={index}>
                                                <Tickets
                                                    tickets={ticket}
                                                    ticketIndex={index}
                                                    updateTicketSegment={
                                                        updateTicketSegment
                                                    }
                                                    submitted={submitted}
                                                    flightSegments={segments}
                                                    flightType={flightType}
                                                />
                                                {index ===
                                                    tickets.length - 1 && (
                                                    <button
                                                        onClick={() =>
                                                            addTicketSegment()
                                                        }
                                                    >
                                                        Add Ticket
                                                    </button>
                                                )}
                                                {index > 0 &&
                                                    index ===
                                                        tickets.length - 1 && (
                                                        <button
                                                            id="delete-btn"
                                                            onClick={() =>
                                                                deleteTicketSegment(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            Delete Ticket
                                                        </button>
                                                    )}
                                            </div>
                                        </>
                                    ))}
                            </div>

                            <Toast
                                show={showToast}
                                onClose={() => setShowToast(false)}
                                delay={3000}
                                autohide
                            >
                                <Toast.Body>
                                    Your application is saved.
                                </Toast.Body>
                            </Toast>
                            {(selectedServices.flight ||
                                selectedServices.hotel ||
                                selectedServices.visa ||
                                selectedServices.ticket) && (
                                <div>
                                    <button onClick={handleSaveAndLeave}>
                                        Save
                                    </button>

                                    <button
                                        style={{ marginTop: "4rem" }}
                                        onClick={handleNext}
                                    >
                                        {isEditMode
                                            ? "Update and Next"
                                            : "Next to Budget"}
                                    </button>
                                    <button id="cancel" onClick={goBack}>
                                        Cancel
                                    </button>
                                </div>
                            )}
                            <CustomModal
                                show={showCustomModal}
                                message={modalMessage}
                                onClose={() => setShowCustomModal(false)}
                            />

                            <Modal
                                show={showConfirmModal}
                                onHide={() => setShowConfirmModal(false)}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Save application</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>
                                        Are you sure you want to save and leave
                                        this page?
                                    </p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="secondary"
                                        onClick={() =>
                                            setShowConfirmModal(false)
                                        }
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={confirmSaveAndLeave}
                                    >
                                        Confirm
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </>
                    )}
                    <EmployeeModal
                        showModal={showModal}
                        setShowModal={setShowModal}
                        profileData={profileData}
                        setProfileData={setProfileData}
                        handleSubmit={handleSubmit}
                        userId={mongoUserId}
                        segments={segments}
                    />
                    {showApplicantModal && (
                        <ApplicantModal
                            showModal={showApplicantModal}
                            setShowModal={setShowApplicantModal}
                            onApplicantSubmit={handleApplicantSubmit}
                            currentApplicant={currentApplicant}
                            applicantProfileData={applicantProfileData}
                            setApplicantProfileData={setApplicantProfileData}
                            segments={segments}
                        />
                    )}
                </>
            )}
            {currentStep === 2 && (
                <>
                    <Budget budget={budget} onSubmit={handleBudgetSubmit} selectedServices={selectedServices} />
                    {applicationType === "business" && (
                        <Modal
                            show={showSubmitModal}
                            onHide={() => setShowSubmitModal(false)}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    Select Manager/Director
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <select
                                    value={selectedManager}
                                    onChange={(e) => {
                                        setSelectedManager(e.target.value);
                                    }}
                                >
                                    <option value="">Select a Manager</option>
                                    {managementList.map((manager, index) => (
                                        <option key={index} value={manager._id}>
                                            {manager.name}
                                        </option>
                                    ))}
                                </select>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={() => setShowSubmitModal(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() =>
                                        handleSubmitToManager(selectedManager)
                                    }
                                >
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )}
                </>
            )}

            {currentStep === 3 && (
                <Confirmation onBackToHome={() => setCurrentStep(1)} />
            )}
        </div>
    );
};

export default Application;
