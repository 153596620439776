import React, { useCallback, useContext, useState } from "react";
import { auth } from "../firebaseConfig";
import {
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
} from "@firebase/auth";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { BTMSContext } from "../BTMSContext";
import { IoIosArrowBack } from "react-icons/io";
import "../assets/scss/_btms.scss";

const Login = () => {
    const baseURL = "https://jrbtms.fly.dev";
    const [errMsg, setErrMsg] = useState("");
    const [showResetModal, setShowResetModal] = useState(false);
    let history = useHistory();
    const routerState = history.location.state;
    const [userData, setUserData] = useState({
        email: routerState?.email || "",
        password: "",
    });

    const handleLogin = async () => {
        try {
            await signInWithEmailAndPassword(
                auth,
                userData.email,
                userData.password
            );

            console.log(`${baseURL}/profile`);

            const response = await fetch(`${baseURL}/profile`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                console.log("Response received:", response);
                throw new Error(response.statusText);
            }

            const allUsers = await response.json();

            const user = allUsers.find(
                (user) => user.userEmail === userData.email
            );

            if (!user || !user._id) {
                throw new Error("User not found in MongoDB data");
            }
            if (user.companyId) {
                sessionStorage.setItem("companyId", user.companyId._id);
            }
            sessionStorage.setItem("userRole", user.role);
            sessionStorage.setItem("mongoUserId", user._id);
            sessionStorage.setItem("username", user.name);
            checkUserRole();
            history.push("/btms/frontpage");
        } catch (error) {
            console.error("Error during login: ", error); // Debug log
            switch (error.code) {
                case "auth/invalid-email":
                    setErrMsg("Invalid email address format.");
                    break;
                case "auth/user-disabled":
                    setErrMsg("This account has been disabled.");
                    break;
                case "auth/user-not-found":
                    setErrMsg("No account found with this email address.");
                    break;
                case "auth/wrong-password":
                    setErrMsg("Incorrect password.");
                    break;
                default:
                    setErrMsg("Email or password is incorrect.");
                    break;
            }
        }
    };

    const handlePasswordReset = async () => {
        try {
            await sendPasswordResetEmail(auth, userData.email);
            console.log("Password reset email sent!");
            setShowResetModal(false);
        } catch (error) {
            console.error("Error sending password reset email: ", error);
        }
    };

    const goBack = useCallback(() => {
        sessionStorage.removeItem("applicationId");
        history.goBack();
    }, [history]);

    const checkUserRole = async () => {
        const userId = sessionStorage.getItem("mongoUserId");
        try {
            const response = await fetch(
                `${baseURL}/profile/${userId}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Failed to fetch user profile.");
            }

            const data = await response.json();
            if (data.role === "Admin") {
                sessionStorage.setItem("isAdmin", true);
                console.log("the user is admin");
            } else {
                sessionStorage.setItem("isAdmin", false);
                console.log("the user is not admin");
            }
        } catch (error) {
            console.error("Error fetching user profile: ", error);
        }
    };

    return (
        <div className="signup-form">
            <input
                type="text"
                placeholder="Email"
                value={userData.email}
                onChange={(e) =>
                    setUserData((prev) => ({ ...prev, email: e.target.value }))
                }
            />
            <input
                type="password"
                placeholder="Password"
                value={userData.password}
                onChange={(e) =>
                    setUserData((prev) => ({
                        ...prev,
                        password: e.target.value,
                    }))
                }
            />
            {errMsg && <p>{errMsg}</p>}
            <Button onClick={handleLogin}>Login</Button>
            <Button onClick={() => setShowResetModal(true)}>
                Forget password?
            </Button>

            <Modal
                show={showResetModal}
                onHide={() => setShowResetModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Password Reset</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Enter your email and we will send you instructions to reset
                    your password. Please check your spam folder if you do not
                    receive the email.
                    <input
                        style={{ marginTop: "1rem" }}
                        type="text"
                        placeholder="Email"
                        value={userData.email}
                        onChange={(e) =>
                            setUserData((prev) => ({
                                ...prev,
                                email: e.target.value,
                            }))
                        }
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowResetModal(false)}
                    >
                        Close
                    </Button>
                    <Button variant="primary" onClick={handlePasswordReset}>
                        Send Reset Email
                    </Button>
                </Modal.Footer>
            </Modal>
            <button className="back-icon" onClick={goBack}>
                <IoIosArrowBack size={24} />
            </button>
        </div>
    );
};

export default Login;
