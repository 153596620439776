import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import sendEmail from "../utils/sendEmail";

const ApplicationConfirmation = () => {
    const baseURL = "https://jrbtms.fly.dev";
    const { applicationId } = useParams();
    const [application, setApplication] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sent, setSent] = useState(false);

    useEffect(() => {
        const fetchApplication = async () => {
            try {
                const response = await fetch(
                    `${baseURL}/application/${applicationId}`
                );
                const data = await response.json();
                setApplication(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchApplication();
    }, [applicationId]);

    const notifyApplicant = async () => {
        if (application && application.userId && application.userId.userEmail) {
            const subject = `Your Application ${
                application.status === "Approved" ? "Approved" : "Rejected"
            }`;
            const emailContent = `Dear ${
                application.userId.name
            }, <br/><br/>I hope this message finds you well. Your application with ID ${applicationId} has been ${application.status.toLowerCase()}.<br/><br/>To view the details, please visit the <a href="https://jrbtms.fly.dev/btms/">JR BTMS</a> -> "Status of Application" page.<br/><br/>Best regards,<br/>JR International`;

            await sendEmail(application.userId.userEmail, subject, emailContent);
            setSent(true);
        }
    };

    useEffect(() => {
        console.log("start notify");
        if (
            !sent &&
            application &&
            (application.status === "Approved" ||
                application.status === "Rejected")
        ) {
            notifyApplicant();
        }
    }, [application]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    // TODO: Implement UI based on application status and comments
    const handleRejectReasonSubmit = async (event) => {
        event.preventDefault();
        const reason = event.target.elements.reason.value;
        if (!reason) return;

        try {
            const response = await fetch(
                `${baseURL}/application/${applicationId}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ comments: reason }),
                }
            );

            if (response.ok) {
                setApplication({ ...application, comments: reason });
            } else {
                throw new Error("Failed to submit reason.");
            }
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className="application-confirmation">
            <h1>Application Confirmation</h1>
            {application.status === "Approved" && (
                <p>
                    Thank you for your confirmation of application number:{" "}
                    <i>{applicationId}</i>. <br />
                    <br />
                    <strong style={{ fontSize: "1.7rem" }}>
                        JR International B.V.
                    </strong>{" "}
                    will start to process the application.
                </p>
            )}
            {application.status === "Rejected" && !application.comments && (
                <form onSubmit={handleRejectReasonSubmit}>
                    <label htmlFor="reason">
                        <p>
                            Thank you for your confirmation of application
                            number: <i>{applicationId}</i>. <br />
                            <br />
                            Please provide{" "}
                            <strong
                                style={{ fontSize: "1.7rem", color: "red" }}
                            >
                                the reason for rejection:
                            </strong>
                        </p>
                    </label>
                    <br />
                    <textarea
                        type="text"
                        id="reason"
                        name="reason"
                        placeholder="Please provide the applicant with detailed reasons for rejection"
                        required
                    />
                    <br />
                    <button type="submit">Submit</button>
                </form>
            )}
            {application.status === "Rejected" && application.comments && (
                <p>
                    Your decision and rejection reason have been sent to{" "}
                    {application.userId.name}.
                </p>
            )}
        </div>
    );
};

export default ApplicationConfirmation;
