import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BTMSContext } from "../BTMSContext";
import "../assets/scss/_btms.scss";
import { Toast } from "react-bootstrap";
import EmployeeModal from "../components/EmployeeModal";
import ApplicantModal from "../components/ApplicantModal";
import sendEmail from "../utils/sendEmail";

const ReplyRequest = () => {
    const baseURL = "https://jrbtms.fly.dev";
    const { initialState, convertDateToInputFormat, goBack } =
        useContext(BTMSContext);
    const { applicationId } = useParams();
    const [application, setApplication] = useState(null);
    const [flightReplies, setFlightReplies] = useState([]);
    const [hotelReplies, setHotelReplies] = useState([]);
    const [visaReplies, setVisaReplies] = useState([]);
    const [ticketReplies, setTicketReplies] = useState([]);
    const [ticketDate, setTicketDate] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [validationErrors, setValidationErrors] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalUserId, setModalUserId] = useState(null);
    const [showApplicantModal, setShowApplicantModal] = useState(false);
    const [modalApplicantId, setModalApplicantId] = useState(null);
    const [applicantData, setApplicantData] = useState(initialState);
    const [profileData, setProfileData] = useState(initialState);
    const [docType, setDocType] = useState("passport");

    const symbol = (
        <svg
            className="input-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path d="M12 15.75A1.125 1.125 0 1 0 12 18a1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zm.75-2.25V5.25a.75.75 0 0 0-1.5 0v8.25a.75.75 0 0 0 1.5 0zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z"></path>
        </svg>
    );

    const fetchProfileData = async (userId) => {
        try {
            const response = await fetch(`${baseURL}/profile/${userId}`);
            if (!response.ok) {
                throw new Error("Failed to fetch profile data");
            }
            const data = await response.json();

            setProfileData((prevState) => ({
                ...prevState,
                ...data,
                name: data?.name || "",
                birth: data?.birth
                    ? convertDateToInputFormat(data?.birth)
                    : null,
                sex: data?.sex || "M",
                nationality: data?.nationality || "",
                contact: {
                    tel: (data.contact && data.contact?.tel) || "",
                },
                passportInfo: {
                    number:
                        (data.passportInfo && data.passportInfo?.number) || "",
                    expiryDate: data.passportInfo?.expiryDate
                        ? convertDateToInputFormat(data.passportInfo.expiryDate)
                        : null,
                },
                idInfo: {
                    number: (data.idInfo && data.idInfo?.number) || "",
                    expiryDate:
                        data.idInfo && data.idInfo?.expiryDate
                            ? convertDateToInputFormat(data.idInfo.expiryDate)
                            : null,
                },
            }));
        } catch (error) {
            console.error("Error fetching profile data: ", error);
        }
    };

    const fetchApplicantData = async (userId) => {
        try {
            const response = await fetch(`${baseURL}/applicant/${userId}`);
            if (!response.ok) {
                throw new Error("Failed to fetch applicant data");
            }
            const data = await response.json();

            setApplicantData((prevState) => ({
                ...prevState,
                ...data,
                name: data?.name || "",
                birth: data?.birth
                    ? convertDateToInputFormat(data?.birth)
                    : null,
                sex: data?.sex || "",
                nationality: data?.nationality || "",
                email: data?.email || "",
                passportInfo: {
                    number:
                        (data.passportInfo && data.passportInfo?.number) || "",
                    expiryDate: data.passportInfo?.expiryDate
                        ? convertDateToInputFormat(data.passportInfo.expiryDate)
                        : null,
                },
                idInfo: {
                    number: (data.idInfo && data.idInfo?.number) || "",
                    expiryDate:
                        data.idInfo && data.idInfo?.expiryDate
                            ? convertDateToInputFormat(data.idInfo.expiryDate)
                            : null,
                },
            }));
        } catch (error) {
            console.error("Error fetching profile data: ", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let missingField = null;

        if (!profileData.contact) {
            missingField = "contact information";
        } else if (!profileData.contact.tel) {
            missingField = "phone number";
        } else if (!profileData.name) {
            missingField = "name";
        } else if (!profileData.passportInfo.number) {
            missingField = "passport number";
        } else if (!profileData.passportInfo.expiryDate) {
            missingField = "passport expiry date";
        } else if (!profileData.birth) {
            missingField = "birth date";
        } else if (!profileData.nationality) {
            missingField = "nationality";
        }

        if (missingField) {
            alert(`Please fill out your ${missingField}.`);
            return;
        }

        try {
            // Validation about age and issue date
            const today = new Date();
            const birthDate = new Date(profileData.birth);
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();

            if (
                monthDifference < 0 ||
                (monthDifference === 0 && today.getDate() < birthDate.getDate())
            ) {
                age--;
            }

            if (age < 18) {
                alert("You seem too young! Are you sure about your birthdate?");
                return;
            }

            const expiryDate =
                docType === "passport"
                    ? new Date(
                          document.querySelector(
                              "input[name='expiryDate']"
                          ).value
                      )
                    : new Date(
                          document.querySelector(
                              "input[name='expiryDate']"
                          ).value
                      );

            if (expiryDate < today) {
                alert(
                    "Your document's is expired. Please renew your document."
                );
            }

            const response = await fetch(`${baseURL}/profile/${modalUserId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    profileData,
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to update profile");
            }

            console.log("Profile updated successfully!");
            setShowModal(false);
        } catch (error) {
            console.error("Error updating profile: ", error);
        }
    };

    const handleEmployeeClick = async (userId) => {
        console.log("userId is", userId);
        setShowModal(true);
        setModalUserId(userId);
        await fetchProfileData(userId._id);
    };

    const handleApplicantClick = async (applicantId) => {
        console.log("applicantId is", applicantId);
        setShowApplicantModal(true);
        setModalApplicantId(applicantId);
        await fetchApplicantData(applicantId);
    };

    useEffect(() => {
        const fetchApplication = async () => {
            try {
                const response = await fetch(
                    `${baseURL}/application/${applicationId}`
                );
                if (!response.ok) {
                    throw new Error("Application not found");
                }
                const data = await response.json();
                setApplication(data);
            } catch (error) {
                console.error("Error fetching application:", error);
            }
        };

        fetchApplication();
    }, [applicationId, baseURL]);

    useEffect(() => {
        if (application && application.flight && application.flight.segments) {
            setFlightReplies(
                application.flight.segments.map((segment, index) => ({
                    reply: {
                        airline: segment.reply?.airline || "",
                        departureTime: segment.reply?.departureTime || "",
                        departureAirport: segment.reply?.departureAirport || "",
                        arrivalAirport: segment.reply?.arrivalAirport || "",
                        arrivalTime: segment.reply?.arrivalTime || "",
                        arrivalDate:
                            convertDateToInputFormat(
                                segment.reply?.arrivalDate
                            ) ||
                            convertDateToInputFormat(
                                application.segments.departureDate
                            ),
                        flightNumber: segment.reply?.flightNumber || "",
                        budget: segment.reply?.budget || 0,
                        luggage: segment.reply?.luggage || "",
                        passengerInfo: segment.reply?.passengerInfo || "",
                    },
                }))
            );
        }
    }, [application]);

    useEffect(() => {
        if (application && application.hotel && application.hotel.stays) {
            setHotelReplies(
                application.hotel.stays.map((stay) => ({
                    reply: {
                        hotelName: stay.reply?.hotelName || "",
                        starRating: stay.reply?.starRating || 0,

                        checkInTime: stay.reply?.checkInTime || "",

                        checkOutTime: stay.reply?.checkOutTime || "",
                        bookingDetails: stay.reply?.bookingDetails || "",
                        address: stay.reply?.address || "",
                        roomType: stay.reply?.roomType || "",
                        guestName:
                            stay.reply?.guestName || application.userId.name,
                        maxCapacity: stay.reply?.maxCapacity || 2,
                        mealPlan: stay.reply?.mealPlan || "Not included",
                        smokingPreference:
                            stay.reply?.smokingPreference || "Non-smoking",
                        budget: stay.reply?.budget || 0,
                    },
                }))
            );
        }
    }, [application]);

    useEffect(() => {
        if (application && application.visa && application.visa.visas) {
            setVisaReplies(
                application.visa.visas.map((visa) => ({
                    reply: {
                        requirement: visa.reply?.requirement || "",
                        budget: visa.reply?.budget || 0,
                    },
                }))
            );
        }
    }, [application]);

    useEffect(() => {
        if (application && application.ticket && application.ticket.tickets) {
            setTicketReplies(
                application.ticket.tickets.map((ticket) => ({
                    reply: {
                        name: ticket.reply?.name || "",
                        date:
                            convertDateToInputFormat(ticket?.reply?.date) ||
                            convertDateToInputFormat(ticket.eventDate),
                        time: ticket.reply?.time || "",
                        venue: ticket.reply?.venue || "",
                        searNumber: ticket.reply?.seatNumber || "",
                        ticketNumber: ticket.reply?.ticketNumber || "",
                        budget: ticket.reply?.budget || 0,
                        additionalInfo: ticket.reply?.additionalInfo || "",
                    },
                }))
            );
        }
    }, [application]);

    useEffect(() => {
        if (application && application.ticket && application.ticket.tickets) {
            setTicketDate(
                convertDateToInputFormat(
                    application.ticket.tickets[0]?.eventDate
                )
            );
        }
    }, [application]);

    const handleFlightReplyChange = (index, event) => {
        const { name, value } = event.target;
        setFlightReplies((prevReplies) =>
            prevReplies.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        reply: {
                            ...item.reply,
                            [name]: value,
                        },
                    };
                }
                return item;
            })
        );
    };

    const handleHotelReplyChange = (index, event) => {
        const { name, value } = event.target;
        setHotelReplies((prevReplies) =>
            prevReplies.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        reply: {
                            ...item.reply,
                            [name]: value,
                        },
                    };
                }
                return item;
            })
        );
    };

    const handleVisaReplyChange = (index, event) => {
        const { name, value } = event.target;
        setVisaReplies((prevReplies) =>
            prevReplies.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        reply: {
                            ...item.reply,
                            [name]: value,
                        },
                    };
                }
                return item;
            })
        );
    };

    const handleTicketReplyChange = (index, event) => {
        const { name, value } = event.target;
        setTicketReplies((prevReplies) =>
            prevReplies.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        reply: {
                            ...item.reply,
                            [name]: value,
                        },
                    };
                }
                return item;
            })
        );
    };

    const validateReplies = () => {
        let errors = [];

        const validateServiceReplies = (serviceReplies, serviceName) => {
            return serviceReplies.every((reply, index) => {
                return Object.entries(reply.reply).every(([key, value]) => {
                    const isValid =
                        (typeof value === "string" && value.trim() !== "") ||
                        (typeof value === "number" && value > 0);
                    if (!isValid) {
                        errors.push(`${serviceName} ${index + 1}: ${key}`);
                    }
                    return isValid;
                });
            });
        };

        const flightValid =
            flightReplies.length > 0
                ? validateServiceReplies(flightReplies, "Flight")
                : true;
        const hotelValid =
            hotelReplies.length > 0
                ? validateServiceReplies(hotelReplies, "Hotel")
                : true;
        // Uncomment and modify the following lines if visa and ticket services are to be included
        const visaValid =
            visaReplies.length > 0
                ? validateServiceReplies(visaReplies, "Visa")
                : true;

        setValidationErrors(errors); // Assuming you have a state or method to handle these errors
        return flightValid && hotelValid && visaValid;
    };

    const notifyApplicant = async () => {
        console.log(application.userId.userEmail);
        if (application.userId.userEmail) {
            const subject = `Suggestion for Your Application with ID ${applicationId}`;
            const emailContent = `Dear ${application.userId.name}, <br/><br/>I hope this message finds you well. We have reviewed your application (ID: ${applicationId}) and would like to offer a suggestion to enhance your application.<br/><br/>To view and respond to this suggestion, please visit the <a href="https://jrbtms.fly.dev/btms/">JR BTMS</a> -> "Status of Application" page.<br/><br/>Thank you for your application, and we look forward to your response.<br/><br/>Best regards,<br/>JR International B.V.`;
            await sendEmail(
                application.userId.userEmail,
                subject,
                emailContent
            );
        }
    };

    const handleSendApplication = async () => {
        if (validateReplies()) {
            try {
                const updateFlightObject = {};
                const updateHotelObject = {};
                const updateVisaObject = {};
                const updateTicketObject = {};

                if (flightReplies.length > 0) {
                    flightReplies.forEach((item, index) => {
                        updateFlightObject[`segments.${index}.reply`] =
                            item.reply;
                    });

                    const response = await fetch(
                        `${baseURL}/flights/${application.flight._id}`,
                        {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ $set: updateFlightObject }),
                        }
                    );

                    if (!response.ok) {
                        throw new Error("Failed to update flight replies.");
                    }
                }

                if (hotelReplies.length > 0) {
                    hotelReplies.forEach((item, index) => {
                        updateHotelObject[`stays.${index}.reply`] = item.reply;
                    });

                    const response = await fetch(
                        `${baseURL}/hotels/${application.hotel._id}`,
                        {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ $set: updateHotelObject }),
                        }
                    );

                    if (!response.ok) {
                        throw new Error("Failed to update hotel replies.");
                    }
                }

                if (visaReplies.length > 0) {
                    visaReplies.forEach((item, index) => {
                        updateVisaObject[`visas.${index}.reply`] = item.reply;
                    });

                    const response = await fetch(
                        `${baseURL}/visas/${application.visa._id}`,
                        {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ $set: updateVisaObject }),
                        }
                    );

                    if (!response.ok) {
                        throw new Error("Failed to update visa replies.");
                    }
                }

                if (ticketReplies.length > 0) {
                    ticketReplies.forEach((item, index) => {
                        updateTicketObject[`tickets.${index}.reply`] =
                            item.reply;
                    });

                    const response = await fetch(
                        `${baseURL}/tickets/${application.ticket._id}`,
                        {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ $set: updateTicketObject }),
                        }
                    );

                    if (!response.ok) {
                        throw new Error("Failed to update ticket replies.");
                    }
                }
                const response = await fetch(
                    `${baseURL}/application/${applicationId}`,
                    {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            status: "Suggestion",
                            comments: "",
                        }),
                    }
                );
                if (response.ok) {
                    notifyApplicant();
                    setShowToast(true);
                    setToastMessage("Your reply has been sent.");
                } else throw new Error("Failed to update application status.");
            } catch (error) {
                console.error("Error submitting replies:", error);
            }
        } else {
            setShowToast(true);
            setToastMessage("Please fill all the required fields");
            setSubmitted(true);
        }
    };

    if (!application) {
        return <div>Loading...</div>;
    }

    return (
        <div className="reply-request">
            <h2>{application.name}</h2>
            <h4>
                Submission Date:{" "}
                {new Date(application.createTime).toLocaleDateString()}
            </h4>
            <p style={{ textAlign: "center" }}>
                Application number: {application._id}
            </p>
            <h3>Apply for: {application.applyFor}</h3>
            {application.applyFor !== "myself" && (
                <>
                    <h3 style={{ textAlign: "left" }}>Applicants:</h3>
                    <div>
                        {application.applicants.map((applicant, index) => (
                            <li key={index} className="employee-list-item">
                                <button
                                    className="employee-name"
                                    onClick={() =>
                                        handleApplicantClick(applicant._id)
                                    }
                                >
                                    {applicant.name}
                                </button>
                            </li>
                        ))}
                    </div>
                </>
            )}

            <div className="trip-details">
                <div className="details-container">
                    {application && application.comments?.length > 0 && (
                        <h2 id="comments">
                            Comments: <br />
                            {application.comments}
                        </h2>
                    )}

                    {application && application.flight && (
                        <>
                            <h2 style={{ textAlign: "left" }}>
                                Flight Details: {application.flight.type} flight
                            </h2>
                        </>
                    )}
                    {application &&
                        application.flight &&
                        application.flight.segments.map((segment, index) => (
                            <div className="segment-details" key={index}>
                                <p>
                                    Trip: <br />
                                    {segment.from} to {segment.to}
                                </p>
                                <p>
                                    Departure:
                                    <br />
                                    {new Date(
                                        segment.departureDate
                                    ).toLocaleDateString()}
                                </p>
                                {segment.leaveDate && (
                                    <p>
                                        Return:
                                        <br />
                                        {segment.leaveDate
                                            ? new Date(
                                                  segment.leaveDate
                                              ).toLocaleDateString()
                                            : "N/A"}
                                    </p>
                                )}
                            </div>
                        ))}

                    {application && application.flight && (
                        <>
                            <h3 id="requirement">
                                Flight requirement:{" "}
                                {application.flight?.comments}
                            </h3>
                            <h3 id="budget">
                                Flight budget: {application.budget?.travel} (
                                {application.budget?.currency})
                            </h3>
                        </>
                    )}
                    {application &&
                        application.flight &&
                        application.flight.segments.map((segment, index) => (
                            <div key={index} className="flight-reply">
                                <h3>
                                    Flight From: {segment.from} to {segment.to}{" "}
                                </h3>

                                <div className="reply-container">
                                    <label>
                                        Airline:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="airline"
                                                value={
                                                    flightReplies[index]
                                                        ?.airline ||
                                                    flightReplies[index]?.reply
                                                        ?.airline
                                                }
                                                onChange={(e) =>
                                                    handleFlightReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                className={
                                                    submitted &&
                                                    !flightReplies[index]?.reply
                                                        ?.airline
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={
                                                    flightReplies[index]?.reply
                                                        ?.airline || "Airline"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !flightReplies[index].reply
                                                    .airline &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !flightReplies[index].reply
                                                .airline && (
                                                <p className="error-message">
                                                    Airline is required.
                                                </p>
                                            )}
                                    </label>

                                    <label>
                                        Flight Number:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="flightNumber"
                                                value={
                                                    flightReplies[index]
                                                        ?.flightNumber ||
                                                    flightReplies[index]?.reply
                                                        ?.flightNumber
                                                }
                                                onChange={(e) =>
                                                    handleFlightReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                required
                                                placeholder={
                                                    flightReplies[index]?.reply
                                                        ?.flightNumber ||
                                                    "Flight Number"
                                                }
                                                className={
                                                    submitted &&
                                                    !flightReplies[index].reply
                                                        .flightNumber
                                                        ? "error"
                                                        : ""
                                                }
                                            />
                                            {submitted &&
                                                !flightReplies[index].reply
                                                    .flightNumber &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !flightReplies[index].reply
                                                .flightNumber && (
                                                <p className="error-message">
                                                    Flight number is required.
                                                </p>
                                            )}
                                    </label>

                                    <label>
                                        Departure Time:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="departureTime"
                                                value={
                                                    flightReplies[index]
                                                        ?.departureTime ||
                                                    flightReplies[index]?.reply
                                                        ?.departureTime
                                                }
                                                onChange={(e) =>
                                                    handleFlightReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                className={
                                                    submitted &&
                                                    !flightReplies[index].reply
                                                        .departureTime
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={
                                                    flightReplies[index]?.reply
                                                        ?.departureTime ||
                                                    "e.g. 10:00am"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !flightReplies[index].reply
                                                    .departureTime &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !flightReplies[index].reply
                                                .departureTime && (
                                                <p className="error-message">
                                                    Departure time is required.
                                                </p>
                                            )}
                                    </label>
                                    <label>
                                        Departure Airport:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="departureAirport"
                                                value={
                                                    flightReplies[index]
                                                        ?.departureAirport ||
                                                    flightReplies[index]?.reply
                                                        ?.departureAirport
                                                }
                                                onChange={(e) =>
                                                    handleFlightReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                className={
                                                    submitted &&
                                                    !flightReplies[index]?.reply
                                                        ?.departureAirport
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={
                                                    flightReplies[index]?.reply
                                                        ?.departureAirport ||
                                                    "Departure Airport"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !flightReplies[index].reply
                                                    .departureAirport &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !flightReplies[index].reply
                                                .departureAirport && (
                                                <p className="error-message">
                                                    Departure airport is
                                                    required.
                                                </p>
                                            )}
                                    </label>
                                    <label>
                                        Arrival Date:
                                        <div className="input-wrapper">
                                            <input
                                                type="date"
                                                name="arrivalDate"
                                                value={
                                                    flightReplies[index]
                                                        ?.arrivalDate ||
                                                    flightReplies[index]?.reply
                                                        ?.arrivalDate
                                                }
                                                onChange={(e) =>
                                                    handleFlightReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                required
                                            />
                                        </div>
                                        {submitted &&
                                            !flightReplies[index].reply
                                                .arrivalDate && (
                                                <p className="error-message">
                                                    Arrival date is required.
                                                </p>
                                            )}
                                    </label>
                                    <label>
                                        Arrival Airport:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="arrivalAirport"
                                                value={
                                                    flightReplies[index]
                                                        ?.arrivalAirport ||
                                                    flightReplies[index]?.reply
                                                        ?.arrivalAirport
                                                }
                                                onChange={(e) =>
                                                    handleFlightReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                className={
                                                    submitted &&
                                                    !flightReplies[index]?.reply
                                                        ?.arrivalAirport
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={
                                                    flightReplies[index]?.reply
                                                        ?.arrivalAirport ||
                                                    "Arrival Airport"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !flightReplies[index].reply
                                                    .arrivalAirport &&
                                                symbol}
                                        </div>
                                    </label>
                                    <label>
                                        Arrival Time:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="arrivalTime"
                                                value={
                                                    flightReplies[index]
                                                        ?.arrivalTime ||
                                                    flightReplies[index]?.reply
                                                        ?.arrivalTime
                                                }
                                                onChange={(e) =>
                                                    handleFlightReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                required
                                                placeholder={
                                                    flightReplies[index]?.reply
                                                        ?.arrivalTime ||
                                                        "e.g. 10:00am"
                                                }
                                                className={
                                                    submitted &&
                                                    !flightReplies[index].reply
                                                        .arrivalTime
                                                        ? "error"
                                                        : ""
                                                }
                                            />
                                            {submitted &&
                                                !flightReplies[index].reply
                                                    .arrivalTime &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !flightReplies[index].reply
                                                .arrivalTime && (
                                                <p className="error-message">
                                                    Arrival time is required.
                                                </p>
                                            )}
                                    </label>

                                    <label>
                                        Luggage:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="luggage"
                                                value={
                                                    flightReplies[index]
                                                        ?.luggage ||
                                                    flightReplies[index]?.reply
                                                        ?.luggage
                                                }
                                                onChange={(e) =>
                                                    handleFlightReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                required
                                                placeholder={
                                                    flightReplies[index]?.reply
                                                        ?.luggage || "Luggage"
                                                }
                                                className={
                                                    submitted &&
                                                    !flightReplies[index].reply
                                                        .luggage
                                                        ? "error"
                                                        : ""
                                                }
                                            />
                                            {submitted &&
                                                !flightReplies[index].reply
                                                    .luggage &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !flightReplies[index].reply
                                                .luggage && (
                                                <p className="error-message">
                                                    Luggage is required.
                                                </p>
                                            )}
                                    </label>
                                    <label>
                                        Budget ({application.budget?.currency}):
                                        <div className="input-wrapper">
                                            <input
                                                type="number"
                                                name="budget"
                                                value={
                                                    flightReplies[index]
                                                        ?.budget ||
                                                    flightReplies[index]?.reply
                                                        ?.budget
                                                }
                                                onChange={(e) =>
                                                    handleFlightReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                required
                                                placeholder={
                                                    flightReplies[index]?.reply
                                                        ?.budget || "Budget"
                                                }
                                                className={
                                                    submitted &&
                                                    !flightReplies[index].reply
                                                        .budget
                                                        ? "error"
                                                        : ""
                                                }
                                            />
                                            {submitted &&
                                                !flightReplies[index].reply
                                                    .budget &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !flightReplies[index].reply
                                                .budget && (
                                                <p className="error-message">
                                                    Budget is required.
                                                </p>
                                            )}
                                    </label>
                                    <label>
                                        Passenger Information:
                                        <div className="input-wrapper">
                                            <textarea
                                                name="passengerInfo"
                                                value={
                                                    flightReplies[index]
                                                        ?.passengerInfo ||
                                                    flightReplies[index]?.reply
                                                        ?.passengerInfo
                                                }
                                                onChange={(e) =>
                                                    handleFlightReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                required
                                                placeholder={
                                                    flightReplies[index]?.reply
                                                        ?.passengerInfo ||
                                                    "Name + (Sex)"
                                                }
                                                className={
                                                    submitted &&
                                                    !flightReplies[index].reply
                                                        .passengerInfo
                                                        ? "error"
                                                        : ""
                                                }
                                            />
                                            {submitted &&
                                                !flightReplies[index].reply
                                                    .passengerInfo &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !flightReplies[index].reply
                                                .passengerInfo && (
                                                <p className="error-message">
                                                    Passenger information is
                                                    required.
                                                </p>
                                            )}
                                    </label>
                                </div>
                            </div>
                        ))}

                    {application && application.hotel && (
                        <>
                            <h2 style={{ textAlign: "left" }}>
                                Hotel Details:
                            </h2>
                        </>
                    )}

                    {application &&
                        application.hotel &&
                        application.hotel.stays.map((segment, index) => (
                            <div className="segment-details" key={index}>
                                <p>Location: {segment.location}</p>
                                <p>
                                    Checkin Date:
                                    <br />
                                    {new Date(
                                        segment.checkInDate
                                    ).toLocaleDateString()}
                                </p>
                                <p>
                                    Checkout Date: <br />
                                    {new Date(
                                        segment.checkOutDate
                                    ).toLocaleDateString()}
                                </p>
                            </div>
                        ))}
                    {application && application.hotel && (
                        <h3 id="budget">
                            Hotel budget: {application.budget?.hotel} (
                            {application.budget?.currency})
                        </h3>
                    )}

                    {application &&
                        application.hotel &&
                        application.hotel.stays.map((stay, index) => (
                            <div key={index} className="hotel-reply">
                                <h3>Hotel Stay at {stay.to}</h3>
                                <h3 id="requirement">
                                    Requirement: {stay.hotelComments}
                                </h3>
                                <div className="reply-container">
                                    <label>
                                        Hotel Name:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="hotelName"
                                                value={
                                                    hotelReplies[index]
                                                        ?.hotelName ||
                                                    hotelReplies[index]?.reply
                                                        ?.hotelName
                                                }
                                                onChange={(e) =>
                                                    handleHotelReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                className={
                                                    submitted &&
                                                    !hotelReplies[index]?.reply
                                                        ?.hotelName
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={
                                                    hotelReplies[index]?.reply
                                                        ?.hotelName ||
                                                    "Hotel Name"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !hotelReplies[index]?.reply
                                                    ?.hotelName &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !hotelReplies[index]?.reply
                                                ?.hotelName && (
                                                <p className="error-message">
                                                    Hotel name is required.
                                                </p>
                                            )}
                                    </label>
                                    <label>
                                        Star Rating:
                                        <div className="input-wrapper">
                                            <input
                                                type="number"
                                                name="starRating"
                                                value={
                                                    hotelReplies[index]
                                                        ?.starRating ||
                                                    hotelReplies[index]?.reply
                                                        ?.starRating
                                                }
                                                onChange={(e) =>
                                                    handleHotelReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                className={
                                                    submitted &&
                                                    !hotelReplies[index]?.reply
                                                        ?.starRating
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={
                                                    hotelReplies[index]?.reply
                                                        ?.starRating ||
                                                    "Star Rating"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !hotelReplies[index]?.reply
                                                    ?.starRating &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !hotelReplies[index]?.reply
                                                ?.starRating && (
                                                <p className="error-message">
                                                    Star rating is required.
                                                </p>
                                            )}
                                    </label>

                                    <label>
                                        Check-In Time:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="checkInTime"
                                                value={
                                                    hotelReplies[index]
                                                        ?.checkInTime ||
                                                    hotelReplies[index]?.reply
                                                        ?.checkInTime
                                                }
                                                onChange={(e) =>
                                                    handleHotelReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                placeholder={
                                                    hotelReplies[index]?.reply
                                                        ?.checkInTime ||
                                                        "e.g. 10:00am"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !hotelReplies[index]?.reply
                                                    ?.checkInTime &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !hotelReplies[index]?.reply
                                                ?.checkInTime && (
                                                <p className="error-message">
                                                    Check-in time is required.
                                                </p>
                                            )}
                                    </label>

                                    <label>
                                        Check-Out Time:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="checkOutTime"
                                                value={
                                                    hotelReplies[index]
                                                        ?.checkOutTime ||
                                                    hotelReplies[index]?.reply
                                                        ?.checkOutTime
                                                }
                                                onChange={(e) =>
                                                    handleHotelReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                placeholder={
                                                    hotelReplies[index]?.reply
                                                        ?.checkOutTime ||
                                                        "e.g. 10:00am"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !hotelReplies[index]?.reply
                                                    ?.checkOutTime &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                        !hotelReplies[index]?.reply
                                            ?.checkOutTime && (
                                            <p className="error-message">
                                                Check-out time is required.
                                            </p>
                                        )}
                                    </label>
                                    
                                    <label>
                                        Booking Details:
                                        <div className="input-wrapper">
                                            <input
                                                style={{ width: "25rem" }}
                                                name="bookingDetails"
                                                value={
                                                    hotelReplies[index]
                                                        ?.bookingDetails ||
                                                    hotelReplies[index]?.reply
                                                        ?.bookingDetails
                                                }
                                                onChange={(e) =>
                                                    handleHotelReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                placeholder={
                                                    hotelReplies[index]?.reply
                                                        ?.bookingDetails ||
                                                    "How many adults/kids, night(s) and room(s)?"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !hotelReplies[index]?.reply
                                                    ?.bookingDetails &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !hotelReplies[index]?.reply
                                                ?.bookingDetails && (
                                                <p className="error-message">
                                                    Booking details are
                                                    required.
                                                </p>
                                            )}
                                    </label>
                                    <label>
                                        Address:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="address"
                                                value={
                                                    hotelReplies[index]
                                                        ?.address ||
                                                    hotelReplies[index]?.reply
                                                        ?.address
                                                }
                                                onChange={(e) =>
                                                    handleHotelReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                placeholder={
                                                    hotelReplies[index]?.reply
                                                        ?.address ||
                                                    "Hotel Address"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !hotelReplies[index]?.reply
                                                    ?.address &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !hotelReplies[index]?.reply
                                                ?.address && (
                                                <p className="error-message">
                                                    Address is required.
                                                </p>
                                            )}
                                    </label>
                                    <label>
                                        Room Type:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="roomType"
                                                value={
                                                    hotelReplies[index]
                                                        ?.roomType ||
                                                    hotelReplies[index]?.reply
                                                        ?.roomType
                                                }
                                                onChange={(e) =>
                                                    handleHotelReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                placeholder={
                                                    hotelReplies[index]?.reply
                                                        ?.roomType ||
                                                    "Room Type"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !hotelReplies[index]?.reply
                                                    ?.roomType &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !hotelReplies[index]?.reply
                                                ?.roomType && (
                                                <p className="error-message">
                                                    Room type is required.
                                                </p>
                                            )}
                                    </label>
                                    <label>
                                        Guest Name:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="guestName"
                                                value={
                                                    hotelReplies[index]
                                                        ?.guestName ||
                                                    hotelReplies[index]?.reply
                                                        ?.guestName
                                                }
                                                onChange={(e) =>
                                                    handleHotelReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                placeholder={
                                                    hotelReplies[index]?.reply
                                                        ?.guestName ||
                                                    "Guest Name"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !hotelReplies[index]?.reply
                                                    ?.guestName &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !hotelReplies[index]?.reply
                                                ?.guestName && (
                                                <p className="error-message">
                                                    Guest name is required.
                                                </p>
                                            )}
                                    </label>
                                    <label>
                                        Max Capacity:
                                        <div className="input-wrapper">
                                            <input
                                                type="number"
                                                name="maxCapacity"
                                                value={
                                                    hotelReplies[index]
                                                        ?.maxCapacity ||
                                                    hotelReplies[index]?.reply
                                                        ?.maxCapacity
                                                }
                                                onChange={(e) =>
                                                    handleHotelReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                placeholder={
                                                    hotelReplies[index]?.reply
                                                        ?.maxCapacity ||
                                                    "Max Capacity"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !hotelReplies[index]?.reply
                                                    ?.maxCapacity &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !hotelReplies[index]?.reply
                                                ?.maxCapacity && (
                                                <p className="error-message">
                                                    Max capacity is required.
                                                </p>
                                            )}
                                    </label>
                                    <label>
                                        Meal Plan:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="mealPlan"
                                                value={
                                                    hotelReplies[index]
                                                        ?.mealPlan ||
                                                    hotelReplies[index]?.reply
                                                        ?.mealPlan
                                                }
                                                onChange={(e) =>
                                                    handleHotelReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                placeholder={
                                                    hotelReplies[index]?.reply
                                                        ?.mealPlan ||
                                                    "Meal Plan"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !hotelReplies[index]?.reply
                                                    ?.mealPlan &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !hotelReplies[index]?.reply
                                                ?.mealPlan && (
                                                <p className="error-message">
                                                    Meal plan is required.
                                                </p>
                                            )}
                                    </label>
                                    <label>
                                        Smoking Preference:
                                        <div className="input-wrapper">
                                            <select
                                                name="smokingPreference"
                                                value={
                                                    hotelReplies[index]
                                                        ?.smokingPreference ||
                                                    hotelReplies[index]?.reply
                                                        ?.smokingPreference
                                                }
                                                onChange={(e) =>
                                                    handleHotelReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                required
                                            >
                                                <option value="">
                                                    Select Preference
                                                </option>
                                                <option value="Non-smoking">
                                                    Non-smoking
                                                </option>
                                                <option value="Smoking">
                                                    Smoking
                                                </option>
                                            </select>
                                        </div>
                                    </label>
                                    <label>
                                        Budget ({application.budget?.currency}):
                                        <div className="input-wrapper">
                                            <input
                                                type="number"
                                                name="budget"
                                                value={
                                                    hotelReplies[index]
                                                        ?.budget ||
                                                    hotelReplies[index]?.reply
                                                        ?.budget
                                                }
                                                onChange={(e) =>
                                                    handleHotelReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                placeholder={
                                                    hotelReplies[index]?.reply
                                                        ?.budget || "Budget"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !hotelReplies[index]?.reply
                                                    ?.budget &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !hotelReplies[index]?.reply
                                                ?.budget && (
                                                <p className="error-message">
                                                    Budget is required.
                                                </p>
                                            )}
                                    </label>
                                </div>
                            </div>
                        ))}
                    {application && application.visa && (
                        <>
                            <h2 style={{ textAlign: "left" }}>
                                Visa Details:{" "}
                            </h2>
                        </>
                    )}
                    {application &&
                        application.visa &&
                        application.visa.visas.map((segment, index) => (
                            <div key={index} className="visa-reply">
                                <h3>
                                    Apply visa to {segment.to}; Departure at{" "}
                                    {new Date(
                                        segment.departureDate
                                    ).toLocaleDateString()}
                                </h3>

                                <div className="reply-container">
                                    <label>
                                        Requirement:
                                        <div className="input-wrapper">
                                            <textarea
                                                style={{ width: "40rem" }}
                                                type="text"
                                                name="requirement"
                                                value={
                                                    visaReplies[index]
                                                        ?.requirement ||
                                                    visaReplies[index]?.reply
                                                        ?.requirement
                                                }
                                                onChange={(e) =>
                                                    handleVisaReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                className={
                                                    submitted &&
                                                    !visaReplies[index]?.reply
                                                        ?.requirement
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={
                                                    visaReplies[index]?.reply
                                                        ?.requirement ||
                                                    "List all the requirements about applying visa"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !visaReplies[index].reply
                                                    .requirement &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !visaReplies[index].reply
                                                .requirement && (
                                                <p className="error-message">
                                                    You missed the requirements.
                                                </p>
                                            )}
                                    </label>
                                    <label style={{ marginLeft: "4rem" }}>
                                        Budget ({application.budget?.currency}):
                                        <div className="input-wrapper">
                                            <input
                                                style={{ width: "10rem" }}
                                                type="number"
                                                name="budget"
                                                value={
                                                    visaReplies[index]
                                                        ?.budget ||
                                                    visaReplies[index]?.reply
                                                        ?.budget
                                                }
                                                onChange={(e) =>
                                                    handleVisaReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                required
                                                placeholder={
                                                    visaReplies[index]?.reply
                                                        ?.budget || "Budget"
                                                }
                                                className={
                                                    submitted &&
                                                    !visaReplies[index].reply
                                                        .budget
                                                        ? "error"
                                                        : ""
                                                }
                                            />
                                            {submitted &&
                                                !visaReplies[index].reply
                                                    .budget &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !visaReplies[index].reply
                                                .budget && (
                                                <p className="error-message">
                                                    Budget is required.
                                                </p>
                                            )}
                                    </label>
                                </div>
                            </div>
                        ))}
                    {application && application.ticket && (
                        <>
                            <h2 style={{ textAlign: "left" }}>
                                Ticket Details:{" "}
                            </h2>
                        </>
                    )}
                    {application &&
                        application.ticket &&
                        application.ticket.tickets.map((ticket, index) => (
                            <div key={index} className="ticket-reply">
                                <h3>
                                    Date:{" "}
                                    {new Date(
                                        ticket.eventDate
                                    ).toLocaleDateString()}{" "}
                                    | Details: {ticket.location} | Requirements:{" "}
                                    {ticket.requirement}
                                </h3>

                                <div
                                    className="reply-container"
                                    style={{ marginTop: "2rem" }}
                                >
                                    <label>
                                        Ticket Name:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="name"
                                                value={
                                                    ticketReplies[index]
                                                        ?.name ||
                                                    ticketReplies[index]?.reply
                                                        ?.name
                                                }
                                                onChange={(e) =>
                                                    handleTicketReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                className={
                                                    submitted &&
                                                    !ticketReplies[index]?.reply
                                                        ?.name
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={
                                                    visaReplies[index]?.reply
                                                        ?.name || "Ticket Name"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !ticketReplies[index]?.reply
                                                    ?.name &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !ticketReplies[index]?.reply
                                                ?.name && (
                                                <p className="error-message">
                                                    Event name is required.
                                                </p>
                                            )}
                                    </label>

                                    <label>
                                        Date:
                                        <div className="input-wrapper">
                                            <input
                                                type="date"
                                                name="date"
                                                value={
                                                    ticketReplies[index]
                                                        ?.date ||
                                                    ticketReplies[index]?.reply
                                                        ?.date
                                                }
                                                onChange={(e) =>
                                                    handleTicketReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                className={
                                                    submitted &&
                                                    !ticketReplies[index]?.reply
                                                        ?.date
                                                        ? "error"
                                                        : ""
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !ticketReplies[index]?.reply
                                                    ?.date &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !ticketReplies[index]?.reply
                                                ?.date && (
                                                <p className="error-message">
                                                    Event date is required.
                                                </p>
                                            )}
                                    </label>

                                    <label>
                                        Event Time:
                                        <div className="input-wrapper">
                                            <input
                                                type="string"
                                                name="time"
                                                value={
                                                    ticketReplies[index]
                                                        ?.time ||
                                                    ticketReplies[index]?.reply
                                                        ?.time
                                                }
                                                onChange={(e) =>
                                                    handleTicketReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                className={
                                                    submitted &&
                                                    !ticketReplies[index]?.reply
                                                        ?.time
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={
                                                    ticketReplies[index]?.reply
                                                        ?.time || "e.g. 10:00am"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !ticketReplies[index]?.reply
                                                    ?.time &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !ticketReplies[index]?.reply
                                                ?.time && (
                                                <p className="error-message">
                                                    Event time is required.
                                                </p>
                                            )}
                                    </label>

                                    <label>
                                        Venue:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="venue"
                                                value={
                                                    ticketReplies[index]
                                                        ?.venue ||
                                                    ticketReplies[index]?.reply
                                                        ?.venue
                                                }
                                                onChange={(e) =>
                                                    handleTicketReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                className={
                                                    submitted &&
                                                    !ticketReplies[index]?.reply
                                                        ?.venue
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={
                                                    ticketReplies[index]?.reply
                                                        ?.venue || "Venue"
                                                }
                                                required
                                            />
                                            {submitted &&
                                                !ticketReplies[index]?.reply
                                                    ?.venue &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !ticketReplies[index]?.reply
                                                ?.venue && (
                                                <p className="error-message">
                                                    Venue is required.
                                                </p>
                                            )}
                                    </label>

                                    <label>
                                        Seat Number:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="seatNumber"
                                                value={
                                                    ticketReplies[index]?.reply
                                                        ?.seatNumber || ""
                                                }
                                                onChange={(e) =>
                                                    handleTicketReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                className={
                                                    submitted &&
                                                    !ticketReplies[index]?.reply
                                                        ?.seatNumber
                                                        ? "error"
                                                        : ""
                                                }
                                                placeholder={
                                                    ticketReplies[index]?.reply
                                                        ?.seatNumber ||
                                                    "Seat Number"
                                                }
                                            />
                                        </div>
                                    </label>

                                    <label>
                                        Ticket Number:
                                        <div className="input-wrapper">
                                            <input
                                                type="text"
                                                name="ticketNumber"
                                                value={
                                                    ticketReplies[index]
                                                        ?.ticketNumber ||
                                                    ticketReplies[index]?.reply
                                                        ?.ticketNumber
                                                }
                                                onChange={(e) =>
                                                    handleTicketReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                placeholder={
                                                    ticketReplies[index]?.reply
                                                        ?.ticketNumber ||
                                                    "Ticket Number"
                                                }
                                            />
                                        </div>
                                    </label>

                                    <label>
                                        Budget ({application.budget?.currency}):
                                        <div className="input-wrapper">
                                            <input
                                                type="number"
                                                name="budget"
                                                value={
                                                    ticketReplies[index]
                                                        ?.budget ||
                                                    ticketReplies[index]?.reply
                                                        ?.budget
                                                }
                                                onChange={(e) =>
                                                    handleTicketReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                className={
                                                    submitted &&
                                                    !ticketReplies[index]?.reply
                                                        ?.budget
                                                        ? "error"
                                                        : ""
                                                }
                                                required
                                                placeholder={
                                                    ticketReplies[index]?.reply
                                                        ?.budget || "Budget"
                                                }
                                            />
                                            {submitted &&
                                                !ticketReplies[index]?.reply
                                                    ?.budget &&
                                                symbol}
                                        </div>
                                        {submitted &&
                                            !ticketReplies[index]?.reply
                                                ?.budget && (
                                                <p className="error-message">
                                                    Budget is required.
                                                </p>
                                            )}
                                    </label>

                                    <label>
                                        Additional Info:
                                        <div className="input-wrapper">
                                            <textarea
                                                name="additionalInfo"
                                                value={
                                                    ticketReplies[index]
                                                        ?.additionalInfo ||
                                                    ticketReplies[index]?.reply
                                                        ?.additionalInfo
                                                }
                                                onChange={(e) =>
                                                    handleTicketReplyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                placeholder={
                                                    ticketReplies[index]?.reply
                                                        ?.additionalInfo ||
                                                    "Additional Info"
                                                }
                                            />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        ))}
                </div>
            </div>

            <Toast
                show={showToast}
                onClose={() => setShowToast(false)}
                delay={3000}
                autohide
            >
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
            <button
                style={{ marginRight: "2rem" }}
                onClick={handleSendApplication}
            >
                Submit Reply
            </button>
            <button onClick={() => goBack()}>Return</button>
            <button
                id="personal-info"
                className="employee-name"
                onClick={() => handleEmployeeClick(application.userId)}
            >
                Personal Information
            </button>

            <EmployeeModal
                showModal={showModal}
                setShowModal={setShowModal}
                profileData={profileData}
                setProfileData={setProfileData}
                setDocType={setDocType}
                handleSubmit={handleSubmit}
                userId={modalUserId}
            />
            <ApplicantModal
                showModal={showApplicantModal}
                setShowModal={setShowApplicantModal}
                applicantProfileData={applicantData}
                setApplicantProfileData={setApplicantData}
            />
        </div>
    );
};

export default ReplyRequest;
