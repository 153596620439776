import React, { useState } from "react";
import PropTypes from "prop-types";
import "../assets/scss/_btms.scss";
import { useHistory } from 'react-router-dom';

const Confirmation = ({onBackToHome}) => {
    const history = useHistory();
    const gobackToHome = () => {
        if (onBackToHome) onBackToHome();
        history.push("/btms/frontpage");
    };
    return (
        <div className="confirmation-container">
            <h2>Confirmation</h2>
            <p>Thank you for your application. After application is approved, we will contact you.</p>
            <button onClick={gobackToHome}>Back to Home</button>
        </div>
    );
}

Confirmation.propTypes = {
    onBackToHome: PropTypes.func.isRequired,
};
export default Confirmation;