import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { BTMSContext } from "../BTMSContext";
import Modal from "react-bootstrap/Modal";
import "../assets/scss/_btms.scss";

const Suggestion = () => {
    const baseURL = "https://jrbtms.fly.dev";
    const { applicationId } = useParams();
    const history = useHistory();
    const { convertDateToInputFormat } = useContext(BTMSContext);
    const [application, setApplication] = useState(null);
    const [comment, setComment] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        const fetchApplication = async () => {
            try {
                const response = await fetch(
                    `${baseURL}/application/${applicationId}`
                );
                if (!response.ok) {
                    throw new Error("Application not found");
                }
                const data = await response.json();
                setApplication(data);
            } catch (error) {
                console.error("Error fetching application:", error);
            }
        };

        fetchApplication();
    }, [applicationId, baseURL]);

    if (!application) {
        return <div>Loading...</div>;
    }

    const handleAccept = async () => {
        try {
            const response = await fetch(
                `${baseURL}/application/${applicationId}`,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ status: "Confirmed" }),
                }
            );

            if (response.ok) {
                // history.push("/btms/status");
                setShowModal2(true);
            } else {
                throw new Error("Failed to update application status.");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleReject = async () => {
        if (comment === "") {
            setShowModal(true); // Show modal if comment is empty
            return;
        }

        try {
            const response = await fetch(
                `${baseURL}/application/${applicationId}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        status: "Processing",
                        comments: comment,
                    }),
                }
            );

            if (response.ok) {
                console.log("rejected");
                history.push("/btms/status");
            } else {
                throw new Error("Failed to update application.");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleSubmitRejection = () => {
        if (comment) {
            handleReject();
        }
    };

    const handleConfirmAcceptance = () => {
        history.push("/btms/status");
    };

    const handleModalOpen = () => {
        console.log("clicked");
        console.log(showModal);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleModalClose2 = () => {
        setShowModal2(false);
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const getTripName = (fullName) => {
        return fullName.replace(/.* - /, "");
    };

    return (
        <div className="suggestion-page">
            <h1 className="suggestion-title">
                {getTripName(application.name)}
            </h1>

            {/* Flight Details */}
            {application.flight && (
                <div className="service-details">
                    <h2 className="service-title">Flight Details</h2>
                    {application.flight.segments.map((segment, index) => (
                        <div key={index} className="flight-details">
                            <h3>
                                <strong>
                                    {segment?.from} (
                                    {segment?.reply?.departureAirport}) <br />{" "}
                                    -&gt; {segment?.to}(
                                    {segment?.reply?.arrivalAirport})
                                </strong>{" "}
                            </h3>
                            <div className="container">
                                <div className="date-container">
                                    <div className="time-container">
                                        <div className="check">
                                            <p className="highlight">
                                                Departure Date:
                                            </p>
                                            <h4>
                                                <strong>
                                                    {convertDateToInputFormat(
                                                        segment?.departureDate
                                                    )}
                                                </strong>
                                            </h4>
                                            <h4 style={{ marginTop: "1rem" }}>
                                                {segment.reply?.departureTime}
                                            </h4>
                                        </div>
                                        |
                                        <div className="check">
                                            <p className="highlight">
                                                Arrive Date:
                                            </p>
                                            <h4>
                                                <strong>
                                                    {convertDateToInputFormat(
                                                        segment.reply
                                                            ?.arrivalDate
                                                    )}
                                                </strong>
                                            </h4>
                                            <h4 style={{ marginTop: "1rem" }}>
                                                {segment.reply?.arrivalTime}
                                            </h4>
                                        </div>
                                    </div>
                                    <p>
                                        <strong className="highlight">
                                            {segment.reply?.airline} Airline -{" "}
                                            {segment.reply?.flightNumber}
                                        </strong>{" "}
                                    </p>
                                </div>
                                <div className="right-container">
                                    <p>
                                        <strong>Class:</strong>{" "}
                                        {application.flight?.class}
                                    </p>
                                    <p>
                                        <strong>Luggage Info:</strong>{" "}
                                        {segment.reply?.luggage}
                                    </p>
                                    <p>
                                        <strong>Passenger Info:</strong>{" "}
                                        {segment.reply?.passengerInfo}
                                    </p>
                                    <p>
                                        <strong className="highlight">
                                            Cost:
                                        </strong>{" "}
                                        {segment.reply?.budget}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Hotel Details */}
            {application.hotel && (
                <div className="service-details hotel-details">
                    <h2 className="service-title">Hotel Details</h2>
                    {application.hotel.stays.map((stay, index) => (
                        <div key={index} className="stay-details">
                            <h3>
                                <strong>{stay.reply?.hotelName}</strong>{" "}
                                <span>
                                    {"*".repeat(stay.reply?.starRating)}
                                </span>
                            </h3>
                            <div className="container">
                                <div className="date-container">
                                    <div className="time-container">
                                        <div className="check">
                                            <p className="highlight">
                                                Check-In Date:
                                            </p>
                                            <h4>
                                                <strong>
                                                    {convertDateToInputFormat(
                                                        stay?.checkInDate
                                                    )}
                                                </strong>
                                            </h4>
                                            <p>
                                                From {stay.reply?.checkInTime}
                                            </p>
                                        </div>
                                        |
                                        <div className="check">
                                            <p className="highlight">
                                                Check-Out Date:
                                            </p>
                                            <h4>
                                                <strong>
                                                    {convertDateToInputFormat(
                                                        stay?.checkOutDate
                                                    )}
                                                </strong>
                                            </h4>
                                            <p>
                                                Until{stay.reply?.checkOutTime}
                                            </p>
                                        </div>
                                    </div>
                                    <p>
                                        <strong className="highlight">
                                            Booking Details:
                                        </strong>{" "}
                                        {stay.reply?.bookingDetails}
                                    </p>
                                    <p>
                                        <strong className="highlight">
                                            Address:
                                        </strong>{" "}
                                        {stay.reply?.address}
                                    </p>
                                </div>

                                <div className="right-container">
                                    <p>
                                        <strong className="highlight">
                                            Room Type:
                                        </strong>{" "}
                                        {stay.reply?.roomType}
                                    </p>
                                    <p>
                                        <strong>Guest Name:</strong>{" "}
                                        {stay.reply?.guestName}
                                    </p>
                                    <p>
                                        <strong>Max Capacity:</strong>{" "}
                                        {stay.reply?.maxCapacity}
                                    </p>
                                    <p>
                                        <strong>Meal Plan:</strong>{" "}
                                        {stay.reply?.mealPlan}
                                    </p>
                                    <p>
                                        <strong>Smoking Preference:</strong>{" "}
                                        {stay.reply?.smokingPreference}
                                    </p>
                                    <p>
                                        <strong className="highlight">
                                            Cost:
                                        </strong>{" "}
                                        {stay.reply?.budget}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Visa Details */}
            {application.visa && (
                <div className="service-details ">
                    <h2 className="service-title">Visa Details</h2>
                    {application.visa.visas.map((visa, index) => (
                        <div key={index} className="visa-details">
                            <div className="container visa-container">
                                <p>
                                    <strong>Visa to:</strong> {visa?.to}
                                </p>
                                <p>
                                    <strong>Departure Date:</strong>{" "}
                                    {convertDateToInputFormat(
                                        visa?.departureDate
                                    )}
                                </p>

                                <p className="highlight">
                                    <strong>Cost:</strong>{" "}
                                    {visa.reply?.budget}
                                </p>
                            </div>
                            <p className="visa-requirement">
                                <strong>Requirement:</strong>{" "}
                                {visa.reply?.requirement}
                            </p>
                        </div>
                    ))}
                </div>
            )}

            {/* Ticket Details */}
            {application.ticket && (
                <div className="service-details">
                    <h2 className="service-title">Ticket Details</h2>
                    {application.ticket.tickets.map((ticket, index) => (
                        <div key={index} className="ticket-details">
                            <div
                                className="container ticket-container"
                                style={{ padding: "1rem 0" }}
                            >
                                <div className="left-container">
                                    <p>
                                        <strong>Ticket {index + 1}</strong>
                                    </p>
                                    <p className="highlight">
                                        <strong>Name:</strong>{" "}
                                        {ticket.reply?.name}
                                    </p>
                                    <p className="highlight">
                                        <strong>Date:</strong>{" "}
                                        {convertDateToInputFormat(
                                            ticket.reply?.date
                                        )}
                                    </p>
                                    <p className="highlight">
                                        <strong>Time:</strong>{" "}
                                        {ticket.reply?.time}
                                    </p>

                                    <p className="highlight">
                                        <strong>Venue:</strong>{" "}
                                        {ticket.reply?.venue}
                                    </p>
                                </div>
                                <div className="right-ticket-container">
                                    {ticket.reply?.seatNumber && (
                                        <p>
                                            <strong>Seat Number:</strong>{" "}
                                            {ticket.reply?.seatNumber}
                                        </p>
                                    )}
                                    {ticket.reply?.ticketNumber && (
                                        <p>
                                            <strong>Ticket Number:</strong>{" "}
                                            {ticket.reply?.ticketNumber}
                                        </p>
                                    )}
                                    {ticket.reply?.additionalInfo && (
                                        <p>
                                            <strong>Additional Info:</strong>{" "}
                                            {ticket.reply?.additionalInfo}
                                        </p>
                                    )}
                                    <p className="highlight">
                                        <strong>Cost:</strong>{" "}
                                        {ticket.reply?.budget}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <div className="action-buttons">
                <button onClick={()=>setShowConfirmModal(true)}>Accept</button>
                <button id="reject" onClick={handleModalOpen}>
                    Reject
                </button>
            </div>
            {showConfirmModal && (
                <Modal
                    className="modal"
                    show={showConfirmModal}
                    onHide={() => setShowConfirmModal(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to accept this application?
                    </Modal.Body>

                    <Modal.Footer>
                        <button id="submit-btn" onClick={handleAccept}>
                            Confirm
                        </button>
                        <button
                            id="cancel-btn"
                            onClick={() => setShowConfirmModal(false)}
                        >
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>
            )}

            {showModal && (
                <Modal
                    className="modal"
                    show={showModal}
                    onHide={handleModalClose}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Rejection Reason</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <textarea
                            style={{ width: "100%", height: "10rem" }}
                            value={comment}
                            onChange={handleCommentChange}
                            placeholder="Enter your reason for rejection. Such as: The budget is too low, the time of flight is not suitable, the date is not available, etc."
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <button id="submit-btn" onClick={handleSubmitRejection}>
                            Submit
                        </button>
                        <button id="cancel-btn" onClick={handleModalClose}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>
            )}
            {showModal2 && (
                <Modal
                    className="modal"
                    show={showModal2}
                    onHide={handleModalClose2}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Thank You</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        JR International B.V. will start booking and will send the
                        documents to your email.
                    </Modal.Body>

                    <Modal.Footer>
                        <button
                            id="submit-btn"
                            onClick={handleConfirmAcceptance}
                        >
                            Confirm
                        </button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default Suggestion;
