import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Status = () => {
    const baseURL = "https://jrbtms.fly.dev";
    const history = useHistory();
    const mongoUserId = sessionStorage.getItem("mongoUserId");
    const [application, setApplication] = useState(null);
    const [userApplication, setUserApplication] = useState(null);
    const [sortStatusAscending, setSortStatusAscending] = useState(true);
    const [sortDateAscending, setSortDateAscending] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        // Fetch companies from the server
        fetch(`${baseURL}/application`)
            .then((response) => response.json())
            .then((data) => setApplication(data))
            .catch((error) =>
                console.error("Error fetching companies:", error)
            );
    }, []);

    useEffect(() => {
        console.log("application", application);
        if (application) {
            const applicationsOfUser = application.filter(
                (app) =>
                    app.userId._id == mongoUserId && app.status !== "Completed"
            );
            // Sort by status initially
            applicationsOfUser.sort((a, b) => {
                if (a.status === "Suggestion" && b.status !== "Suggestion") {
                    return -1;
                }
                if (a.status !== "Suggestion" && b.status === "Suggestion") {
                    return 1;
                }
                return a.status.localeCompare(b.status);
            });

            setUserApplication(applicationsOfUser);
        }
    }, [application]);

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const getSelectedServices = (app) => {
        const services = [];
        if (app.flight) services.push("Flight");
        if (app.hotel) services.push("Hotel");
        if (app.visa) services.push("Visa");
        if (app.ticket) services.push("Ticket");
        return services.join(", ");
    };

    const toggleSortByDate = () => {
        if (userApplication) {
            const sortedApplications = [...userApplication].sort((a, b) => {
                const dateA = new Date(a.createTime);
                const dateB = new Date(b.createTime);
                return sortDateAscending ? dateA - dateB : dateB - dateA;
            });
            setUserApplication(sortedApplications);
            setSortDateAscending(!sortDateAscending); // Toggle sorting order
        }
    };

    const toggleSortByStatus = () => {
        if (userApplication) {
            const sortedApplications = [...userApplication].sort((a, b) => {
                if (sortStatusAscending) {
                    return a.status.localeCompare(b.status);
                } else {
                    return b.status.localeCompare(a.status);
                }
            });
            setUserApplication(sortedApplications);
            setSortStatusAscending(!sortStatusAscending); // Toggle sorting order
        }
    };

    const getTripName = (fullName) => {
        return fullName.replace(/.* - /, "");
    };

    const filterApplications = (applications, query) => {
        if (!query) return applications;
    
        return applications.filter((app) => {
            const tripName = getTripName(app.name).toLowerCase();
            const appNumber = app._id.toLowerCase();
            const services = getSelectedServices(app).toLowerCase();
            const applyDate = formatDate(app.createTime).toLowerCase();
            const status = app.status.toLowerCase();
            const departureDate = formatDate(app.flight?.segments[0].departureDate).toLowerCase();
            const returnDate = formatDate(app.flight?.segments[0].leaveDate).toLowerCase();
            const checkInDate = formatDate(app.hotel?.stays[0].checkInDate).toLowerCase();
            const checkOutDate = formatDate(app.hotel?.stays[0].checkOutDate).toLowerCase();
            
    
            return (
                tripName.includes(query.toLowerCase()) ||
                appNumber.includes(query.toLowerCase()) ||
                services.includes(query.toLowerCase()) ||
                applyDate.includes(query.toLowerCase())||
                status.includes(query.toLowerCase()) ||
                departureDate.includes(query.toLowerCase()) ||
                returnDate.includes(query.toLowerCase()) ||
                checkInDate.includes(query.toLowerCase()) ||
                checkOutDate.includes(query.toLowerCase())
                // Add more conditions for other fields
            );
        });
    };
    

    const handleApplicationClick = (applicationId, status) => {
        if (status === "Suggestion") {
            history.push(`/btms/suggestion/${applicationId}`);
        } else {
            history.push(`/btms/status/${applicationId}`);
        }
    };

    return (
        <div className="status-page">
            <div className="sort-buttons">
                <h2>Application Status</h2>
                <input
                className="search-bar"
                type="text"
                placeholder="Search by any word or number"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
                <button onClick={toggleSortByDate} className="sort-button">
                    Sort by Date
                </button>
                <button className="sort-button" onClick={toggleSortByStatus}>
                    Sort by Status
                </button>
            </div>
            <div className="application-list">
                {userApplication ? (
                    filterApplications(userApplication, searchQuery).map((app) => (
                        <div
                            key={app._id}
                            className={`application-box ${
                                app.status === "Suggestion" ? "click-suggestion" : "click-others"
                            }`}
                            onClick={() =>
                                handleApplicationClick(app._id, app.status)
                            }
                            onKeyDown={(event) => handleKeyDown(event, app._id)}
                            role="button"
                            tabIndex={0}
                        >
                            <h3 className="application-title">
                                {getTripName(app.name)}
                            </h3>
                            
                            <div className="container">
                                <div className="left-container">
                                    <p>{app?.flight? 
                                    app.flight?.segments.map((segment) => (
                                        <div key={segment._id}>
                                            From:{" "}
                                            {formatDate(segment.departureDate)}{" "}
                                            to {formatDate(segment.leaveDate)}
                                        </div>
                                    )) : app.hotel?.stays.map((segment) => {
                                        return (
                                            <div key={segment._id}>
                                                From:{" "}
                                                {formatDate(segment.checkInDate)}{" "}
                                                to {formatDate(segment.checkOutDate)}
                                            </div>
                                        );
                                    })}</p>
                                    <p>
                                        Apply Date: {formatDate(app.createTime)}
                                    </p>
                                    <p>Services: {getSelectedServices(app)}</p>
                                    <p className="app-number">Application number: {app._id}</p>
                                </div>
                                <div
                                    className={`status-button status-${app.status.toLowerCase()}`}
                                >
                                    {app.status}
                                </div>
                                
                            </div>
                        </div>
                    ))
                ) : (
                    <div>Loading...</div>
                )}
            </div>
        </div>
    );
};

export default Status;
