import React from "react";
import PropTypes from "prop-types";
import PageTitle from "../../../components/page-title";

const PageBanner = ({ title, excerpt, image }) => {
    return (
        <div className="section position-relative">
            <div className="hero-shape2">
                <img
                    src={image}
                    alt="shape"
                />
            </div>

            <div className="container">
                <div className="row">
                    
                        <div className="hero-slider">
                            <div className="container">
                                <div className="row align-items-center">
                                    
                                        <div className="hero-slide-content">
                                            <h2 className="title animated mt-10">
                                                <PageTitle
                                                    title={title}
                                                    excerpt={excerpt}
                                                />
                                            </h2>
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                    
                </div>
            </div>
        </div>
    );
};

PageBanner.propTypes = {
    title: PropTypes.string,
    excerpt: PropTypes.string,
    image: PropTypes.string,
};

export default PageBanner;
