import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import CustomModal from "./CustomModal";

const Tickets = ({
    tickets,
    ticketIndex,
    updateTicketSegment,
    submitted,
    flightSegments,
    flightType,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const handleLocationChange = (e) => {
        updateTicketSegment(ticketIndex, "location", e.target.value);
    };

    const handleEventDateChange = (e) => {
        updateTicketSegment(ticketIndex, "eventDate", e.target.value);
    };

    const handleRequirementChange = (e) => {
        updateTicketSegment(ticketIndex, "requirement", e.target.value);
    };

    const symbol = (
        <svg
            className="input-icon"
            aria-describedby="desc"
            aria-labelledby="title"
            role="img"
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M55 18.7A6.9 6.9 0 0 1 45.3 9l-7-7L2 38.3l7 7a6.9 6.9 0 0 1 9.7 9.7l7 7L62 25.7z"
                data-name="layer2"
                fill="none"
                stroke="#202020"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth={2}
            />
            <path
                d="M30.6 46a3 3 0 0 1-4.2 0L18 37.6a3 3 0 0 1 0-4.2L33.4 18a3 3 0 0 1 4.2 0l8.4 8.4a3 3 0 0 1 0 4.2z"
                data-name="layer1"
                fill="none"
                stroke="#202020"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth={2}
            />
        </svg>
    );

    const isDateWithinFlightRange = (eventDate) => {
        const eventTime = new Date(eventDate).getTime();

        if (flightType === "return" && firstFlightTime) {
            let lastFlightTime = new Date(
                flightSegments[0]?.leaveDate
            ).getTime();
            return eventTime <= lastFlightTime;
        }
        return true;
    };

    const currentDate = new Date().getTime();
    const firstFlightTime = new Date(
        flightSegments[0]?.departureDate
    ).getTime();

    const handleEventDateBlur = (e) => {
        const eventDate = new Date(e.target.value).getTime();
        if (isDateWithinFlightRange(eventDate) === false) {
            setModalMessage("Event date must before return date.");
            setShowModal(true);
        } else if (eventDate > 0 && eventDate < currentDate) {
            setModalMessage("Event date must be after today.");
            setShowModal(true);
        } else if (
            firstFlightTime &&
            eventDate > 0 &&
            eventDate < firstFlightTime
        ) {
            setModalMessage("Event date must be after first flight date.");
            setShowModal(true);
        }
    };

    return (
        <div className="services-content">
            <div className="hotel-information">
                <div className="upper-container">
                    <label>
                        <div className="input-wrapper">
                            <input
                                style={{ width: "45.5rem" }}
                                type="text"
                                className={`city-input ${
                                    submitted && !tickets.location && "error"
                                }`}
                                name="location"
                                value={tickets.location}
                                onChange={handleLocationChange}
                                placeholder="Location or Name of Event/Ticket"
                            />
                            {symbol}
                        </div>
                        {submitted && !tickets.location && (
                            <div className="error">Location is required.</div>
                        )}
                    </label>
                    <label>
                        <div className="input-wrapper">
                            <input
                                className={`date-input ${
                                    submitted && !tickets.eventDate && "error"
                                }`}
                                type="date"
                                name="eventDate"
                                value={tickets.eventDate}
                                onChange={handleEventDateChange}
                                placeholder="Event Date"
                                onBlur={handleEventDateBlur}
                            />
                        </div>
                        {submitted && !tickets.eventDate && (
                            <div className="error">Event date is required.</div>
                        )}
                    </label>
                </div>
                <div className="requirement" style={{ marginTop: "0" }}>
                    <label>
                        Requirement
                        <input
                            className={`requirement-input ${
                                submitted && !tickets.requirement && "error"
                            }`}
                            type="text"
                            name="requirement"
                            value={tickets.requirement}
                            onChange={handleRequirementChange}
                            placeholder="票務詳情、數量、其他需求"
                        />
                    </label>
                    {submitted && !tickets.requirement && (
                        <div className="error">Requirement is required.</div>
                    )}
                </div>
                <CustomModal
                    show={showModal}
                    message={modalMessage}
                    onClose={() => setShowModal(false)}
                />
            </div>
        </div>
    );
};

Tickets.propTypes = {
    tickets: PropTypes.object.isRequired,
    ticketIndex: PropTypes.number.isRequired,
    updateTicketSegment: PropTypes.func.isRequired,
    submitted: PropTypes.bool.isRequired,
    flightSegments: PropTypes.array.isRequired,
    flightType: PropTypes.string.isRequired,
};

export default Tickets;
