import React, { createContext, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { auth } from "./firebaseConfig";
import { signOut } from "@firebase/auth";

export const BTMSContext = createContext();

export const BTMSProvider = ({ children }) => {
    const baseURL = "https://jrbtms.fly.dev";
    const history = useHistory();

    const goBack = useCallback(() => {
        sessionStorage.removeItem("applicationId");
        history.goBack();
    }, [history]);

    const [step, setStep] = useState(1);

    const resetStep = useCallback(() => {
        setStep(prevStep => {
            console.log("Setting step from", prevStep, "to 1");
            return 1;
        });
    }, []);
    

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                sessionStorage.removeItem("mongoUserId");
                sessionStorage.removeItem("username");
                sessionStorage.removeItem("applicationId");
                sessionStorage.removeItem("isAdmin");
                sessionStorage.removeItem("companyId");
                sessionStorage.removeItem("userRole");
                resetStep();
                console.log(step);
                history.push("/btms");
            })
            .catch((error) => {
                console.error("Error logging out: ", error);
            });
    };

    const handleSegmentChange = (segments, setSegments, index, event) => {
        const values = [...segments];
        values[index][event.target.name] = event.target.value;
        setSegments(values);
    };

    const handleAddSegment = (
        segments,
        setSegments,
        validateSegment,
        onValidate
    ) => {
        const allErrors = segments.map((segment) => validateSegment(segment));
        onValidate(allErrors);

        if (
            allErrors.every(
                (segmentErrors) => Object.keys(segmentErrors).length === 0
            )
        ) {
            setSegments([
                ...segments,
                { departureDate: "", returnDate: "", from: "", to: "" },
            ]);
        }
    };

    const handleRemoveSegment = (segments, setSegments, index) => {
        const values = [...segments];
        values.splice(index, 1);
        setSegments(values);
    };

    const validatePassword = (password) => {
        if (password.length < 8)
            return "Password should be at least 8 characters long.";
        if (!/[a-z]/.test(password))
            return "Password should contain at least one lowercase letter.";
        if (!/[A-Z]/.test(password))
            return "Password should contain at least one uppercase letter.";
        if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password))
            return "Password should contain at least one special character: ! @ # $ % ^ & * ( ) _ + - = [ ] { } ; : ' \" \\ | , . < > / ?";

        return "";
    };

    const username = sessionStorage.getItem("username");

    const validateTel = (tel) => {
        const cleanedTel = tel.replace(/[\s-]/g, ""); // remove spaces and hyphens

        if (!cleanedTel.startsWith("+") && cleanedTel.length < 9) {
            return "Phone number seems too short.";
        }

        if (cleanedTel.startsWith("+") && cleanedTel.length < 10) {
            return "Phone number seems too short with country code.";
        }

        if (cleanedTel.length > 15) {
            return "Phone number seems too long.";
        }

        if (!/^(\+)?[0-9\s-]*$/.test(tel)) {
            return "Please use only numbers, spaces, and hyphens.";
        }

        return "";
    };

    const handleDepartureDateBlur = (date) => {
        const currentDate = new Date();
        const oneYearFromNow = new Date(currentDate);
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

        const departureDate = new Date(date);

        if (departureDate < currentDate || departureDate > oneYearFromNow) {
            alert(
                "Please ensure the date is between tomorrow and 1 year from today."
            );
        }
    };

    const handleCheckinDateBlur = (departureDate, checkinDate) => {
        const departure = new Date(departureDate);
        const checkin = new Date(checkinDate);

        const diffTime = checkin - departure;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays > 2) {
            alert(
                "Your check-in date is more than 2 days after your departure. Please confirm if this is intended."
            );
        } else if (diffDays < 0) {
            alert(
                "Your check-in date cannot be earlier than your departure date. Please adjust the dates."
            );
        }
    };

    const handleLeaveDateBlur = (checkinDate, leaveDate) => {
        const checkin = new Date(checkinDate);
        const leave = new Date(leaveDate);

        if (leave < checkin) {
            alert(
                "Your leave date cannot be earlier than your check-in date. Please adjust the dates."
            );
        }
    };

    const convertDateToInputFormat = (isoDateString) => {
        if (!isoDateString) return "";
        const date = new Date(isoDateString);
        return date.toISOString().split("T")[0];
    };

    const updateApplicationStatus = async (status) => {
        const applicationId = sessionStorage.getItem("applicationId");
        try {
            const response = await fetch(
                `${baseURL}/application/${applicationId}`,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ status: status }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to update application status.");
            }
        } catch (error) {
            console.error("Error updating application status: ", error);
        }
    };

    const checkUserRole = async () => {
        const userId = sessionStorage.getItem("mongoUserId");
        try {
            const response = await fetch(
                `${baseURL}/profile/${userId}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Failed to fetch user profile.");
            }

            const data = await response.json();
            if (data.role === "Admin") {
                sessionStorage.setItem("isAdmin", true);
                console.log("the user is admin");
            } else {
                sessionStorage.setItem("isAdmin", false);
                console.log("the user is not admin");
            }
        } catch (error) {
            console.error("Error fetching user profile: ", error);
        }
    };

    const initialState = {
        name: "",
        contact: {
            tel: "",
        },
        birth: null,
        sex: "M",
        nationality: "",

        passportInfo: {
            number: "",
            expiryDate: null,
        },
        idInfo: {
            number: "",
            expiryDate: null,
        },
    };

    const initialApplicantState = {
        userId: "",
        name: "",
        birth: null,
        sex: "M",
        nationality: "",
        email: "",
        passportInfo: {
            number: "",
            expiryDate: null,
        },
        idInfo: {
            number: "",
            expiryDate: null,
        },
    };

    return (
        <BTMSContext.Provider
            value={{
                goBack,
                handleLogout,
                handleSegmentChange,
                handleAddSegment,
                handleRemoveSegment,
                validatePassword,
                username,
                validateTel,
                handleDepartureDateBlur,
                handleCheckinDateBlur,
                handleLeaveDateBlur,
                convertDateToInputFormat,
                updateApplicationStatus,
                checkUserRole,
                step,
                setStep,
                resetStep,
                initialState,
                initialApplicantState,
            }}
        >
            {children}
        </BTMSContext.Provider>
    );
};

BTMSProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
