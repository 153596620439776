import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/logo";
import SocialIcon from "../../components/social-icon";
import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    const [showWeChatQR, setShowWeChatQR] = useState(false);
    return (
        <footer className="footer-section">
            <div className="footer-top position-relative">
                <img
                    className="footer-shape"
                    src={process.env.PUBLIC_URL + "/images/footer/sea.png"}
                    alt="shape"
                />
                <div className="container">
                    <div className="row mb-n7">
                        <div className="col-lg-3 col-sm-6">
                            <div className="footer-widget">
                                <Logo
                                    classOption="footer-logo mb-5"
                                    image={`${process.env.PUBLIC_URL}/images/logo/JR_logo_sky.png`}
                                />
                                <p>
                                    <Trans
                                        i18nKey="footer-disclaimer"
                                        components={{ br: <br /> }}
                                    />
                                </p>
                                <ul className="footer-social-links">
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.linkedin.com/company/jrlinkintl"
                                            icon="icofont-linkedin"
                                            target="_blank"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path={`mailto:info@jrlinkintl.com`}
                                            icon="icofont-email"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path={`https://wa.me/31618193025`}
                                            icon="icofont-whatsapp"
                                            target="_blank"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="#"
                                            icon="icofont-wechat"
                                            onClick={{
                                                showWeChatQR: showWeChatQR,
                                                toggleQR: () =>
                                                    setShowWeChatQR(
                                                        !showWeChatQR
                                                    ),
                                            }}
                                        />
                                    </li>

                                    
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 me-7 mb-7 offset-xl-1">
                            <div className="footer-widget">
                                <h4 className="title">
                                    {t("footer-quickLink")}
                                </h4>
                                <ul className="footer-menu">
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/about"
                                            }
                                        >
                                            {t("footer-aboutUs")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service"
                                            }
                                        >
                                            {t("footer-services")}
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL + "/blog"
                                            }
                                        >
                                            {t("footer-insight")}
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/contact"
                                            }
                                        >
                                            {t("footer-contactUs")}
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/privacy"
                                            }
                                        >
                                            {t("footer-privacy")}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 me-7 mb-7">
                            <div className="footer-widget ">
                                <h4 className="title">
                                    {t("footer-services")}
                                </h4>
                                <ul className="footer-menu">
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                `/service-details/1`
                                            }
                                        >
                                            {t("footer-service1")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                `/service-details/2`
                                            }
                                        >
                                            {t("footer-service2")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                `/service-details/3`
                                            }
                                        >
                                            {t("footer-service3")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                `/service-details/4`
                                            }
                                        >
                                            {t("footer-service4")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                `/service-details/5`
                                            }
                                        >
                                            {t("footer-service5")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                `/service-details/6`
                                            }
                                        >
                                            {t("footer-service6")}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mb-7 ">
                            <div className="footer-widget">
                                <h4 className="title">
                                    {t("footer-contactInfo")}
                                </h4>
                                <p>{t("footer-address")}</p>
                                <ul className="address">
                                    <li>
                                        <a
                                            className="address-link"
                                            href="tel:+310618193025"
                                        >
                                            +31 (0) 618 193 025
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="address-link"
                                            href="tel:+310627342441"
                                        >
                                            +31 (0) 627 342 441
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="address-link"
                                            href="mailto:info@jrlinkintl.com"
                                        >
                                            info@jrlinkintl.com
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="address-link"
                                            href="https://www.jrlinkintl.com"
                                        >
                                            www.jrlinkintl.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copy-right-section mt-10">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="copyright-info text-center">
                                    <p>
                                        {t("footer-copyright1")} &copy; 2024{" "}
                                        {t("footer-copyright2")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
