import Cookies from "js-cookie";
import Button from "../../../components/button";
import SectionTitle from "../../../components/section-title";
import { Trans, useTranslation } from "react-i18next";

const HomeAboutContainer = () => {
    const { t } = useTranslation();
    const currectLanguageCode = Cookies.get("i18next");

    const imageSrc = currectLanguageCode === 'zh' 
        ? `${process.env.PUBLIC_URL}/images/about/service-zh.png` 
        : `${process.env.PUBLIC_URL}/images/about/service.png`;


    return (
        <div className="about-us position-relative">
            <div className="container mt-6">
                <div className="row mb-n7 section-py pe-0">
                    <div className="col-xl-6 col-lg-6 mb-7">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                // subTitle={t("about-us-subTitle")}
                                title={t("about-us-title")}
                            />
                            {/* <span className="date-meta">
                                {t("about-us-since")} 2023
                                <span className="hr"></span>
                            </span> */}
                            <p className="high-light mb-5">
                                <Trans
                                    i18nKey="about-us-intro"
                                    components={{
                                        br: <br />,
                                        i: <i />,
                                        2: (
                                            <span
                                                style={{ color: "blue" }}
                                            ></span>
                                        ),
                                        1: (
                                            <span
                                                style={{ color: "brown" }}
                                            ></span>
                                        ),
                                    }}
                                />
                            </p>
                            <p className="mb-8">
                                <Trans
                                    i18nKey="about-us-highlight"
                                    components={{
                                        br: <br />,
                                        i: <i />,
                                        1: (
                                            <span
                                                style={{ color: "brown"}}
                                            ></span>
                                        ),
                                    }}
                                />
                            </p>
                            <Button
                                classOption="btn btn-lg btn-dark btn-hover-dark"
                                text={t("icon-box-container-btn")}
                                path="/about"
                            />
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 order-first order-lg-last mb-7">
                        <div
                            className="about-photo scene text-center text-lg-left about-photo-container"
                            id="scene"
                        >
                            <img
                                src={imageSrc}
                                alt="about"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* <img
                className="about-shape"
                src={`${process.env.PUBLIC_URL}/images/about/service.png`}
                alt="bg-shape"
            /> */}
        </div>
    );
};

export default HomeAboutContainer;
