import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const CustomForm = ({ status, message, onValidated }) => {
    let email;
    const submit = (e) => {
        e.preventDefault();
        email &&
            email.value.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email.value,
            });
        let emailInput = document.getElementById("mc-email");
        emailInput.value = "";
    };
    // Change Handaler
    const inputChangedHandler = (e) => {
        console.log(e.target.value);
    };
    return (
        <Fragment>
            <form className="news-letter-form d-flex">
                <input
                    id="mc-email"
                    className="form-control"
                    type="email"
                    placeholder="Enter Email here"
                    name="mail"
                    onChange={(e) => inputChangedHandler(e)}
                    ref={(node) => (email = node)}
                />
                <button className="search-btn" type="submit" onClick={submit}>
                    Subscribe
                </button>
            </form>
            {status === "sending" && (
                <div style={{ color: "#3498db", fontSize: "12px" }}>
                    sending...
                </div>
            )}
            {status === "error" && (
                <div
                    style={{ color: "#e74c3c", fontSize: "12px" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <div
                    style={{ color: "#2ecc71", fontSize: "12px" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
        </Fragment>
    );
};

CustomForm.propTypes = {
    status: PropTypes.oneOf(["sending", "error", "success"]),
    message: PropTypes.string,
    onValidated: PropTypes.func,
};
const Newsletter = () => {
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState("");
    const [showRecaptcha, setShowRecaptcha] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
        if (value) {
            submitForm(); // If reCAPTCHA is successful, submit the form
        }
    };

    const handleSubscribeClick = (e) => {
        e.preventDefault();
        setShowRecaptcha(true); // Show the reCAPTCHA when "Subscribe" is clicked
    };

    const submitForm = async () => {
        setStatus("sending");

        const formData = new FormData();
        formData.append("email", email);

        try {
            const response = await fetch("https://formspree.io/f/xqkvlplp", {
                method: "POST",
                body: formData,
                mode: "no-cors",
            });

            //after upgrading Formspree, I can use API to check
            // if (response.ok) {
            //     console.log("OK");
            //     setStatus("success");
            //     setEmail("");
            // } else {
            //     console.log("fail");
            //     setStatus("error");
            // }
            setStatus("success");
            setEmail("");
        } catch (error) {
            setStatus("error");
        }

        setShowRecaptcha(false); // Hide the reCAPTCHA after form submission
    };

    return (
        <div>
            <form className="news-letter-form d-flex">
                <input
                    className="form-control"
                    type="email"
                    placeholder="Enter Email here"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button
                    className="search-btn"
                    type="submit"
                    onClick={handleSubscribeClick}
                >
                    Subscribe
                </button>
            </form>
            {showRecaptcha && (
                <ReCAPTCHA
                    sitekey="6Lf2SiUoAAAAAEUOjvDwlUrLut3GZ-9Ot2Wpt6qu" // Replace with your Site Key
                    onChange={handleRecaptchaChange}
                />
            )}
            {status === "sending" && (
                <div style={{ color: "#3498db", fontSize: "12px" }}>
                    sending...
                </div>
            )}
            {status === "error" && (
                <div style={{ color: "#e74c3c", fontSize: "12px" }}>
                    Error. Please try again.
                </div>
            )}
            {status === "success" && (
                <div style={{ color: "#2ecc71", fontSize: "12px" }}>
                    Thank you for subscribing!
                </div>
            )}
        </div>
    );
};

export default Newsletter;
