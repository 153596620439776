import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { BTMSContext } from "../BTMSContext";
import "../assets/scss/_btms.scss";
import { use } from "i18next";

const Navbar = () => {
    const baseURL = "https://jrbtms.fly.dev";
    const [isAdmin, setIsAdmin] = useState(false);
    const { handleLogout } = useContext(BTMSContext);

    const userId = sessionStorage.getItem("mongoUserId");
    const checkUserRole = async () => {
        try {
            const response = await fetch(
                `${baseURL}/profile/${userId}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Failed to fetch user profile.");
            }

            const data = await response.json();
            if (data.role === "Admin") {
                setIsAdmin(true);
            } else {
                sessionStorage.setItem("isAdmin", false);
            }
        } catch (error) {
            console.error("Error fetching user profile: ", error);
        }
    };
    useEffect(() => {
        checkUserRole();
    }, [userId]);

    return (
        <div className="navbar">
            <NavLink exact to="/btms/frontpage" activeClassName="active">
                Home
            </NavLink>
            <NavLink exact to="/btms/application" activeClassName="active">
                New Application
            </NavLink>
            <NavLink to="/btms/status" activeClassName="active">
                Status of Application
            </NavLink>
            <NavLink to="/btms/history" activeClassName="active">
                History Application
            </NavLink>
            <NavLink to="/btms/registerinfo" activeClassName="active">
                Register Information
            </NavLink>
            <NavLink to="/btms/personalinfo" activeClassName="active">
                Personal Information
            </NavLink>
            {isAdmin && (
                    <>
                        <NavLink to="/btms/company" activeClassName="active">
                            Company Information
                        </NavLink>
                        <NavLink to="/btms/reply" activeClassName="active">
                            Pending Application
                        </NavLink>
                    </>
                )}

            <button id="logout" onClick={handleLogout}>Logout</button>
        </div>
    );
};

export default Navbar;
