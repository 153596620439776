import React, { useContext, useEffect, useState } from "react";
import { updatePassword } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { BTMSContext } from "../BTMSContext";
import { Toast } from "react-bootstrap";
import "../assets/scss/_btms.scss";

const RegisterInformation = () => {
    const baseURL = "https://jrbtms.fly.dev";
    const userId = sessionStorage.getItem("mongoUserId");
    const { validatePassword } = useContext(BTMSContext);

    // Password change
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [telError, setTelError] = useState("");
    const [showTelHint, setShowTelHint] = useState(false);

    const handleChangePassword = async () => {
        const passwordValidationMsg = validatePassword(newPassword);
        if (passwordValidationMsg) {
            setPasswordError(passwordValidationMsg);
            return;
        }

        try {
            const user = auth.currentUser;
            await updatePassword(user, newPassword);
            setShowToast(true);
            setNewPassword("");
            setConfirmNewPassword("");
        } catch (error) {
            console.error("Error updating password: ", error);
        }
    };

    // Profile update
    const initialState = {
        name: "",
        contact: {
            tel: "",
            address: "",
        },
        companyId: "",
        jobTitle: "",
    };

    const [profileData, setProfileData] = useState(initialState);

    useEffect(() => {
        if (!userId) {
            return;
        }

        const fetchProfileData = async () => {
            try {
                const response = await fetch(`${baseURL}/profile/${userId}`);

                const data = await response.json();

                setProfileData((prevState) => ({
                    ...prevState,
                    ...data,
                    name: data?.name || "",
                    contact: {
                        tel: (data.contact && data.contact?.tel) || "",
                        address: (data.contact && data.contact?.address) || "",
                    },
                    companyId: data.companyId || "",
                    jobTitle: data.jobTitle || "",
                }));
            } catch (error) {
                console.error("Error fetching profile data: ", error);
            }
        };
        fetchProfileData();
    }, [userId]);

    useEffect(() => {
        // Reset the state when the component is unmounted
        return () => setProfileData(initialState);
    }, []);

    const [showProfileUpdateToast, setShowProfileUpdateToast] = useState(false);

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();

        try {
            const response = await fetch(`${baseURL}/profile/${userId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ profileData }),
            });
            console.log("Profile updated successfully!");

            if (!response.ok) {
                throw new Error("Failed to update profile");
            }

            setShowProfileUpdateToast(true);
        } catch (error) {
            console.error("Error updating profile: ", error);
        }
    };

    return (
        <div className="profile-container">
            <form onSubmit={handleSubmit} className="profile-form">
                <h1>Register Information</h1>
                {/* Name */}
                <div className="info-container register-info">
                    <label>
                        Name:
                        <br/>
                        <input
                            type="text"
                            value={profileData.name}
                            onChange={(e) =>
                                setProfileData({
                                    ...profileData,
                                    name: e.target.value,
                                })
                            }
                            placeholder={
                                profileData.name ||
                                "Please enter the name in passport"
                            }
                        />
                    </label>
                    {/* Company */}
                    <label>
                        Company name:
                        <br/>
                        <input
                            style={{ color: "gray" }}
                            type="text"
                            value={profileData.companyId?.name}
                            placeholder={profileData.companyId}
                            readOnly
                        />
                    </label>

                    <label>
                        Job title:
                        <br/>
                        <input
                            type="text"
                            value={profileData.jobTitle}
                            onChange={(e) =>
                                setProfileData({
                                    ...profileData,
                                    jobTitle: e.target.value,
                                })
                            }
                            placeholder={
                                profileData.jobTitle || "Enter your job title"
                            }
                        />
                    </label>
                </div>
                <div className="contact-container">
                    {/* Contact */}
                    <label>
                        Phone number:
                        <br />
                        <input
                            type="text"
                            value={profileData.contact.tel}
                            onChange={(e) => {
                                setProfileData({
                                    ...profileData,
                                    contact: {
                                        ...profileData.contact,
                                        tel: e.target.value,
                                    },
                                });
                                setTelError("");
                            }}
                            onFocus={() => setShowTelHint(true)}
                            onBlur={() => setShowTelHint(false)}
                            placeholder={
                                profileData.contact.tel ||
                                "My phone number is..."
                            }
                        />
                    </label>
                    {showTelHint && (
                        <p className="hint">
                            Hint: Please start with a &quot;+&quot; for
                            international format. E.g., +310612345678
                        </p>
                    )}
                    {telError && <p className="error">{telError}</p>}
                    <label>
                        Your email
                        <br />
                        <input
                            style={{ width: "37rem" }}
                            type="text"
                            value={profileData.contact.email}
                            onChange={(e) =>
                                setProfileData({
                                    ...profileData,
                                    contact: {
                                        ...profileData.contact,
                                        email: e.target.value,
                                    },
                                })
                            }
                            readOnly
                            placeholder={profileData.userEmail}
                        />
                    </label>
                </div>

                <button type="submit">Update Profile</button>
                <Toast
                    onClose={() => setShowProfileUpdateToast(false)}
                    show={showProfileUpdateToast}
                    delay={3000}
                    autohide
                >
                    <Toast.Body>Profile updated successfully!</Toast.Body>
                </Toast>
            </form>
            <div className="profile-form mt-10">
                <h2>Change Password</h2>
                <div className="signup-form change-password">
                    <input
                        type="password"
                        placeholder="New password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Confirm new password"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />

                    <p className="password-hint">
                        Password requirements:
                        <ul>
                            <li
                                className={newPassword.length >= 8 ? "met" : ""}
                            >
                                At least 8 characters long
                            </li>
                            <li
                                className={
                                    /[a-z]/.test(newPassword) ? "met" : ""
                                }
                            >
                                At least 1 lowercase letter
                            </li>
                            <li
                                className={
                                    /[A-Z]/.test(newPassword) ? "met" : ""
                                }
                            >
                                At least 1 uppercase letter
                            </li>
                            <li
                                className={
                                    /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
                                        newPassword
                                    )
                                        ? "met"
                                        : ""
                                }
                            >
                                At least 1 special character (e.g. !@#$%^&*)
                            </li>
                        </ul>
                    </p>
                    {passwordError && <p className="error">{passwordError}</p>}

                    <Toast
                        onClose={() => setShowToast(false)}
                        show={showToast}
                        delay={3000}
                        autohide
                    >
                        <Toast.Body>Password updated successfully!</Toast.Body>
                    </Toast>
                    <button onClick={handleChangePassword}>
                        Change Password
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RegisterInformation;
