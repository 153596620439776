import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { BTMSContext } from "../BTMSContext";
import CustomModal from "./CustomModal";

const EmployeeModal = ({
    showModal,
    setShowModal,
    profileData,
    setProfileData,
    handleSubmit,
    userId,
    segments,
}) => {
    const [validationErrors, setValidationErrors] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [showCustomModal, setShowCustomModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const { initialState, convertDateToInputFormat } = useContext(BTMSContext);
    const symbol = (
        <svg
            className="input-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path d="M12 15.75A1.125 1.125 0 1 0 12 18a1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zm.75-2.25V5.25a.75.75 0 0 0-1.5 0v8.25a.75.75 0 0 0 1.5 0zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z"></path>
        </svg>
    );

    useEffect(() => {
        validateProfileData();
    }, [profileData]);

    const handleBirthDateBlur = (e) => {
        const today = new Date();
        const birthDate = new Date(profileData.birth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthDate.getDate())
        ) {
            age--;
        }

        if (age < 18) {
            setModalMessage(
                "You seem too young! Are you sure about your birthdate?"
            );
            setShowCustomModal(true);
            
            return;
        }
    }

    const handleDepartureDateBlur = (e) => {
        const expiryDate = new Date(profileData.passportInfo.expiryDate).getTime();
        const departureDate = new Date(segments[0].departureDate).getTime();
        console.log(expiryDate, departureDate);
        if (expiryDate < departureDate) {
            setModalMessage(
                "The document's expiry date is before the departure date. Please renew your document."
            );
            setShowCustomModal(true);
        }
    };

    const validateProfileData = () => {
        let errors = [];

        const validateEntries = (data, prefix = "") => {
            return Object.entries(data).every(([key, value]) => {
                if (
                    key === "idCopy" ||
                    key === "passportCopy" ||
                    key === "maritalStatus" ||
                    key === "idInfo" ||
                    key === "__v"
                ) {
                    return true;
                }

                if (typeof value === "object" && value !== null) {
                    return validateEntries(value, `${prefix}${key}.`);
                }

                if (key === "__v") {
                    return true;
                }

                const isValid =
                    typeof value === "string" && value.trim() !== "";

                if (!isValid) {
                    errors.push(`${prefix}${key}`);
                }

                return isValid;
            });
        };

        const isValid = validateEntries(profileData);

        setValidationErrors(errors);
        return isValid;
    };

    const resetProfileData = () => {
        setProfileData(initialState);
    };

    const modifiedHandleSubmit = (e) => {
        e.preventDefault();
        console.log("clicked");
        console.log(validationErrors);
        console.log(validateProfileData());
        setSubmitted(true);
        if (validateProfileData()) {
            handleSubmit(e);
            setSubmitted(false);
            resetProfileData();
        }
    };

    return (
        <Modal
            className="modal"
            show={showModal}
            onHide={() => setShowModal(false)}
        >
            <Modal.Header className="modal-header" closeButton>
                <Modal.Title className="modal-title">
                    Confirm Personal Data
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <label>
                    Your email <span className="required">*</span>
                    <input
                        type="text"
                        value={profileData.userEmail}
                        onChange={(e) =>
                            setProfileData({
                                ...profileData,
                                contact: {
                                    ...profileData,
                                    userEmail: e.target.value,
                                },
                            })
                        }
                        readOnly
                        placeholder={profileData.userEmail}
                    />
                </label>
                <label>
                    Phone number <span className="required">*</span>
                    <div className="input-wrapper">
                        <input
                            type="text"
                            value={profileData.contact.tel}
                            onChange={(e) => {
                                setProfileData({
                                    ...profileData,
                                    contact: {
                                        ...profileData.contact,
                                        tel: e.target.value,
                                    },
                                });
                            }}
                            className={
                                submitted && !profileData.contact.tel
                                    ? "error"
                                    : ""
                            }
                            placeholder={
                                profileData.contact.tel ||
                                "My phone number is..."
                            }
                        />
                        {!profileData.contact.tel && submitted && symbol}
                    </div>
                    {!profileData.contact.tel && submitted && (
                        <p className="error-message">
                            Phone number is required.
                        </p>
                    )}
                </label>

                <label>
                    Name on Passport <span className="required">*</span>
                    <div className="input-wrapper">
                        <input
                            type="text"
                            value={profileData.name}
                            onChange={(e) =>
                                setProfileData({
                                    ...profileData,
                                    name: e.target.value,
                                })
                            }
                            placeholder={
                                profileData.name ||
                                "Please enter the name in passport"
                            }
                            className={
                                submitted && !profileData.name ? "error" : ""
                            }
                        />
                        {!profileData.name && submitted && symbol}
                    </div>
                    {!profileData.name && submitted && (
                        <p className="error-message">Name is required.</p>
                    )}
                </label>

                <label>
                    Birth <span className="required">*</span>
                    <div className="input-wrapper">
                        <input
                            type="date"
                            value={profileData?.birth}
                            onChange={(e) =>
                                setProfileData({
                                    ...profileData,
                                    birth: e.target.value,
                                })
                            }
                            placeholder={
                                convertDateToInputFormat(profileData.birth) ||
                                ""
                            }
                            className={
                                submitted && !profileData.birth ? "error" : ""
                            }
                            onBlur={handleBirthDateBlur}
                        />
                    </div>
                    {!profileData.birth && submitted && (
                        <p className="error-message">Birth is required.</p>
                    )}
                </label>
                {/* Sex */}
                <label>
                    Sex <span className="required">*</span>
                    <select
                        type="text"
                        value={profileData?.sex || "M"}
                        onChange={(e) =>
                            setProfileData({
                                ...profileData,
                                sex: e.target.value,
                            })
                        }
                        placeholder={profileData.sex || ""}
                    >
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                    </select>
                </label>

                {/* Nationality */}
                <label>
                    Nationality <span className="required">*</span>
                    <div className="input-wrapper">
                        <input
                            type="text"
                            value={profileData.nationality}
                            onChange={(e) =>
                                setProfileData({
                                    ...profileData,
                                    nationality: e.target.value,
                                })
                            }
                            placeholder={
                                profileData.nationality ||
                                "Enter your nationality"
                            }
                            className={
                                submitted && !profileData.nationality
                                    ? "error"
                                    : ""
                            }
                        />
                        {!profileData.nationality && submitted && symbol}
                    </div>
                    {!profileData.nationality && submitted && (
                        <p className="error-message">
                            Nationality is required.
                        </p>
                    )}
                </label>

                <label>
                    Passport Number <span className="required">*</span>
                    <div className="input-wrapper">
                        <input
                            type="text"
                            value={profileData.passportInfo.number}
                            onChange={(e) =>
                                setProfileData({
                                    ...profileData,
                                    passportInfo: {
                                        ...profileData.passportInfo,
                                        number: e.target.value,
                                    },
                                })
                            }
                            placeholder={
                                profileData.passportInfo.number ||
                                "Enter your passport number"
                            }
                            className={
                                submitted && !profileData.passportInfo.number
                                    ? "error"
                                    : ""
                            }
                        />
                        {!profileData.passportInfo.number &&
                            submitted &&
                            symbol}
                    </div>
                    {!profileData.passportInfo.number && submitted && (
                        <p className="error-message">
                            Passport number is required.
                        </p>
                    )}
                </label>
                <label>
                    Expiry Date <span className="required">*</span>
                    <div className="input-wrapper">
                        <input
                            name="expiryDate"
                            type="date"
                            value={profileData.passportInfo.expiryDate}
                            onChange={(e) =>
                                setProfileData({
                                    ...profileData,
                                    passportInfo: {
                                        ...profileData.passportInfo,
                                        expiryDate: e.target.value,
                                    },
                                })
                            }
                            placeholder={
                                convertDateToInputFormat(
                                    profileData.passportInfo.expiryDate
                                ) || ""
                            }
                            className={
                                submitted &&
                                !profileData.passportInfo.expiryDate
                                    ? "error"
                                    : ""
                            }
                            onBlur={handleDepartureDateBlur}
                        />
                    </div>
                    {!profileData.passportInfo.expiryDate && submitted && (
                        <p className="error-message">
                            Expiry date is required.
                        </p>
                    )}
                </label>

                <label>
                    ID Number:
                    <div className="input-wrapper">
                        <input
                            type="text"
                            value={profileData.idInfo.number}
                            onChange={(e) =>
                                setProfileData({
                                    ...profileData,
                                    idInfo: {
                                        ...profileData.idInfo,
                                        number: e.target.value,
                                    },
                                })
                            }
                            placeholder={
                                profileData.idInfo.number ||
                                "Enter your ID number"
                            }
                        />
                    </div>
                </label>

                <label>
                    Expiry Date:
                    <div className="input-wrapper">
                        <input
                            name="expiryDate"
                            type="date"
                            value={profileData.idInfo.expiryDate}
                            onChange={(e) =>
                                setProfileData({
                                    ...profileData,
                                    idInfo: {
                                        ...profileData.idInfo,
                                        expiryDate: e.target.value,
                                    },
                                })
                            }
                            placeholder={
                                convertDateToInputFormat(
                                    profileData.idInfo.expiryDate
                                ) || ""
                            }
                        />
                    </div>
                </label>
                <CustomModal
                    show={showCustomModal}
                    message={modalMessage}
                    onClose={() => setShowCustomModal(false)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setShowModal(false);
                        setSubmitted(false);
                    }}
                >
                    Close
                </Button>
                <Button variant="primary" onClick={modifiedHandleSubmit}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

EmployeeModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    profileData: PropTypes.object.isRequired,
    setProfileData: PropTypes.func.isRequired,
    docType: PropTypes.string.isRequired,
    setDocType: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    segments: PropTypes.array.isRequired,
};

export default EmployeeModal;
