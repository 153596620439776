import Cookies from "js-cookie";
import PropTyps from "prop-types";
import SocialIcon from "../../components/social-icon";

const Team = ({ data }) => {
    const currectLanguageCode = Cookies.get("i18next");

    return (
        <div className="new-team-members-list">
            <img src={process.env.PUBLIC_URL + data.thumb} alt="images" />
            <h3 className="title">{data.name[0][currectLanguageCode]}</h3>
            <span>{data.designation[0][currectLanguageCode]}</span>
            <p>{data.description[0][currectLanguageCode]}</p>
            <ul className="team-social-links">
                <li>
                    <SocialIcon
                        classOption="team-social-link"
                        path={data.linkedin}
                        icon="icofont-linkedin"
                    />
                </li>
                <li>
                    <SocialIcon
                        classOption="team-social-link team-social-link-2"
                        path={`mailto:${data.email}`}
                        icon="icofont-email"
                    />
                </li>
            </ul>
        </div>
    );
};

Team.propTypes = {
    data: PropTyps.object,
};

export default Team;
