import React, { useState, useEffect, useContext } from "react";
import { Toast } from "react-bootstrap";
import { BTMSContext } from "../BTMSContext";
import EmployeeModal from "../components/EmployeeModal";

const CompanyInformation = () => {
    const baseURL = "https://jrbtms.fly.dev";
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyDetails, setCompanyDetails] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalUserId, setModalUserId] = useState(null);

    const { initialState,convertDateToInputFormat } = useContext(BTMSContext);
    const [profileData, setProfileData] = useState(initialState);
    const [docType, setDocType] = useState("passport");

    console.log("Company page is clicked");

    const fetchProfileData = async (userId) => {
        try {
            const response = await fetch(
                `${baseURL}/profile/${userId}`
            );
            if (!response.ok) {
                throw new Error("Failed to fetch profile data");
            }
            const data = await response.json();

            setProfileData((prevState) => ({
                ...prevState,
                ...data,
                name: data?.name || "",
                birth: data?.birth
                    ? convertDateToInputFormat(data?.birth)
                    : null,
                sex: data?.sex || "",
                nationality: data?.nationality || "",
                contact: {
                    tel: (data.contact && data.contact?.tel) || "",
                },
                passportInfo: {
                    number:
                        (data.passportInfo && data.passportInfo?.number) || "",
                    expiryDate: data.passportInfo?.expiryDate
                        ? convertDateToInputFormat(data.passportInfo.expiryDate)
                        : null,
                },
                idInfo: {
                    number: (data.idInfo && data.idInfo?.number) || "",
                    expiryDate:
                        data.idInfo && data.idInfo?.expiryDate
                            ? convertDateToInputFormat(data.idInfo.expiryDate)
                            : null,
                },
            }));
        } catch (error) {
            console.error("Error fetching profile data: ", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Validation about age and issue date
            const today = new Date();
            const birthDate = new Date(profileData.birth);
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();

            if (
                monthDifference < 0 ||
                (monthDifference === 0 && today.getDate() < birthDate.getDate())
            ) {
                age--;
            }

            if (age < 18) {
                alert("You seem too young! Are you sure about your birthdate?");
                return;
            }

           

            const response = await fetch(
                `${baseURL}/profile/${modalUserId}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        profileData,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to update profile");
            }

            console.log("Profile updated successfully!");
            setShowModal(false);
        } catch (error) {
            console.error("Error updating profile: ", error);
        }
    };

    useEffect(() => {
        // Fetch companies from the server
        fetch(`${baseURL}/company`)
            .then((response) => response.json())
            .then((data) => setCompanies(data))
            .catch((error) =>
                console.error("Error fetching companies:", error)
            );
    }, []);

    const handleAddCompany = async () => {
        try {
            const response = await fetch(`${baseURL}/company`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ name: companyName }),
            });
            const newCompany = await response.json();
            setCompanies([...companies, newCompany]);
            setShowToast(true);
            console.log(newCompany.name, "added to database");
        } catch (error) {
            console.error("Error adding company:", error);
        }
    };

    const handleCompanyChange = async (event) => {
        const companyId = event.target.value;
        setSelectedCompany(companyId);

        try {
            const response = await fetch(`${baseURL}/company/${companyId}`);
            const data = await response.json();
            setCompanyDetails(data);
        } catch (error) {
            console.error("Error fetching company details:", error);
        }
    };

    const getAllUsers = () => {
        // Combine users from all roles into a single list
        const users = [];
        if (companyDetails) {
            ["director", "manager", "user"].forEach((role) => {
                if (companyDetails[role]) {
                    companyDetails[role].forEach((user) => {
                        users.push({ ...user, role }); // Assign the role to the user for sorting
                    });
                }
            });
        }
        return users;
    };

    const handleRoleChange = async (userId, newRole) => {
        try {
            let oldRole = null;
            let userToUpdate = null;

            // Look for the user in each role category to find the oldRole and userToUpdate
            for (const role of Object.keys(companyDetails)) {
                if (Array.isArray(companyDetails[role])) {
                    userToUpdate = companyDetails[role].find(
                        (usr) => usr._id === userId
                    );
                    if (userToUpdate) {
                        oldRole = role;
                        break;
                    }
                }
            }

            if (!userToUpdate) {
                throw new Error("User not found in company details");
            }

            // Update the role in the profile
            const updatedProfileData = { role: newRole };
            console.log(updatedProfileData);
            const response = await fetch(
                `${baseURL}/profile/${userId}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ profileData: updatedProfileData }),
                }
            );

            console.log("role changed in User profile");

            // If the profile update is successful, update the company details
            if (response.ok) {
                console.log(userId, newRole, oldRole);
                const response = await fetch(
                    `${baseURL}/company/${selectedCompany}`,
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ userId, newRole, oldRole }),
                    }
                );

                if (response.ok) {
                    const updatedCompany = await response.json();

                    // Update local state with the new company details
                    setCompanyDetails(updatedCompany);
                    console.log("role updated in Company");
                } else {
                    throw new Error(
                        "Failed to update company with new user role"
                    );
                }
            } else {
                throw new Error("Failed to update user profile");
            }
        } catch (error) {
            console.error("Error updating user role:", error);
        }
    };

    const handleEmployeeClick = async (userId) => {
        console.log("userId is", userId);
        setShowModal(true);
        setModalUserId(userId); 
        await fetchProfileData(userId);
    };

    return (
        <div className="company-container">
            <div className="left-container">
                {/* Add new company */}
                <div className="add-company-container">
                    <input
                        type="text"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        placeholder="New Company Name"
                    />
                    <Toast
                        onClose={() => setShowToast(false)}
                        show={showToast}
                        delay={3000}
                        autohide
                    >
                        <Toast.Body>The company added successfully!</Toast.Body>
                    </Toast>
                    <button onClick={handleAddCompany}>Add Company</button>
                </div>

                {/* Select company */}
                <div>
                    <h2>Select a company</h2>
                    <select
                        value={selectedCompany}
                        onChange={handleCompanyChange}
                    >
                        <option value="">Select a company</option>
                        {companies.map((company) => (
                            <option key={company._id} value={company._id}>
                                {company.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {/* Display company details */}
            <div className="right-container">
                {companyDetails && (
                    <div className="employee-container">
                        <h2>Employees:</h2>
                        <ul>
                            {getAllUsers()
                                .sort((a, b) => {
                                    const order = [
                                        "Admin",
                                        "director",
                                        "manager",
                                        "user",
                                    ];
                                    return (
                                        order.indexOf(a.role) -
                                        order.indexOf(b.role)
                                    );
                                })
                                .map((usr, index) => (
                                    <li
                                        key={index}
                                        className="employee-list-item"
                                    >
                                        <button
                                            className="employee-name"
                                            onClick={() =>
                                                handleEmployeeClick(usr._id)
                                            }
                                        >
                                            {usr.name}
                                        </button>
                                        <select
                                            className="employee-role"
                                            value={usr.role}
                                            onChange={(e) =>
                                                handleRoleChange(
                                                    usr._id,
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="Admin">Admin</option>
                                            <option value="director">
                                                Director
                                            </option>
                                            <option value="manager">
                                                Manager
                                            </option>
                                            <option value="user">User</option>
                                        </select>
                                    </li>
                                ))}
                        </ul>
                        {/* <button
                            onClick={() =>
                                console.log(companyDetails.application)
                            }
                        >
                            Application Record
                        </button> */}

                        <EmployeeModal
                            showModal={showModal}
                            setShowModal={setShowModal}
                            profileData={profileData}
                            setProfileData={setProfileData}
                            setDocType={setDocType}
                            handleSubmit={handleSubmit}
                            userId={modalUserId}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default CompanyInformation;
