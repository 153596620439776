import React, { useContext, useEffect, useState } from "react";
import { BTMSContext } from "../BTMSContext";
import "../assets/scss/_btms.scss";
import { useHistory } from "react-router-dom";

const FrontPage = () => {
    const baseURL = "https://jrbtms.fly.dev";
    const history = useHistory();
    const { username, validateTel } = useContext(BTMSContext);
    const userId = sessionStorage.getItem("mongoUserId");
    const [currentTime, setCurrentTime] = useState(
        new Date().toLocaleString("en-US", { timeZone: "Asia/Shanghai" })
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(
                new Date().toLocaleString("en-US", {
                    timeZone: "Asia/Shanghai",
                })
            );
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    //handle signup registerinfo
    const initialState = {
        name: "",
        contact: {
            tel: "",
        },
        sex: "",
        companyId: "",
        jobTitle: "",
    };
    const [step, setStep] = useState(1);
    const [profileData, setProfileData] = useState(initialState);
    const [telError, setTelError] = useState("");
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        fetch(`${baseURL}/company`)
            .then((response) => response.json())
            .then((data) => setCompanies(data))
            .catch((error) =>
                console.error("Error fetching companies:", error)
            );
    }, []);

    useEffect(() => {
        if (!userId) {
            return;
        }

        const fetchProfileData = async () => {
            try {
                console.log("start fetching profile data");
                const response = await fetch(
                    `${baseURL}/profile/${userId}`
                );

                const data = await response.json();

                setProfileData((prevState) => ({
                    ...prevState,
                    ...data,
                    name: data?.name || "",
                    contact: {
                        tel: (data.contact && data.contact?.tel) || "",
                    },
                    companyId: data.companyId || "",
                    jobTitle: data.jobTitle || "",
                }));
                console.log(data);

                let newStep = 1;
                if (data?.name && data.name !== "") {
                    newStep = 2;
                }
                if (
                    data?.companyId &&
                    data?.companyId !== "" &&
                    data?.jobTitle &&
                    data?.jobTitle !== ""
                ) {
                    newStep = 3;
                }
                if (data.contact?.tel && data.contact.tel !== "") {
                    newStep = 4; // Directly go to the registration info display if contact data is present
                }

                setStep(newStep);
            } catch (error) {
                console.error("Error fetching profile data: ", error);
            }
        };
        fetchProfileData();
    }, [userId]);

    useEffect(() => {
        // Reset the state when the component is unmounted
        return () => setProfileData(initialState);
    }, []);

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        console.log("profileData", profileData);

        try {
            const response = await fetch(
                `${baseURL}/profile/${userId}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ profileData }),
                }
            );
            console.log("Profile updated successfully!");

            if (!response.ok) {
                throw new Error("Failed to update profile");
            }

            if (profileData.companyId !== "") {
                const companyId = profileData.companyId;
                sessionStorage.setItem("companyId", companyId);
                const companyResponse = await fetch(
                    `${baseURL}/company/${companyId}`,
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            userId: userId,
                            newRole: "user",
                            oldRole: "user",
                        }),
                    }
                );

                if (!companyResponse.ok) {
                    throw new Error(
                        "Failed to update company with user association"
                    );
                }
            }

            console.log("Profile updated successfully!");
        } catch (error) {
            console.error("Error updating profile: ", error);
        }
        handleNextStep();
    };

    const handleNext = () => {
        let hasError = false;

        // Validate based on current step
        switch (step) {
            case 1:
                if (profileData.name === "") {
                    alert("Please provide your name.");
                    hasError = true;
                }
                if (profileData.sex === "") {
                    alert("Please confirm your title.");
                    hasError = true;
                }
                break;
            case 2:
                if (
                    profileData.companyId === "" ||
                    profileData.jobTitle === ""
                ) {
                    alert("Please provide your company information.");
                    hasError = true;
                }

                break;
            case 3:
                if (profileData.contact.tel === "") {
                    alert("Please provide your contact number.");
                    hasError = true;
                }
                sessionStorage.setItem("username", profileData.name);
                break;
            default:
                break;
        }

        // If there's a validation error, stop and don't proceed to the next step
        if (hasError) {
            return;
        }

        handleSubmit();

        if (step === 3) {
            const telErrorMsg = validateTel(profileData.contact.tel);
            if (telErrorMsg) {
                setTelError(telErrorMsg);
                hasError = true;
                return;
            }
            // history.push("/btms/frontpage"); // Redirect to the front page
        }
    };

    const handlePrevious = () => {
        setStep((prevStep) => prevStep - 1);
    };

    const handleNextStep = () => {
        setStep((prevStep) => prevStep + 1);
    };

    return (
        <div className="frontpage-container">
            {step < 4 ? (
                <>
                    {step === 1 && (
                        <div className="dialog-style">
                            <p>Welcome to BTMS! May I have your name?</p>
                            <div className="container">
                                <select
                                    value={profileData.sex || ""}
                                    onChange={(e) =>
                                        setProfileData({
                                            ...profileData,
                                            sex: e.target.value,
                                        })
                                    }
                                    required
                                >
                                    <option value="" disabled>
                                        Your title?
                                    </option>
                                    <option value="M">Mr.</option>
                                    <option value="F">Miss</option>
                                </select>
                                <input
                                    type="text"
                                    value={profileData.name}
                                    onChange={(e) =>
                                        setProfileData({
                                            ...profileData,
                                            name: e.target.value,
                                        })
                                    }
                                    placeholder="Please enter the name in passport"
                                    required
                                />
                            </div>
                            <button onClick={handleNext}>Next</button>
                        </div>
                    )}
                    {step === 2 && (
                        <div className="dialog-style">
                            <p>
                                Hi {profileData.name}! Which company do you work
                                for?
                            </p>

                            <select
                                style={{
                                    fontSize: "1rem",
                                    padding: "0.5rem",
                                    marginBottom: "1rem",
                                }}
                                value={profileData.companyId}
                                onChange={(e) =>
                                    setProfileData({
                                        ...profileData,
                                        companyId: e.target.value,
                                    })
                                }
                            >
                                <option value="">Select your company</option>
                                {companies.map((company) => (
                                    <option
                                        key={company._id}
                                        value={company._id}
                                    >
                                        {company.name}
                                    </option>
                                ))}
                            </select>
                            <p>And your job title?</p>
                            <input
                                type="text"
                                value={profileData.jobTitle}
                                onChange={(e) =>
                                    setProfileData({
                                        ...profileData,
                                        jobTitle: e.target.value,
                                    })
                                }
                                placeholder="My job title is..."
                            />
                            <button onClick={handlePrevious}>Previous</button>
                            <button onClick={handleNext}>Next</button>
                        </div>
                    )}
                    {step === 3 && (
                        <div className="dialog-style">
                            <p>How can we contact you?</p>
                            <label>
                                Phone number:
                                <input
                                    type="text"
                                    value={profileData.contact.tel}
                                    onChange={(e) => {
                                        setProfileData({
                                            ...profileData,
                                            contact: {
                                                ...profileData.contact,
                                                tel: e.target.value,
                                            },
                                        });
                                        setTelError("");
                                    }}
                                    placeholder={
                                        profileData.contact.tel ||
                                        "My phone number is..."
                                    }
                                />
                            </label>
                            <p className="hint">
                                Hint: Please start with a &quot;+&quot; for
                                international format. E.g., +310612345678
                            </p>
                            {telError && <p className="error">{telError}</p>}

                            <br />
                            <button onClick={handlePrevious}>Previous</button>
                            <button onClick={handleNext}>Next</button>
                        </div>
                    )}
                </>
            ) : (
                <>
                {profileData.name !== undefined? (<h1>Welcome {profileData.name}!</h1>):(<h1>Welcome!</h1>)}
                    

                    <div className="content">
                        <div className="links">
                            <div className="buttons">
                                <button
                                    onClick={() =>
                                        history.push("/btms/application")
                                    }
                                >
                                    New Application
                                </button>
                                <p>
                                    A platform to create a new application for
                                    your requirements.
                                </p>
                            </div>
                            <div className="buttons">
                                <button
                                    onClick={() => history.push("/btms/status")}
                                >
                                    Status of My Application
                                </button>
                                <p>
                                    Check the current status of your
                                    applications. E.g., Pending, Approved,
                                    Rejected, etc.
                                </p>
                            </div>
                            <div className="buttons">
                                <button
                                    onClick={() =>
                                        history.push("/btms/history")
                                    }
                                >
                                    History Application
                                </button>
                                <p>
                                    View a history of all your past applications
                                    and their respective outcomes.
                                </p>
                            </div>
                            <div className="buttons">
                                <button
                                    onClick={() =>
                                        history.push("/btms/registerinfo")
                                    }
                                >
                                    Register Information
                                </button>
                                <p>
                                    View and update your registration details.
                                </p>
                            </div>
                            <div className="buttons">
                                <button
                                    onClick={() =>
                                        history.push("/btms/personalinfo")
                                    }
                                >
                                    Personal Information
                                </button>
                                <p>
                                    View and modify your personal information
                                    for better service.
                                </p>
                            </div>
                        </div>
                        <div className="time">
                            Current time in China: {currentTime}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default FrontPage;
