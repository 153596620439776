import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

const loadingMarkup = (
    <div className="py-4 text-center">
      <h2>Loading..</h2>
    </div>
  );  

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <Suspense fallback={loadingMarkup}>
        <App />
    </Suspense>
);
