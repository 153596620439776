import PropTypes from "prop-types";
import SocialIconWechat from "../social-icon-wechat";

const SocialIcon = ({ path, icon, classOption, onClick }) => {
    if (icon === "icofont-wechat") {
        return (
            <SocialIconWechat 
                classOption={classOption} 
                showWeChatQR={onClick.showWeChatQR} 
                toggleQR={onClick.toggleQR} 
            />
        );
    }

    return (
        <a
            className={`${classOption}`}
            href={process.env.PUBLIC_URL + path}
            target="_blank"
            rel="noopener noreferrer"
        >
            <i className={icon}></i>
        </a>
    );
};

SocialIcon.propTypes = {
    path: PropTypes.string,
    icon: PropTypes.string,
    classOption: PropTypes.string,
    onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};
SocialIcon.defaultProps = {
    classOption: "footer-social-link",
};

export default SocialIcon;
