import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import PageBanner from "../containers/global/page-banner";
import LegalContainer from "../containers/legal";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import { useTranslation } from "react-i18next";

const ServicePage = () => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Layout>
                <SEO title="JR International – Service" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={t("footer-privacy")}
                        
                        image={process.env.PUBLIC_URL + "./images/blog/eu.jpg"}
                    />
                    <LegalContainer />
                
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ServicePage;
