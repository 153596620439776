import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { slugify } from "../../utils";
import Cookies from "js-cookie";
import { Trans } from "react-i18next";

const ServiceBox = ({ data }) => {
    const currectLanguageCode = Cookies.get("i18next");

    return (
        <div className="service-media">
            <Link
                to={
                    process.env.PUBLIC_URL +
                    `/service-details/${slugify(data.id)}`
                }
            >
                <img
                    className="logo"
                    src={process.env.PUBLIC_URL + data.icon}
                    alt=" service logo"
                />
            </Link>
            <div className="service-media-body">
                <h4 className="title">
                    <Link
                        to={
                            process.env.PUBLIC_URL +
                            `/service-details/${slugify(data.id)}`
                        }
                    >
                        {data.title[0][currectLanguageCode]}
                    </Link>
                </h4>
                <ul>
                    <Trans
                        i18nKey={data.excerpt[0][currectLanguageCode]}
                        components={{
                            br: <br />,
                            li: <li style={{ listStyleType: "disc" }}></li>,
                        }}
                    />
                </ul>
            </div>
        </div>
    );
};

ServiceBox.propTypes = {
    data: PropTypes.object,
};

export default ServiceBox;
