import React, { useState } from "react";
import PropTypes from "prop-types";
import "../assets/scss/_btms.scss";
import CustomModal from "./CustomModal";

const Hotels = ({
    segment,
    segmentIndex,
    updateSegment,
    submitted,
    locationSelected,
    locationRef,
    setLocationSelected,
    showHint1,
    setShowHint1,
    flightSegments,
    flightType,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const handleCommentChange = (e) => {
        updateSegment(segmentIndex, "hotelComments", e.target.value);
    };

    // Add handlers for other fields
    const handleLocationChange = (e) => {
        updateSegment(segmentIndex, "location", e.target.value);
    };

    const handleCheckInDateChange = (e) => {
        updateSegment(segmentIndex, "checkInDate", e.target.value);
    };

    const handleCheckOutDateChange = (e) => {
        updateSegment(segmentIndex, "checkOutDate", e.target.value);
    };

    const symbol = (
        <svg
            className="input-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path d="M2.75 12h18.5c.69 0 1.25.56 1.25 1.25V18l.75-.75H.75l.75.75v-4.75c0-.69.56-1.25 1.25-1.25zm0-1.5A2.75 2.75 0 0 0 0 13.25V18c0 .414.336.75.75.75h22.5A.75.75 0 0 0 24 18v-4.75a2.75 2.75 0 0 0-2.75-2.75H2.75zM0 18v3a.75.75 0 0 0 1.5 0v-3A.75.75 0 0 0 0 18zm22.5 0v3a.75.75 0 0 0 1.5 0v-3a.75.75 0 0 0-1.5 0zm-.75-6.75V4.5a2.25 2.25 0 0 0-2.25-2.25h-15A2.25 2.25 0 0 0 2.25 4.5v6.75a.75.75 0 0 0 1.5 0V4.5a.75.75 0 0 1 .75-.75h15a.75.75 0 0 1 .75.75v6.75a.75.75 0 0 0 1.5 0zm-13.25-3h7a.25.25 0 0 1 .25.25v2.75l.75-.75h-9l.75.75V8.5a.25.25 0 0 1 .25-.25zm0-1.5A1.75 1.75 0 0 0 6.75 8.5v2.75c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75V8.5a1.75 1.75 0 0 0-1.75-1.75h-7z"></path>
        </svg>
    );

    const isDateWithinFlightRange = (checkInDate, checkOutDate) => {
        const firstFlightDate = flightSegments[0]?.departureDate;
        const firstFlightTime = new Date(firstFlightDate).getTime();
        const checkInTime = new Date(checkInDate).getTime();
        if (
            flightType === "return" &&
            (firstFlightDate || flightSegments[0]?.leaveDate)
        ) {
            let lastFlightDate = flightSegments[0]?.leaveDate;
            let checkOutTime = new Date(checkOutDate).getTime();
            let lastFlightTime = new Date(lastFlightDate).getTime();

            return (
                checkInTime >= firstFlightTime && checkOutTime <= lastFlightTime
            );
        } else if (flightType === "single") {
            return checkInTime >= firstFlightTime;
        } else if (flightType === "multi-city") {
            return checkInTime >= firstFlightTime;
        }
        return true;
    };

    const currentDate = new Date().getTime();

    const handleCheckInDateBlur = (e) => {
        const checkInDate = new Date(e.target.value).getTime();
        const checkOutDate = new Date(segment.checkOutDate).getTime();

        if (isDateWithinFlightRange(checkInDate, checkOutDate) === false) {
            setModalMessage(
                "Hotel check-in and check-out dates must be within the range of flight dates."
            );
            setShowModal(true);
        } else if (
            checkInDate > 0 &&
            checkOutDate > 0 &&
            checkInDate > checkOutDate
        ) {
            setModalMessage("Check-in date must be before check-out date.");
            setShowModal(true);
        } else if (checkInDate > 0 && checkInDate < currentDate) {
            setModalMessage("Check-in date must be after today.");
            setShowModal(true);
        }
    };

    const handleCheckOutDateBlur = (e) => {
        const checkOutDate = new Date(e.target.value).getTime();
        const checkInDate = new Date(segment.checkOutDate).getTime();
        if (isDateWithinFlightRange(checkInDate, checkOutDate) === false) {
            setModalMessage(
                "Hotel check-in and check-out dates must be within the range of flight dates."
            );
            setShowModal(true);
        } else if (
            checkInDate > 0 &&
            checkOutDate > 0 &&
            checkInDate > checkOutDate
        ) {
            setModalMessage("Check-in date must be before check-out date.");
            setShowModal(true);
        } else if (checkOutDate > 0 && checkOutDate < currentDate) {
            setModalMessage("Check-out date must be after today.");
            setShowModal(true);
            
        }
    };

    return (
        <div className="services-content">
            <div className="hotel-information">
                <div className="upper-container">
                    <label>
                        <div className="input-wrapper">
                            <input
                                className={`city-input ${
                                    submitted && !segment.location && "error"
                                }`}
                                type="text"
                                value={segment.location}
                                placeholder="Where are you going?"
                                onChange={handleLocationChange}
                                ref={(ref) =>
                                    (locationRef.current[segmentIndex] = ref)
                                }
                                onFocus={() => setShowHint1(true)}
                                onBlur={() => {
                                    if (!locationSelected[segmentIndex]) {
                                        updateSegment(segmentIndex, "to", "");
                                    }
                                    let newDestinationSelected = [
                                        ...locationSelected,
                                    ];
                                    newDestinationSelected[
                                        segmentIndex
                                    ] = false;
                                    setLocationSelected(newDestinationSelected);
                                    setShowHint1(false);
                                }}
                            />
                            {symbol}
                            {submitted && !segment.location && (
                                <div className="error-message">
                                    Please enter a city
                                </div>
                            )}
                        </div>
                    </label>
                    <label>
                        <div className="input-wrapper">
                            <input
                                className={`date-input ${
                                    submitted && !segment.checkInDate && "error"
                                }`}
                                type="date"
                                value={segment.checkInDate || ""}
                                onChange={handleCheckInDateChange}
                                onBlur={handleCheckInDateBlur}
                                placeholder="Check-in Date"
                            />
                            {submitted && !segment.checkInDate && (
                                <div className="error-message">
                                    Check-in date
                                </div>
                            )}
                        </div>
                    </label>
                    <label>
                        <div className="input-wrapper">
                            <input
                                style={{ marginRight: "0px" }}
                                className={`date-input ${
                                    submitted &&
                                    !segment.checkOutDate &&
                                    "error"
                                }`}
                                type="date"
                                value={segment.checkOutDate || ""}
                                onChange={handleCheckOutDateChange}
                                onBlur={handleCheckOutDateBlur}
                                placeholder="Check-out Date"
                            />
                            {submitted && !segment.checkOutDate && (
                                <div className="error-message">
                                    Check-out date
                                </div>
                            )}
                        </div>
                    </label>
                </div>
                <div className="requirement">
                    <label>
                        Requirements:
                        <input
                            className={`requirement-input ${
                                submitted && !segment.hotelComments && "error"
                            }`}
                            type="text"
                            maxLength={100}
                            value={segment.hotelComments}
                            onChange={handleCommentChange}
                            placeholder="酒店等級、房間要求、其他需求"
                        />
                    </label>
                    {submitted && !segment.hotelComments && (
                        <div className="error-message">
                            Please enter your requirements
                        </div>
                    )}
                </div>
                <CustomModal
                show={showModal}
                message={modalMessage}
                onClose={() => setShowModal(false)}
            />
            </div>
            
        </div>
    );
};

Hotels.propTypes = {
    segment: PropTypes.object.isRequired,
    segmentIndex: PropTypes.number.isRequired,
    updateSegment: PropTypes.func.isRequired,
    submitted: PropTypes.bool.isRequired,
    locationRef: PropTypes.object.isRequired,
    showHint1: PropTypes.bool.isRequired,
    setShowHint1: PropTypes.func.isRequired,
    locationSelected: PropTypes.array.isRequired,
    setLocationSelected: PropTypes.func.isRequired,
    flightSegments: PropTypes.array.isRequired,
    flightType: PropTypes.string.isRequired,
};

export default Hotels;
