import React, { useEffect, useState } from "react";

const History = () => {
    const baseURL = "https://jrbtms.fly.dev";
    const userId = sessionStorage.getItem("mongoUserId");
    const [applications, setApplications] = useState([]);

    useEffect(() => {
        // Fetch applications from the server
        fetch(`${baseURL}/application`)
            .then((response) => response.json())
            .then((data) => {
                const completedApplications = data.filter(
                    (app) => app.userId === userId && app.status === "Completed"
                );
                setApplications(completedApplications);
            })
            .catch((error) =>
                console.error("Error fetching applications:", error)
            );
    }, [userId]);

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const getSelectedServices = (app) => {
        const services = [];
        if (app.flight) services.push("Flight");
        if (app.hotel) services.push("Hotel");
        if (app.visa) services.push("Visa");
        if (app.ticket) services.push("Ticket");
        return services.join(", ");
    };

    const getTripName = (fullName) => {
        return fullName.replace(/.* - /, "");
    };

    return (
        <div className="status-page">
            <h2 style={{marginBottom: "2rem"}}>History Application</h2>
            <div className="application-list" >
                {applications.length > 0 ? (
                    applications.map((app) => (
                        <div key={app._id} className="application-box">
                            <h3 className="application-title">
                                {getTripName(app.name)}
                            </h3>
                            <div className="container">
                                <div className="left-container">
                                    <p>Apply Date: {formatDate(app.createTime)}</p>
                                    <p>Services: {getSelectedServices(app)}</p>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div>No completed applications found.</div>
                )}
            </div>
        </div>
    );
};

export default History;
