import React, { useState } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { useTranslation } from "react-i18next";
// import Funfact from "../../../components/funfact";
// import HomeData from "../../../data/home.json";

const FunFactContainer = ({ classOption }) => {
    const { t } = useTranslation();
    const [didViewCountUp, setDidViewCountUp] = useState(false);

    const onVisibilityChange = (isVisible) => {
        if (isVisible) {
            setDidViewCountUp(true);
        }
    };
    return (
        <div className={``}>
            <div className="container mt-10 ">
                <div className="mb-10 d-flex justify-content-around">
                    <div className="fanfact text-md-left text-center">
                        <span className="title">
                            <span className="counter">
                                <VisibilitySensor
                                    onChange={onVisibilityChange}
                                    offset={{ top: 10 }}
                                    delayedCall
                                >
                                    <CountUp end={didViewCountUp ? 18 : 0} />
                                </VisibilitySensor>
                            </span>
                            +
                        </span>
                        <h6 className="sub-title">{t("fun-fact-title1")}</h6>
                    </div>
                    <div className="fanfact text-md-left text-center">
                        <span className="title">
                            <span className="counter">
                                <VisibilitySensor
                                    onChange={onVisibilityChange}
                                    offset={{ top: 10 }}
                                    delayedCall
                                >
                                    <CountUp end={didViewCountUp ? 16 : 0} />
                                </VisibilitySensor>
                            </span>
                            +
                        </span>
                        <h6 className="sub-title">{t("fun-fact-title2")}</h6>
                    </div>
                    <div className="fanfact text-md-left text-center">
                        <span className="title">
                            <span className="counter">
                                <VisibilitySensor
                                    onChange={onVisibilityChange}
                                    offset={{ top: 10 }}
                                    delayedCall
                                >
                                    <CountUp end={didViewCountUp ? 8 : 0} />
                                </VisibilitySensor>
                            </span>
                            +
                        </span>
                        <h6 className="sub-title">{t("fun-fact-title3")}</h6>
                    </div>
                    {/* <div className="fanfact text-md-left text-center">
                        <span className="title">
                        
                            <span className="counter">
                                <VisibilitySensor
                                    onChange={onVisibilityChange}
                                    offset={{ top: 10 }}
                                    delayedCall
                                >
                                    <CountUp end={didViewCountUp ? 2023 : 0} />
                                </VisibilitySensor>
                            </span>
                            
                        </span>
                        <h6 className="sub-title">{t("fun-fact-title4")}</h6>
                    </div> */}
                </div>
            </div>
            <svg
                className="funfact-svg"
                id="funfact"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1920 535"
            >
                <path
                    className="funfact-path"
                    d="M1950,327.309S1666,1.64,1336,111.4,906,290.324,671,164.377,120.868,57.118-64,225.351"
                />
                <path
                    id="Shape_5_copy"
                    data-name="Shape 5 copy"
                    className="funfact-path"
                    d="M1919.04,532.448s-223.79-369.607-567.75-318.167-454.362,102.4-664.214-62.038S163.566-47.917-47.473,86.059"
                />
            </svg>
        </div>
    );
};

FunFactContainer.propTypes = {
    classOption: PropTypes.string,
};

FunFactContainer.defaultProps = {
    classOption: "mt-10 mt-lg-0",
};

export default FunFactContainer;
