import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BTMSContext } from "../BTMSContext";
import { Modal } from "react-bootstrap";
import "../assets/scss/_btms.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ApplicationContent = () => {
    const baseURL = "https://jrbtms.fly.dev";
    const { convertDateToInputFormat, goBack } = useContext(BTMSContext);
    const { applicationId } = useParams();
    const [application, setApplication] = useState(null);
    const [ticketDate, setTicketDate] = useState("");
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();

    const getTripName = (fullName) => {
        return fullName.replace(/.* - /, "");
    };

    useEffect(() => {
        const fetchApplication = async () => {
            try {
                const response = await fetch(
                    `${baseURL}/application/${applicationId}`
                );
                if (!response.ok) {
                    throw new Error("Application not found");
                }
                const data = await response.json();
                setApplication(data);
            } catch (error) {
                console.error("Error fetching application:", error);
            }
        };

        fetchApplication();
    }, [applicationId]);

    useEffect(() => {
        if (application && application.ticket && application.ticket.tickets) {
            setTicketDate(
                convertDateToInputFormat(
                    application.ticket.tickets[0]?.eventDate
                )
            );
        }
    }, [application]);

    const handleEdit = () => {
        history.push(`/btms/application/${applicationId}/edit`);
    };

    const handleDelete = async () => {
        try {
            const response = await fetch(
                `${baseURL}/application/${applicationId}`,
                {
                    method: "DELETE",
                }
            );
            if (!response.ok) {
                throw new Error("Failed to delete application");
            }
            goBack();
        } catch (error) {
            console.error("Error deleting application:", error);
        }
    };

    if (!application) {
        return <div>Loading...</div>;
    }

    return (
        <div className="application-content">
            <h2>{getTripName(application.name)}</h2>
            <h4>
                Submission Date:{" "}
                {new Date(application.createTime).toLocaleDateString()}
            </h4>
            {application.status === "Rejected" && (
                <>
                    <h3 className="rejected">
                        Rejected reason: <br />{application.comments}
                    </h3>
                </>
            )}
            <p style={{ textAlign: "center" }}>
                Application number: {application._id}
            </p>

            <div className="trip-details">
                <h3>Trip Details</h3>
                <div className="details-container">
                    <div className="service-container"></div>

                    <div className="service-container">
                        {application && application.flight && (
                            <div className="service-item flight-item">
                                <h3>
                                    <strong>Flight</strong>
                                </h3>
                                <p>Type: {application.flight?.type} </p>
                                <p>
                                    Budget: {application.budget?.travel} (
                                    {application.budget?.currency})
                                </p>
                                <p>
                                    Requirement: {application.flight.comments}
                                </p>

                                {application.flight.segments.map(
                                    (segment, index) => (
                                        <div key={index}>
                                            {application.flight.segments
                                                .length > 1 && (
                                                <h3>Trip {index + 1}</h3>
                                            )}
                                            <p>
                                                From: {segment.from} to{" "}
                                                {segment.to}
                                            </p>
                                            <p>
                                                Date:{" "}
                                                {new Date(
                                                    segment.departureDate
                                                ).toLocaleDateString()}{" "}
                                                to{" "}
                                                {new Date(
                                                    segment.leaveDate
                                                ).toLocaleDateString()}
                                            </p>
                                        </div>
                                    )
                                )}
                            </div>
                        )}

                        {application && application.hotel && (
                            <div className="service-item hotel-item">
                                <h3>
                                    <strong>Hotel</strong>
                                </h3>
                                {application.hotel.stays.map((stay, index) => (
                                    <div key={index} className="hotel-detail">
                                        <p>Stay at {stay.location}</p>
                                        <p>
                                            {" "}
                                            Date:{" "}
                                            {new Date(
                                                stay.checkInDate
                                            ).toLocaleDateString()}{" "}
                                            to{" "}
                                            {new Date(
                                                stay.checkOutDate
                                            ).toLocaleDateString()}
                                        </p>
                                        <p id="requirement">
                                            Requirement: {stay.hotelComments}
                                        </p>
                                    </div>
                                ))}
                                <p>
                                    Budget: {application.budget?.hotel} (
                                    {application.budget?.currency})
                                </p>
                            </div>
                        )}
                        {application &&
                            application.visa &&
                            application.visa.visas.map((segment, index) => (
                                <div
                                    key={index}
                                    className="service-item visa-item"
                                >
                                    <h3>
                                        <strong>Visa</strong>
                                    </h3>
                                    <p> to {segment.to}</p>
                                    <p>
                                        {" "}
                                        Departure Date:{" "}
                                        {new Date(
                                            segment.departureDate
                                        ).toLocaleDateString()}{" "}
                                    </p>
                                </div>
                            ))}

                        {application &&
                            application.ticket &&
                            application.ticket.tickets.map((ticket, index) => (
                                <div
                                    key={index}
                                    className="service-item ticket-item"
                                >
                                    <h3>
                                        <strong>Ticket</strong>
                                    </h3>
                                    <p>Date: {ticketDate}</p>
                                    <p>Location: {ticket.location}</p>
                                    <p>Requirements: {ticket.requirement}</p>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            <div style={{ display: "flex" }}>
                <div>
                    {application &&
                        (application.status === "Draft" ||
                            application.status === "Rejected") && (
                            <button id="edit" onClick={handleEdit}>
                                Edit
                            </button>
                        )}
                </div>
                <button onClick={goBack}>My Applications</button>
                {application.status === "Draft" && (
                    <button id="delete" onClick={() => setShowModal(true)}>
                        Delete Application
                    </button>
                )}
            </div>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Application</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this application?
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setShowModal(false)}>Cancel</button>
                    <button onClick={handleDelete}>Delete</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ApplicationContent;
