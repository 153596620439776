import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
    const { t } = useTranslation();
    const { register, errors, handleSubmit, reset } = useForm({
        mode: "onBlur",
    });
    const [showRecaptcha, setShowRecaptcha] = useState(false);
    const [formStatus, setFormStatus] = useState(""); // "sending", "success", or "error"
    const [pendingFormData, setPendingFormData] = useState(null);
    const handleRecaptchaChange = (value) => {
        if (value && pendingFormData) {
            submitForm(pendingFormData); // Pass the stored form data
        }
    };

    const submitForm = async (data) => {
        setFormStatus("sending");
        try {
            const response = await fetch(
                "https://formspree.io/f/xqkvlplp",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }
            );
            if (response.ok) {
                setFormStatus("success");
                reset(); // Clear the input fields
            } else {
                setFormStatus("error");
            }
        } catch (error) {
            setFormStatus("error");
        }
        setShowRecaptcha(false); // Hide the reCAPTCHA after form submission
    };

    const onSubmitWithRecaptcha = (data) => {
        setShowRecaptcha(true);
        setPendingFormData(data); 
    };

    return (
        <Fragment>
            <form
                id="contactForm"
                className="row"
                onSubmit={handleSubmit(onSubmitWithRecaptcha)}
            >
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder={t("contactFormPh-name")}
                        ref={register({
                            required: t("formErrors.requiredName"),
                        })}
                    />
                    {errors.name && <p>{errors.name.message}</p>}
                </div>
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder={t("contactFormPh-email")}
                        ref={register({
                            required: t("formErrors.requiredEmail"),
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: t("formErrors.invalidEmail"),
                            },
                        })}
                    />
                    {errors.email && <p>{errors.email.message}</p>}
                </div>

                <div className="col-12 mb-9">
                    <textarea
                        className="form-control massage-control"
                        name="message"
                        id="massage"
                        cols="30"
                        rows="10"
                        placeholder={t("contactFormPh-message")}
                        ref={register({
                            required: t("formErrors.requiredMessage"),
                        })}
                    ></textarea>
                    {errors.message && <p>{errors.message.message}</p>}
                    {formStatus === "sending" && (
                        <div style={{ color: "#3498db", fontSize: "16px" }}>
                            {t("contactFormSending")}
                        </div>
                    )}
                    {formStatus === "error" && (
                        <div style={{ color: "#e74c3c", fontSize: "16px" }}>
                            {t("contactFormError")}
                        </div>
                    )}
                    {formStatus === "success" && (
                        <div style={{ color: "#2ecc71", fontSize: "16px" }}>
                            {t("contactFormSuccess")}
                        </div>
                    )}
                </div>
                <div className="col-12">
                    <button
                        id="contactSubmit"
                        type="submit"
                        className="btn btn-dark btn-hover-dark"
                        data-complete-text="Well Done!"
                    >
                        {t("contactFormBtn")}
                    </button>
                </div>
            </form>
            {showRecaptcha && (
                <ReCAPTCHA
                    sitekey="6Lf2SiUoAAAAAEUOjvDwlUrLut3GZ-9Ot2Wpt6qu" // Replace with your Site Key
                    onChange={handleRecaptchaChange}
                />
            )}
        </Fragment>
    );
};

export default ContactForm;