import React from 'react';
import PropTypes from 'prop-types';
import Navbar from '../components/Navbar'

const MainLayout = ({ children }) => {
    return (
        <>
            <Navbar />
            <div className="main-content">
                {children}
            </div>
        </>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MainLayout;
