import PropTypes from "prop-types";

const PageTitle = ({ title, excerpt }) => {
    return (
        <div className="banner-content banner-padding">
            <h3 className="title" style={{textShadow: "1px 1px 1px white"}}>{title}</h3>
            <p dangerouslySetInnerHTML={{ __html: excerpt }}></p>
        </div>
    );
};

PageTitle.propTypes = {
    title: PropTypes.string,
    excerpt: PropTypes.string,
};

export default PageTitle;
