import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "../assets/scss/_btms.scss";
import CustomModal from "./CustomModal";

const Visas = ({
    submitted,
    segment,
    segmentIndex,
    updateSegment,
    flightSegments,
    flightType,
}) => {
    const [countryList, setCountryList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    useEffect(() => {
        // Fetch the country list from an API
        const fetchCountries = async () => {
            try {
                const response = await fetch("YOUR_API_ENDPOINT");
                const data = await response.json();
                setCountryList(data.countries);
            } catch (error) {
                console.error("Error fetching country list:", error);
            }
        };

        fetchCountries();
    }, []);

    const handleCountryChange = (e) => {
        updateSegment(segmentIndex, "to", e.target.value);
    };

    const handleDepartureDateChange = (e) => {
        updateSegment(segmentIndex, "departureDate", e.target.value);
    };

    const toSymbol = (
        <svg
            className="input-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path d="M19.906 12.996l-12.72-6.2.421.672-.006-2.457a.75.75 0 0 0-.386-.654L5.207 3.244c-.95-.468-2.058-.091-2.51.826a1.85 1.85 0 0 0-.187.706l-.295 5.242a2.95 2.95 0 0 0 1.63 2.802l3.618 1.8-.117-1.27-4.461 3.359a1.85 1.85 0 0 0 .289 3.13l1.883.933a1.85 1.85 0 0 0 1.564.037l7.381-3.248-.636-.014 3.44 1.71a3.494 3.494 0 1 0 3.11-6.259.75.75 0 0 0-.334-.078h-.005v.75l.329-.674zm-.658 1.348a.75.75 0 0 0 .329.076h.005v-.75l-.334.672a1.994 1.994 0 1 1-1.774 3.571l-3.44-1.71a.75.75 0 0 0-.636-.014l-7.38 3.247a.35.35 0 0 1-.295-.008l-1.881-.932a.35.35 0 0 1-.053-.59l4.46-3.358a.75.75 0 0 0-.118-1.27l-3.618-1.8a1.45 1.45 0 0 1-.8-1.377l.295-5.238a.35.35 0 0 1 .504-.29l1.975 1.096-.386-.654.006 2.457a.75.75 0 0 0 .421.672l12.72 6.2zm-2.234-2.419l-.9-4.918a1.85 1.85 0 0 0-.995-1.32l-1.853-.92a1.85 1.85 0 0 0-2.669 1.657v3.234a.75.75 0 0 0 1.5 0V6.425a.35.35 0 0 1 .505-.313l1.85.918a.35.35 0 0 1 .187.249l.9 4.916a.75.75 0 1 0 1.475-.27z"></path>
        </svg>
    );

    const currentDate = new Date().getTime();
    const firstFlightTime = new Date(
        flightSegments[0]?.departureDate
    ).getTime();

    const isDateWithinFlightRange = (departureDate) => {
        const visaDepartureTime = new Date(departureDate).getTime();

        if (
            flightType === "return" &&
            (firstFlightTime || flightSegments[0]?.leaveDate)
        ) {
            let lastFlightTime = new Date(
                flightSegments[0]?.leaveDate
            ).getTime();
            return visaDepartureTime <= lastFlightTime;
        }
        return true;
    };

    const handleDepartureDateBlur = (e) => {
        const departureDate = new Date(e.target.value).getTime();
        const currentDate = new Date();
        const twoMonthsFromNow = new Date(currentDate);
        twoMonthsFromNow.setMonth(twoMonthsFromNow.getMonth() + 2);

        
        if (isDateWithinFlightRange(departureDate) === false) {
            setModalMessage("Visa date must before return date.");
            setShowModal(true);
        } else if (departureDate > 0 && departureDate < currentDate) {
            setModalMessage("Visa date must be after today.");
            setShowModal(true);
        } else if (
            firstFlightTime &&
            departureDate > 0 &&
            departureDate > firstFlightTime
        ) {
            setModalMessage("Visa date is after first flight date. You sure?");
            setShowModal(true);
        } else if (
            departureDate < twoMonthsFromNow
        ) {
            setModalMessage(
                "Disclaimer: Different embassies have varying processing times, and due to the application's timeframe of less than two months, we cannot guarantee a successful visa application."
            );
            setShowModal(true);
        }
    };

    return (
        <div className="visa-services-container">
            <div>
                <label>
                    <div className="input-wrapper">
                        <input
                            className={`city-input ${
                                submitted && !segment.to && "error"
                            }`}
                            type="text"
                            value={segment.to}
                            onChange={handleCountryChange}
                            placeholder="Where are you going?"
                            // list="countryList"
                        />
                        {toSymbol}
                    </div>
                    {submitted && !segment.to && (
                        <p className="error-message">Please enter a country.</p>
                    )}
                </label>
                {/* <datalist id="countryList">
                    {countryList.map((country, idx) => (
                        <option key={idx} value={country} />
                    ))}
                </datalist> */}
            </div>

            <label>
                <div className="input-wrapper">
                    <input
                        className={`date-input ${
                            submitted && !segment.departureDate && "error"
                        }`}
                        type="date"
                        value={segment.departureDate}
                        onChange={handleDepartureDateChange}
                        onBlur={handleDepartureDateBlur}
                    />
                </div>
                {submitted && !segment.departureDate && (
                    <p className="error-message">Departure date?</p>
                )}
            </label>
            <CustomModal
                show={showModal}
                message={modalMessage}
                onClose={() => setShowModal(false)}
            />
        </div>
    );
};

Visas.propTypes = {
    submitted: PropTypes.bool.isRequired,
    segment: PropTypes.object.isRequired,
    segmentIndex: PropTypes.number.isRequired,
    updateSegment: PropTypes.func.isRequired,
    flightSegments: PropTypes.array.isRequired,
    flightType: PropTypes.string.isRequired,
};

export default Visas;
