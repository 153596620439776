import React from "react";
import PropTypes from "prop-types";
import legalData from "../../data/legal.json";
import Cookies from "js-cookie";

const LegalContainer = ({}) => {
    const currectLanguageCode = Cookies.get("i18next");
    return (
        <div className="single-service mt-10">
            <div className="container pt-10">
                <div className="title-section">
                    <div className="service-content-wrap">
                        {legalData[0].body[0][currectLanguageCode].map(
                            (single, key) => {
                                return (
                                    <div
                                        className="desc"
                                        key={key}
                                        dangerouslySetInnerHTML={{
                                            __html: single,
                                        }}
                                    ></div>
                                );
                            }
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

LegalContainer.propTypes = {
    data: PropTypes.object,
};

export default LegalContainer;
