import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BTMSContext } from "../BTMSContext";
import CustomModal from "./CustomModal";
import "../assets/scss/_btms.scss";

const Budget = ({ onSubmit, budget, selectedServices }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const { updateApplicationStatus } = useContext(BTMSContext);
    const [budgetData, setBudgetData] = useState({
        travel: "",
        hotel: "",
        social: "",
        subsidy: "",
        communication: "",
        other: "",
        currency: "Euro",
    });

    useEffect(() => {
        if (budget) {
            setBudgetData(budget);
        }
    }, [budget]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBudgetData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isAnyFieldFilled = Object.keys(budgetData).some((key) => {
            const value = budgetData[key];
            if (key === "currency") return false; // Skip currency field

            // Check if numeric fields are filled (exclude empty strings and null/undefined values)
            if (
                typeof value === "number" ||
                (!isNaN(value) && value.trim() !== "")
            ) {
                return true;
            }
            return false;
        });

        if (!isAnyFieldFilled) {
            setModalMessage("Please fill in at least one budget field.");
            setShowModal(true);
            return;
        }
        onSubmit(budgetData);
        await updateApplicationStatus("Pending");
    };

    return (
        <div className="budget-container">
            <h2>Enter Your Budget</h2>
            <form onSubmit={handleSubmit}>
                <div className="budget-field">
                    <div className="budget-field">
                        <label>
                            Currency:
                            <select
                                name="currency"
                                value={budgetData?.currency}
                                onChange={handleChange}
                            >
                                <option value="Euro">Euro</option>
                                <option value="RMB">RMB</option>
                                <option value="USD">USD</option>
                                <option value="HKD">HKD</option>
                                <option value="GBP">GBP</option>
                            </select>
                        </label>
                    </div>
                    <label>
                        Travel expenses:
                        <input
                            type="number"
                            name="travel"
                            value={budgetData?.travel}
                            onChange={handleChange}
                        />
                        <p>Description about travel budget.</p>
                    </label>
                </div>

                <div className="budget-field">
                    <label>
                        Hotel expenses:
                        <input
                            type="number"
                            name="hotel"
                            value={budgetData?.hotel}
                            onChange={handleChange}
                        />
                        <p>Description about hotel budget.</p>
                    </label>
                </div>

                <div className="budget-field">
                    <label>
                        Business entertainment expenses:
                        <input
                            type="number"
                            name="social"
                            value={budgetData?.social}
                            onChange={handleChange}
                        />
                        <p>Description about social budget.</p>
                    </label>
                </div>

                <div className="budget-field">
                    <label>
                        Travel allowances:
                        <input
                            type="number"
                            name="subsidy"
                            value={budgetData?.subsidy}
                            onChange={handleChange}
                        />
                        <p>Description about Travel allowances.</p>
                    </label>
                </div>

                <div className="budget-field">
                    <label>
                        Communication expenses:
                        <input
                            type="number"
                            name="communication"
                            value={budgetData?.communication}
                            onChange={handleChange}
                        />
                        <p>Description about communication expenses.</p>
                    </label>
                </div>

                <div className="budget-field">
                    <label>
                        Other expenses:
                        <input
                            type="number"
                            name="other"
                            value={budgetData?.other}
                            onChange={handleChange}
                        />
                        <p>Any other expenses?</p>
                    </label>
                </div>
                <CustomModal
                    show={showModal}
                    message={modalMessage}
                    onClose={() => setShowModal(false)}
                />

                <button type="submit">Submit Budget</button>
            </form>
        </div>
    );
};

Budget.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    budget: PropTypes.object.isRequired,
    selectedServices: PropTypes.array.isRequired,
};
export default Budget;
