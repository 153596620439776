import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="robots" content="noindex, follow" />

            <meta
                name="description"
                content="Our integrated and customized services are meticulously designed to meet the unique demands of companies venturing into new horizons. We guide and support your international journey with precision and expertise, ensuring your success as you navigate global markets."
            />
            <meta
                name="keywords"
                content="European Market Expansion, Market Research, Entry Strategy, Compliance Assessment, Business Registration, Networking, Brand Localization, Sales and Marketing, Distribution Channels, Office Setup, HR Services, SCM, Financial Compliance, Performance Assessment, EU Markets Expansion, Long-Term Sustainability"
            />

            <meta
                name="description"
                content="我们精心设计的综合和定制服务，旨在满足涉足欧洲市场企业的独特需求。我们以精准和专业知识指导和支持您的出海之旅，确保您在全球市场中取得成功。"
            />
            <meta
                name="keywords"
                content="欧洲市场拓展、市场研究、进入策略、合规评估、商业登记、网络、品牌本地化、销售和营销、分销渠道、办公室设置、人力资源服务、供应链管理、财务合规、绩效评估、欧盟市场拓展、长期 -期限可持续性"
            />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
        </Helmet>
    );
};

SEO.propTypes = {
    title: PropTypes.string,
};

export default SEO;
