import React from 'react';
import PropTypes from 'prop-types';

const SocialIconWechat = ({ classOption, showWeChatQR, toggleQR }) => {
    return (
        <div>
            <button
                className={`${classOption}`}
                onClick={toggleQR}
            >
                <i className="icofont-wechat"></i>
            </button>
            {showWeChatQR && (
                <div>
                    <img
                        src={`${process.env.PUBLIC_URL}/images/footer/3.png`}
                        alt="WeChat QR Code"
                    />
                </div>
            )}
        </div>
    );
};

SocialIconWechat.propTypes = {
    classOption: PropTypes.string,
    showWeChatQR: PropTypes.bool.isRequired,
    toggleQR: PropTypes.func.isRequired,
};

SocialIconWechat.defaultProps = {
    classOption: "",
};

export default SocialIconWechat;
