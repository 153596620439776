import React from "react";
import PropTypes from "prop-types";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import BlogDetailsContainer from "../containers/blog/blog-details";
import BlogData from "../data/blog.json";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import { useTranslation } from "react-i18next";

const BlogDetailsPage = ({
    match: {
        params: { id },
    },
}) => {
    const { t } = useTranslation();
    const blogId = parseInt(id, 10);
    const data = BlogData.filter((blogItem) => blogItem.id === blogId);
    return (
        <React.Fragment>
            <Layout>
                <SEO title="JR International – Blog Details" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={t("insights-details")}
                        excerpt={t("Insights-excerpt")}
                        image="/images/blog/eu.jpg"
                    />
                    <BlogDetailsContainer data={data[0]} />
                    {/* <NewsletterArea /> */}
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

BlogDetailsPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    }),
};

export default BlogDetailsPage;
