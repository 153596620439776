const sendEmail = async (recipientEmail, subject, emailContent) => {
    const baseURL = "https://jrbtms.fly.dev";
    try {
        const response = await fetch(
            `${baseURL}/email-actions/send-notification`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    to: recipientEmail,
                    subject: subject,
                    html: emailContent,
                }),
            }
        );

        if (response.ok) {
            console.log("Email sent successfully");
        } else {
            console.error("Failed to send email");
        }
    } catch (error) {
        console.error("Error sending email:", error);
    }
};

export default sendEmail;
