import React, { useCallback, useContext, useState } from "react";
import { auth } from "../firebaseConfig";
import {
    createUserWithEmailAndPassword,
    sendEmailVerification,
} from "@firebase/auth";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { BTMSContext } from "../BTMSContext";
import { IoIosArrowBack } from "react-icons/io";
import "../assets/scss/_btms.scss";

const Signup = () => {
    const baseURL = "https://jrbtms.fly.dev";
    const [userData, setUserData] = useState({
        email: "",
        password: "",
    });
    const [errMsg, setErrMsg] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [showLoginModel, setShowLoginModel] = useState(false);
    const history = useHistory();

    const validatePassword = (password) => {
        if (password.length < 8)
            return "Password should be at least 8 characters long.";
        if (!/[a-z]/.test(password))
            return "Password should contain at least one lowercase letter.";
        if (!/[A-Z]/.test(password))
            return "Password should contain at least one uppercase letter.";
        if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password))
            return "Password should contain at least one special character: ! @ # $ % ^ & * ( ) _ + - = [ ] { } ; : ' \" \\ | , . < > / ?";

        return "";
    };

    const [step, setStep] = useState(1);

    const resetStep = useCallback(() => {
        setStep(prevStep => {
            console.log("Setting step from", prevStep, "to 1");
            return 1;
        });
    }, []);
    // const { validatePassword, resetStep } = useContext(BTMSContext);

    const handleShowModal = () => {
        const passwordValidationMsg = validatePassword(userData.password);
        if (passwordValidationMsg || userData.password !== confirmPassword) {
            setPasswordError(
                passwordValidationMsg || "Passwords do not match."
            );
            return;
        }

        setShowModal(true);
    };

    const handleAgree = async () => {
        setShowModal(false);
        await handleSignup();
    };

    const handleRedirect = async() =>{
        setShowLoginModel(false);
        history.push("/btms/login",{ email: userData.email });
    }

    const handleSignup = async () => {
        const passwordValidationMsg = validatePassword(userData.password);
        if (passwordValidationMsg) {
            setPasswordError(passwordValidationMsg);
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                userData.email,
                userData.password
            );
            const firebaseUserId = userCredential.user.uid;
            const userEmail = userCredential.user.email;
            const firebaseUser = userCredential.user;

            await sendEmailVerification(firebaseUser, {
                url: "https://www.jrlinkintl.com/btms/login", 
            });

            const response = await fetch(`${baseURL}/auth/signup`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    firebaseUserId: firebaseUserId,
                    email: userEmail,
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to create user in MongoDB");
            }
            const data = await response.json();
            sessionStorage.setItem("userRole", "user");
            sessionStorage.setItem("mongoUserId", data.id);
            resetStep();
            history.push("/btms/verify-email", { userId: data.id });
        } catch (error) {
            console.error("Error during signup: ", error); // Debug log
            if (error.code === 'auth/email-already-in-use') {
                setErrMsg("The email is already registered.");
                setShowLoginModel(true);
            } else {
                setErrMsg("Failed to sign up. Please try again later.");
            }
        }
    };

    const goBack = useCallback(() => {
        sessionStorage.removeItem("applicationId");
        history.goBack();
    }, [history]);

    return (
        <div style={{paddingTop:"7rem"}} className="signup-form">
            <input
                type="text"
                placeholder="Email"
                value={userData.email}
                onChange={(e) =>
                    setUserData((prev) => ({ ...prev, email: e.target.value }))
                }
            />
            <input
                type="password"
                placeholder="Password"
                value={userData.password}
                onChange={(e) =>
                    setUserData((prev) => ({
                        ...prev,
                        password: e.target.value,
                    }))
                }
            />
            <input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
            />

            <p className="password-hint">
                Password requirements:
                <ul>
                    <li className={userData.password.length >= 8 ? "met" : ""}>
                        At least 8 characters long
                    </li>
                    <li
                        className={/[a-z]/.test(userData.password) ? "met" : ""}
                    >
                        At least 1 lowercase letter
                    </li>
                    <li
                        className={/[A-Z]/.test(userData.password) ? "met" : ""}
                    >
                        At least 1 uppercase letter
                    </li>
                    <li
                        className={
                            /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
                                userData.password
                            )
                                ? "met"
                                : ""
                        }
                    >
                        At least 1 special character (e.g. !@#$%^&*)
                    </li>
                </ul>
            </p>
            {passwordError && <p className="error">{passwordError}</p>}

            {errMsg && <p>{errMsg}</p>}

            <Button onClick={handleShowModal}>Signup</Button>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Privacy Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your data is stored in the EU. We respect your privacy and
                    do not share your data with third parties without your
                    consent.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowModal(false)}
                    >
                        Disagree
                    </Button>
                    <Button variant="primary" onClick={handleAgree}>
                        Agree
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showLoginModel} onHide={() => setShowLoginModel(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>The email is already registered.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Do you want to login by {userData.email}?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowLoginModel(false)}
                    >
                        No
                    </Button>
                    <Button variant="primary" onClick={handleRedirect}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <button style={{bottom:"7rem"}} className="back-icon" onClick={goBack}>
                <IoIosArrowBack size={24} />
            </button>
        </div>
    );
};

export default Signup;
