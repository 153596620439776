import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Reply = () => {
    const baseURL = "https://jrbtms.fly.dev";
    const history = useHistory();
    const userId = sessionStorage.getItem("mongoUserId");
    const [applications, setApplications] = useState([]);
    const [sortOrder, setSortOrder] = useState("none");
    const [searchQuery, setSearchQuery] = useState('');

    const sortApplications = () => {
        let sortedApplications;
        if (sortOrder === "status") {
            sortedApplications = [...applications].sort(
                (a, b) => new Date(a.createTime) - new Date(b.createTime)
            );
            setSortOrder("date");
        } else {
            sortedApplications = [...applications].sort((a, b) =>
                a.status.localeCompare(b.status)
            );
            setSortOrder("status");
        }
        setApplications(sortedApplications);
    };

    const filterApplications = (applications, query) => {
        if (!query) return applications;
    
        return applications.filter((app) => {
            const tripName = app.name.toLowerCase();
            const companyName = app.userId?.companyId?.name?.toLowerCase()|| '';
            const appNumber = app._id.toLowerCase();
            const services = getSelectedServices(app).toLowerCase();
            const applyDate = formatDate(app.createTime).toLowerCase();
            const status = app.status.toLowerCase();
    
            return (
                tripName.includes(query.toLowerCase()) ||
                companyName.includes(query.toLowerCase()) ||
                appNumber.includes(query.toLowerCase()) ||
                services.includes(query.toLowerCase()) ||
                applyDate.includes(query.toLowerCase())||
                status.includes(query.toLowerCase())
            );
        });
    };


    

    const handleApplicationClick = async (applicationId) => {
        const selectedApplication = applications.find(
            (app) => app._id === applicationId
        );

        if (selectedApplication && selectedApplication.status === "Approved") {
            try {
                const response = await fetch(
                    `${baseURL}/application/${applicationId}`,
                    {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ status: "Processing" }),
                    }
                );

                if (response.ok) {
                    // Refresh the applications list
                    fetchApplications();
                    history.push(`/btms/reply/${applicationId}`);
                } else {
                    throw new Error("Failed to update application status.");
                }
            } catch (error) {
                console.error("Error updating application status:", error);
            }
        } else {
            history.push(`/btms/reply/${applicationId}`);
        }
    };

    const fetchApplications = () => {
        fetch(`${baseURL}/application`)
            .then((response) => response.json())
            .then((data) => {
                const approvedApplications = data.filter(
                    (app) =>
                        app.status === "Approved" ||
                        app.status === "Processing" ||
                        app.status === "Suggestion" ||
                        app.status === "Confirmed"
                );
                setApplications(approvedApplications);
            })
            .catch((error) =>
                console.error("Error fetching applications:", error)
            );
    };

    useEffect(() => {
        fetchApplications();
    }, [userId]);

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const getSelectedServices = (app) => {
        const services = [];
        if (app.flight) services.push("Flight");
        if (app.hotel) services.push("Hotel");
        if (app.visa) services.push("Visa");
        if (app.ticket) services.push("Ticket");
        return services.join(", ");
    };

    const filteredApplications = filterApplications(applications, searchQuery);

    return (
        <div className="status-page">
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "2rem",
                }}
            >
                <h2>Pending Application</h2>
                <input
                style={{ marginLeft: "auto" }}
                className="search-bar"
                type="text"
                placeholder="Search applications"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
                <button
                className="sort-button-status"
                    onClick={sortApplications}
                    style={{ marginLeft: "auto" }}
                >
                    Sort by {sortOrder === "status" ? "Date" : "Status"}
                </button>
            </div>
            <div className="application-list">
                {applications.length > 0 ? (
                    filteredApplications.map((app) => (
                        <div
                            key={app._id}
                            className="reply-box"
                            onClick={() => handleApplicationClick(app._id)}
                            onKeyDown={(event) => handleKeyDown(event, app._id)}
                            role="button"
                            tabIndex={0}
                        >
                            <div className="info-container"></div>
                            <h3 className="application-title">{app?.userId.companyId?.name} <br /><br /> {app.name}</h3>
                            <div className="container">
                                <div className="left-container">
                                    <p>
                                        Apply Date: {formatDate(app.createTime)}
                                    </p>
                                    <p>Services: {getSelectedServices(app)}</p>
                                    <p style={{textAlign:"center"}}>Application number: {app._id}</p>
                                </div>
                                <div
                                    className={`status-button status-${app.status.toLowerCase()}`}
                                >
                                    {app.status}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div>No completed applications found.</div>
                )}
            </div>
        </div>
    );
};

export default Reply;
