import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { BTMSContext } from "../BTMSContext";
import CustomModal from "./CustomModal";

const ApplicantModal = ({
    showModal,
    setShowModal,
    onApplicantSubmit,
    currentApplicant,
    applicantProfileData,
    setApplicantProfileData,
    segments
}) => {
    const [validationErrors, setValidationErrors] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [showCustomModal, setShowCustomModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const { initialApplicantState,convertDateToInputFormat } = useContext(BTMSContext);
    const symbol = (
        <svg
            className="input-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path d="M12 15.75A1.125 1.125 0 1 0 12 18a1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zm.75-2.25V5.25a.75.75 0 0 0-1.5 0v8.25a.75.75 0 0 0 1.5 0zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z"></path>
        </svg>
    );

    useEffect(() => {
        validateProfileData();
    }, [applicantProfileData]); // Add other dependencies if needed

    const handleBirthDateBlur = (e) => {
        const today = new Date();
        const birthDate = new Date(applicantProfileData.birth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthDate.getDate())
        ) {
            age--;
        }

        if (age < 18) {
            setModalMessage(
                "The applicant seems too young! Are you sure about the birthdate?"
            );
            setShowCustomModal(true);
            
            return;
        }
    }

    const handleDepartureDateBlur = (e) => {
        const expiryDate = new Date(applicantProfileData.passportInfo.expiryDate).getTime();
        const departureDate = new Date(segments[0].departureDate).getTime();
        console.log(expiryDate, departureDate);
        if (expiryDate < departureDate) {
            setModalMessage(
                "The document's expiry date is before the departure date. Please renew your document."
            );
            setShowCustomModal(true);
        }
    };

    const validateProfileData = () => {
        let errors = [];

        const validateEntries = (data, prefix = "") => {
            return Object.entries(data).every(([key, value]) => {
                if (key === "idInfo" || key === "idCopy" || key === "userId" || key === "__v") {
                    return true;
                }

                if (typeof value === "object" && value !== null) {
                    return validateEntries(value, `${prefix}${key}.`);
                }

                

                if (key === "__v" || key === "userId" ) {
                    return true;
                }

                const isValid =
                    typeof value === "string" && value.trim() !== "";

                if (!isValid) {
                    errors.push(`${prefix}${key}`);
                }

                return isValid;
            });
        };

        const isValid = validateEntries(applicantProfileData);

        setValidationErrors(errors);
        return isValid;
    };

    const resetProfileData = () => {
        setApplicantProfileData(initialApplicantState);
    };

    const modifiedHandleSubmit = (e) => {
        e.preventDefault();
        console.log("clicked");
        console.log(validationErrors);
        console.log(validateProfileData());
        setSubmitted(true);
        if (validateProfileData()) {
            onApplicantSubmit(e);
            setSubmitted(false);
            resetProfileData();
        }
    };

    return (
        <Modal
            className="modal"
            show={showModal}
            onHide={() => setShowModal(false)}
        >
            <Modal.Header className="modal-header" closeButton>
                <Modal.Title className="modal-title">
                    Confirm Applicant Data
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                {currentApplicant && currentApplicant.length > 0 && (
                    <label>
                        Select Applicant<span className="required">*</span>
                        <select
                            onChange={(e) => {
                                const selectedApplicant = currentApplicant.find(
                                    (app) => app._id === e.target.value
                                );
                                setApplicantProfileData(
                                    selectedApplicant || initialApplicantState
                                );
                            }}
                        >
                            <option value="">--Select an Applicant or create a new Applicant--</option>
                            {currentApplicant.map((applicant) => (
                                <option
                                    key={applicant._id}
                                    value={applicant._id}
                                >
                                    {applicant.name}
                                </option>
                            ))}
                        </select>
                    </label>
                )}
                <label>
                    Name on Passport<span className="required">*</span>
                    <div className="input-wrapper">
                        <input
                            type="text"
                            value={applicantProfileData.name}
                            onChange={(e) =>
                                setApplicantProfileData({
                                    ...applicantProfileData,
                                    name: e.target.value,
                                })
                            }
                            placeholder={
                                applicantProfileData.name ||
                                "Please enter the name in passport"
                            }
                            className={
                                submitted && !applicantProfileData.name
                                    ? "error"
                                    : ""
                            }
                        />
                        {!applicantProfileData.name && submitted && symbol}
                    </div>
                    {!applicantProfileData.name && submitted && (
                        <p className="error-message">Name is required.</p>
                    )}
                </label>

                <label>
                    Birth<span className="required">*</span>
                    <div className="input-wrapper">
                        <input
                            type="date"
                            value={convertDateToInputFormat(applicantProfileData?.birth)}
                            onChange={(e) =>
                                setApplicantProfileData({
                                    ...applicantProfileData,
                                    birth: e.target.value,
                                })
                            }
                            placeholder={convertDateToInputFormat(applicantProfileData.birth) || ""}
                            className={
                                submitted && !applicantProfileData.birth
                                    ? "error"
                                    : ""
                            }
                            onBlur={handleBirthDateBlur}
                        />
                    </div>
                    {!applicantProfileData.birth && submitted && (
                        <p className="error-message">Birth is required.</p>
                    )}
                </label>
                {/* Sex */}
                <label>
                    Sex<span className="required">*</span>
                    <select
                        type="text"
                        value={applicantProfileData?.sex || "M"}
                        onChange={(e) =>
                            setApplicantProfileData({
                                ...applicantProfileData,
                                sex: e.target.value,
                            })
                        }
                        placeholder={applicantProfileData.sex || ""}
                    >
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                    </select>
                </label>

                {/* Nationality */}
                <label>
                    Nationality<span className="required">*</span>
                    <div className="input-wrapper">
                        <input
                            type="text"
                            value={applicantProfileData.nationality}
                            onChange={(e) =>
                                setApplicantProfileData({
                                    ...applicantProfileData,
                                    nationality: e.target.value,
                                })
                            }
                            placeholder={
                                applicantProfileData.nationality ||
                                "Enter your nationality"
                            }
                            className={
                                submitted && !applicantProfileData.nationality
                                    ? "error"
                                    : ""
                            }
                        />
                        {!applicantProfileData.nationality &&
                            submitted &&
                            symbol}
                    </div>
                    {!applicantProfileData.nationality && submitted && (
                        <p className="error-message">
                            Nationality is required.
                        </p>
                    )}
                </label>

                <label>
                    Email<span className="required">*</span>
                    <div className="input-wrapper">
                        <input
                            type="text"
                            value={applicantProfileData.email}
                            onChange={(e) =>
                                setApplicantProfileData({
                                    ...applicantProfileData,
                                    email: e.target.value,
                                })
                            }
                            placeholder={
                                applicantProfileData.email || "Enter the email"
                            }
                            className={
                                submitted && !applicantProfileData.email
                                    ? "error"
                                    : ""
                            }
                        />
                        {!applicantProfileData.email && submitted && symbol}
                    </div>
                    {!applicantProfileData.email && submitted && (
                        <p className="error-message">Email is required.</p>
                    )}
                </label>

                <label>
                    Passport Number<span className="required">*</span>
                    <div className="input-wrapper">
                        <input
                            type="text"
                            value={applicantProfileData.passportInfo.number}
                            onChange={(e) =>
                                setApplicantProfileData({
                                    ...applicantProfileData,
                                    passportInfo: {
                                        ...applicantProfileData.passportInfo,
                                        number: e.target.value,
                                    },
                                })
                            }
                            placeholder={
                                applicantProfileData.passportInfo.number ||
                                "Enter your passport number"
                            }
                            className={
                                submitted &&
                                !applicantProfileData.passportInfo.number
                                    ? "error"
                                    : ""
                            }
                        />
                        {!applicantProfileData.passportInfo.number &&
                            submitted &&
                            symbol}
                    </div>
                    {!applicantProfileData.passportInfo.number && submitted && (
                        <p className="error-message">
                            Passport number is required.
                        </p>
                    )}
                </label>
                <label>
                    Expiry Date<span className="required">*</span>
                    <div className="input-wrapper">
                        <input
                            name="expiryDate"
                            type="date"
                            value={convertDateToInputFormat(applicantProfileData.passportInfo.expiryDate)}
                            onChange={(e) =>
                                setApplicantProfileData({
                                    ...applicantProfileData,
                                    passportInfo: {
                                        ...applicantProfileData.passportInfo,
                                        expiryDate: e.target.value,
                                    },
                                })
                            }
                            placeholder={
                                convertDateToInputFormat(applicantProfileData.passportInfo.expiryDate) ||
                                ""
                            }
                            className={
                                submitted &&
                                !applicantProfileData.passportInfo.expiryDate
                                    ? "error"
                                    : ""
                            }
                            onBlur={handleDepartureDateBlur}
                        />
                    </div>
                    {!applicantProfileData.passportInfo.expiryDate &&
                        submitted && (
                            <p className="error-message">
                                Expiry date is required.
                            </p>
                        )}
                </label>

                <label>
                    ID Number:
                    <div className="input-wrapper">
                        <input
                            type="text"
                            value={applicantProfileData.idInfo.number}
                            onChange={(e) =>
                                setApplicantProfileData({
                                    ...applicantProfileData,
                                    idInfo: {
                                        ...applicantProfileData.idInfo,
                                        number: e.target.value,
                                    },
                                })
                            }
                            placeholder={
                                applicantProfileData.idInfo.number ||
                                "Enter your ID number"
                            }
                            
                        />
                    </div>
                   
                </label>

                <label>
                    Expiry Date:
                    <div className="input-wrapper">
                        <input
                            name="expiryDate"
                            type="date"
                            value={convertDateToInputFormat(applicantProfileData.idInfo.expiryDate)}
                            onChange={(e) =>
                                setApplicantProfileData({
                                    ...applicantProfileData,
                                    idInfo: {
                                        ...applicantProfileData.idInfo,
                                        expiryDate: e.target.value,
                                    },
                                })
                            }
                            placeholder={
                                convertDateToInputFormat(applicantProfileData.idInfo.expiryDate) || ""
                            }
                        />
                    </div>
                 
                </label>
                <CustomModal
                    show={showCustomModal}
                    message={modalMessage}
                    onClose={() => setShowCustomModal(false)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setShowModal(false);
                        setSubmitted(false);
                        resetProfileData();
                    }}
                >
                    Close
                </Button>
                <Button variant="primary" onClick={modifiedHandleSubmit}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ApplicantModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    onApplicantSubmit: PropTypes.func.isRequired,
    currentApplicant: PropTypes.array.isRequired,
    applicantProfileData: PropTypes.object.isRequired,
    setApplicantProfileData: PropTypes.func.isRequired,
    segments: PropTypes.array.isRequired,
};

export default ApplicantModal;
