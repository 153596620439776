import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { auth } from "./BTMS/firebaseConfig";
import PropTypes from "prop-types";
import NavScrollTop from "./components/nav-scroll-top";
import HomePage from "./pages/index";
import AboutPage from "./pages/about";
import ServicePage from "./pages/service";
import ServiceDetails from "./templates/service-details";
import TeamPage from "./pages/team";
import FaqPage from "./pages/faq";
import BlogPage from "./pages/blog";
import BlogLeftSidebarPage from "./pages/blog-left-sidebar";
import BlogRightSidebarPage from "./pages/blog-right-sidebar";
import BlogDetailsPage from "./templates/blog-details";
import BlogAuthor from "./templates/blog-author";
import BlogDate from "./templates/blog-date";
import BlogTag from "./templates/blog-tag";
import BlogCategory from "./templates/blog-category";
import ContactPage from "./pages/contact";
import PrivacyPage from "./pages/privacy";

// The packages below are about multi-language support
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import "swiper/components/navigation/navigation.scss";
import "swiper/swiper.scss";
import "./assets/css/vendor/metropolis.css";
import "./assets/css/vendor/icofont.min.css";
import "./assets/css/vendor/font-awesome.css";
import "./assets/css/vendor/material-design-iconic.min.css";
import "./assets/css/plugins/animate.min.css";
import "./assets/scss/style.scss";

import { BTMSProvider, checkUserRole } from "./BTMS/BTMSContext";
import FrontPage from "./BTMS/pages/FrontPage";
import Application from "./BTMS/pages/Application";
import Signup from "./BTMS/components/Signup";
import Login from "./BTMS/components/Login";
import BTMSLanding from "./BTMS/pages/BTMSLanding";
import Status from "./BTMS/pages/Status";
import History from "./BTMS/pages/History";
import MainLayout from "./BTMS/layouts/MainLayout";
import RegisterInformation from "./BTMS/pages/RegisterInformation";
import PersonalInformation from "./BTMS/pages/PersonalInformation";
import CompanyInformation from "./BTMS/pages/CompanyInformation";
import ApplicationConfirmation from "./BTMS/pages/ApplicationConfirmation";
import VerifyEmail from "./BTMS/pages/VerifyEmail";
import Reply from "./BTMS/pages/Reply";
import ReplyRequest from "./BTMS/pages/ReplyRequest";

import Suggestion from "./BTMS/pages/Suggestion";
import ApplicationContent from "./BTMS/pages/ApplicationContent";
import { Redirect } from "react-router-dom";

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ["en", "nl", "zh"],
        fallbackLng: "en",
        detection: {
            order: ["path", "cookie", "htmlTag", "sessionStorage", "subdomain"],
            caches: ["cookie"],
        },
        backend: {
            loadPath: "/assets/locales/{{lng}}/translation.json",
        },
        interpolation: {
            escapeValue: false,
        },
    });
const PrivateRoute = ({
    component: Component,
    isAuthenticated,
    isAdmin,
    adminOnly = false,
    ...rest
}) => {
    if (typeof isAdmin === "undefined") {
        console.warn("isAdmin prop is undefined");
    }
    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isAuthenticated) return <Redirect to="/btms" />;
                if (adminOnly && !isAdmin)
                    return <Redirect to="/btms/frontpage" />;
                return <Component {...props} />;
            }}
        />
    );
};
const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);
    // const userId = sessionStorage.getItem("mongoUserId");

    // useEffect(() => {
    //     const baseURL = "https://jrbtms.fly.dev";
    //     const checkUserRole = async () => {
    //         try {
    //             const response = await fetch(`${baseURL}/profile/${userId}`, {
    //                 method: "GET",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //             });

    //             if (!response.ok) {
    //                 throw new Error("Failed to fetch user profile.");
    //             }

    //             const data = await response.json();
    //             console.log("data.role", data.role);
    //             if (data.role === "Admin") {
    //                 sessionStorage.setItem("isAdmin", true);
    //                 setIsAdmin(true);
    //                 console.log("the user is admin");
    //             } else {
    //                 sessionStorage.setItem("isAdmin", false);
    //                 setIsAdmin(false);
    //                 console.log("the user is not admin");
    //             }
    //         } catch (error) {
    //             console.error("Error fetching user profile: ", error);
    //         }
    //         setLoading(false);
    //     };

    //     setLoading(true); // Set loading to true when the component mounts
    //     checkUserRole();

    //     auth.onAuthStateChanged((user) => {
    //         if (user) {
    //             setIsAuthenticated(true);
    //         } else {
    //             setIsAuthenticated(false);
    //         }
    //     });
    // }, [userId]);

    useEffect(() => {
        setLoading(true)
       const isAdmin = sessionStorage.getItem("isAdmin")
        setIsAdmin(isAdmin);
        setLoading(false);
    }, [isAdmin]);

    if (loading) {
        return <div>Loading...</div>; // Show a loading indicator while checking the user role
    }

    // Debugging logs
    console.log("isAdmin:", isAdmin);
    console.log("isAuthenticated:", isAuthenticated);

    return (
        <Router>
            <NavScrollTop>
                <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route path="/about" component={AboutPage} />
                    <Route path="/service" component={ServicePage} />
                    <Route
                        path="/service-details/:id"
                        component={ServiceDetails}
                    />
                    <Route path="/team" component={TeamPage} />
                    <Route path="/faq" component={FaqPage} />
                    <Route path="/contact" component={ContactPage} />
                    <Route path="/blog" component={BlogPage} />
                    <Route
                        path="/blog-left-sidebar"
                        component={BlogLeftSidebarPage}
                    />
                    <Route
                        path="/blog-right-sidebar"
                        component={BlogRightSidebarPage}
                    />
                    <Route
                        path="/blog-details/:id"
                        component={BlogDetailsPage}
                    />
                    <Route path="/author/:author" component={BlogAuthor} />
                    <Route path="/date/:date" component={BlogDate} />
                    <Route path="/tag/:slug" component={BlogTag} />
                    <Route path="/category/:slug" component={BlogCategory} />
                    <Route path="/privacy" component={PrivacyPage} />

                    <Route
                        exact
                        path="/btms/:applicationId/application-confirmation"
                        component={ApplicationConfirmation}
                    />
                    <Route
                        exact
                        path="/btms/verify-email"
                        component={VerifyEmail}
                    />

                    <Route exact path="/btms" component={BTMSLanding} />

                    <Route path="/btms/signup" component={Signup} />
                    <Route path="/btms/login" component={Login} />
                    <BTMSProvider>
                        <MainLayout>
                            <PrivateRoute
                                path="/btms/frontpage"
                                component={FrontPage}
                                isAuthenticated={isAuthenticated}
                            />

                            <PrivateRoute
                                path="/btms/application/:applicationId/edit"
                                component={Application}
                                isAuthenticated={isAuthenticated}
                            />
                            <PrivateRoute
                                exact
                                path="/btms/application"
                                component={Application}
                                isAuthenticated={isAuthenticated}
                            />
                            <PrivateRoute
                                exact
                                path="/btms/status"
                                component={Status}
                                isAuthenticated={isAuthenticated}
                            />
                            <PrivateRoute
                                path="/btms/status/:applicationId"
                                component={ApplicationContent}
                                isAuthenticated={isAuthenticated}
                            />
                            <PrivateRoute
                                path="/btms/history"
                                component={History}
                                isAuthenticated={isAuthenticated}
                            />
                            <PrivateRoute
                                path="/btms/registerinfo"
                                component={RegisterInformation}
                                isAuthenticated={isAuthenticated}
                            />
                            <PrivateRoute
                                path="/btms/personalinfo"
                                component={PersonalInformation}
                                isAuthenticated={isAuthenticated}
                            />

                            <PrivateRoute
                                path="/btms/company"
                                component={CompanyInformation}
                                isAuthenticated={isAuthenticated}
                                // isAdmin={isAdmin}
                                // adminOnly={true}
                            />

                            <PrivateRoute
                                path="/btms/reply/:applicationId"
                                component={ReplyRequest}
                                isAuthenticated={isAuthenticated}
                            />
                            <PrivateRoute
                                path="/btms/suggestion/:applicationId"
                                component={Suggestion}
                                isAuthenticated={isAuthenticated}
                            />
                            <PrivateRoute
                                exact
                                path="/btms/reply"
                                component={Reply}
                                isAuthenticated={isAuthenticated}
                                // isAdmin={isAdmin}
                                // adminOnly={true}
                            />
                        </MainLayout>
                    </BTMSProvider>
                </Switch>
            </NavScrollTop>
        </Router>
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.elementType.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool,
    adminOnly: PropTypes.bool,
};
export default App;
