import React from "react";
import PropTypes from 'prop-types'
import '../assets/scss/_btms.scss'
import {BiSolidRightArrowAlt} from 'react-icons/bi'

const ProgressBar = ({ step }) => {
    return (
        <div className="progress-bar">
            <div className={`step ${step === 1 ? "active" : ""}`}>Fill in business trip information</div>
            <BiSolidRightArrowAlt size={32} style={{color:'black'}}/>
            <div className={`step ${step === 2 ? "active" : ""}`}>Budget and Approval</div>
            <BiSolidRightArrowAlt size={32} style={{color:'black'}}/>
            <div className={`step ${step === 3 ? "active" : ""}`}>Finish application</div>
        </div>
    );
};

ProgressBar.propTypes = {
    step: PropTypes.number,
};

export default ProgressBar;
