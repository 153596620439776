import React, { useContext, useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import "../assets/scss/_btms.scss";
import { BTMSContext } from "../BTMSContext";

const PersonalInformation = () => {
    const baseURL = "https://jrbtms.fly.dev";
    const userId = sessionStorage.getItem("mongoUserId");
    const { convertDateToInputFormat } = useContext(BTMSContext);

    // Profile update
    const initialState = {
        birth: null,
        sex: "",
        nationality: "",
        maritalStatus: "",
        contact: {
            tel: "",
            address: "",
        },
        passportInfo: {
            number: "",
            expiryDate: null,
            issueDate: null,
            issuingCountry: "",
        },
        idInfo: {
            number: "",
            expiryDate: null,
            issueDate: null,
            issuingPlace: "",
        },
    };
    const [profileData, setProfileData] = useState(initialState);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!userId) {
            console.log("User ID not available yet");
            return; // Exit the useEffect if userId is not available
        }

        const fetchProfileData = async () => {
            try {
                console.log("start fetching profile data");
                const response = await fetch(`${baseURL}/profile/${userId}`);
                if (response.status === 404) {
                    console.log(
                        "It's a new user. Profile data not found in MongoDB."
                    );
                    return;
                } else if (!response.ok) {
                    throw new Error("Failed to fetch profile data");
                }
                const data = await response.json();

                setProfileData((prevState) => ({
                    ...prevState,
                    ...data,
                    birth: data?.birth
                        ? convertDateToInputFormat(data?.birth)
                        : null,
                    sex: data?.sex || "M",
                    nationality: data?.nationality || "",
                    maritalStatus: data?.maritalStatus || "",
                    contact: {
                        tel: (data.contact && data.contact?.tel) || "",
                        address: (data.contact && data.contact?.address) || "",
                    },
                    passportInfo: {
                        number:
                            (data.passportInfo && data.passportInfo?.number) ||
                            "",
                        expiryDate: data.passportInfo?.expiryDate
                            ? convertDateToInputFormat(
                                  data.passportInfo.expiryDate
                              )
                            : null,
                        issueDate: data.passportInfo?.issueDate
                            ? convertDateToInputFormat(
                                  data.passportInfo.issueDate
                              )
                            : null,
                        issuingCountry:
                            (data.passportInfo &&
                                data.passportInfo?.issuingCountry) ||
                            "",
                    },
                    idInfo: {
                        number: (data.idInfo && data.idInfo?.number) || "",
                        expiryDate:
                            data.idInfo && data.idInfo?.expiryDate
                                ? convertDateToInputFormat(
                                      data.idInfo.expiryDate
                                  )
                                : null,
                        issueDate:
                            data.idInfo && data.idInfo?.issueDate
                                ? convertDateToInputFormat(
                                      data.idInfo.issueDate
                                  )
                                : null,
                        issuingPlace:
                            (data.idInfo && data.idInfo?.issuingPlace) || "",
                    },
                    idCopy: data?.idCopy || "",
                    passportCopy: data?.passportCopy || "",
                }));
                console.log(data);
            } catch (error) {
                console.error("Error fetching profile data: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProfileData();
    }, [userId]);

    useEffect(() => {
        // Reset the state when the component is unmounted
        return () => setProfileData(initialState);
    }, []);

    const [showProfileUpdateToast, setShowProfileUpdateToast] = useState(false);

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        console.log(`${baseURL}/profile/${userId}`);
        try {
            const today = new Date();
            const birthDate = new Date(profileData.birth);
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();

            if (
                monthDifference < 0 ||
                (monthDifference === 0 && today.getDate() < birthDate.getDate())
            ) {
                age--;
            }

            if (age < 18) {
                alert("You seem too young! Are you sure about your birthdate?");
                return;
            }

            const passportIssueDate = new Date(
                profileData.passportInfo.issueDate
            );
            const passportExpiryDate = new Date(
                profileData.passportInfo.expiryDate
            );

            const idIssueDate = new Date(profileData.idInfo.issueDate);
            const idExpiryDate = new Date(profileData.idInfo.expiryDate);

            if (passportIssueDate > passportExpiryDate) {
                alert("Passport issue date cannot be after the expiry date!");
                return;
            }

            if (idIssueDate > idExpiryDate) {
                alert("ID issue date cannot be after the expiry date!");
                return;
            }

            const response = await fetch(`${baseURL}/profile/${userId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    profileData,
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to update profile");
            }

            console.log("Profile updated successfully!");
            setShowProfileUpdateToast(true);
        } catch (error) {
            console.error("Error updating profile: ", error);
        }
    };

    // Cloudinary upload
    const [uploadingId, setUploadingId] = useState(false);
    const [uploadingPassport, setUploadingPassport] = useState(false);
    const [idFileName, setIdFileName] = useState("");
    const [passportFileName, setPassportFileName] = useState("");

    const handleCloudinaryUpload = async (type) => {
        try {
            const uploadUrl = `${baseURL}/cloudinary/upload`;
            const fileInput = document.createElement("input");
            fileInput.type = "file";
            fileInput.onchange = async (e) => {
                const file = e.target.files[0];
                const originalFileName = file.name;
                if (type === "id") {
                    setUploadingId(true);
                } else {
                    setUploadingPassport(true);
                }

                const formData = new FormData();
                formData.append("file", file);

                const response = await fetch(uploadUrl, {
                    method: "POST",
                    body: formData,
                });

                const data = await response.json();

                if (type === "id") {
                    setProfileData({ ...profileData, idCopy: data.filePath });
                    setUploadingId(false);
                    setIdFileName(originalFileName);
                } else {
                    setProfileData({
                        ...profileData,
                        passportCopy: data.filePath,
                    });
                    setUploadingPassport(false);
                    setPassportFileName(originalFileName);
                }
            };
            fileInput.click();
        } catch (error) {
            console.error("Error uploading to Cloudinary: ", error);
        }
    };

    return (
        <div className="profile-container">
            {loading ? (
                <p>Loading profile...</p>
            ) : (
                <>
                    <h1 className="pt-10">Personal Information</h1>

                    <form onSubmit={handleSubmit} className="profile-form">
                        <div className="info-container">
                        {/* Birth */}
                        <label>
                            Birth:
                            <br/>
                            <input
                                type="date"
                                value={profileData.birth}
                                onChange={(e) =>
                                    setProfileData({
                                        ...profileData,
                                        birth: e.target.value,
                                    })
                                }
                                placeholder={profileData.birth || ""}
                            />
                        </label>
                        {/* Sex */}
                        <label>
                            Sex:
                            <br/>
                            <select
                                value={profileData.sex}
                                onChange={(e) =>
                                    setProfileData({
                                        ...profileData,
                                        sex: e.target.value,
                                    })
                                }
                                placeholder={profileData.sex || ""}
                            >
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                            </select>
                        </label>

                        {/* Nationality */}
                        <label>
                            Nationality:
                            <br/>
                            <input
                                type="text"
                                value={profileData.nationality}
                                onChange={(e) =>
                                    setProfileData({
                                        ...profileData,
                                        nationality: e.target.value,
                                    })
                                }
                                placeholder={
                                    profileData.nationality ||
                                    "Enter your nationality"
                                }
                            />
                        </label>
                        {/* Marital Status */}
                        <label>
                            Marital Status:
                            <br/>
                            <select
                                value={profileData.maritalStatus}
                                onChange={(e) =>
                                    setProfileData({
                                        ...profileData,
                                        maritalStatus: e.target.value,
                                    })
                                }
                                placeholder={profileData.maritalStatus || ""}
                            >
                                <option value="Single">Single</option>
                                <option value="Married">Married</option>
                                <option value="Divorced">Divorced</option>
                                <option value="Widowed">Widowed</option>
                            </select>
                        </label>
                        {/* Contact */}
                        </div>
                        <label>
                            Contact address:
                            <br/>
                            <input
                                type="text"
                                value={profileData.contact.address}
                                onChange={(e) =>
                                    setProfileData({
                                        ...profileData,
                                        contact: {
                                            ...profileData.contact,
                                            address: e.target.value,
                                        },
                                    })
                                }
                                placeholder={
                                    profileData.contact.address ||
                                    "Enter your address"
                                }
                            />
                        </label>

                        <div className="id-container">
                            {/* Passport Info */}
                            <div className="id-info">
                                <label>
                                    Passport Info:
                                    <br/>
                                    <input
                                        type="text"
                                        value={profileData.passportInfo.number}
                                        onChange={(e) =>
                                            setProfileData({
                                                ...profileData,
                                                passportInfo: {
                                                    ...profileData.passportInfo,
                                                    number: e.target.value,
                                                },
                                            })
                                        }
                                        placeholder={
                                            profileData.passportInfo.number ||
                                            "Enter your passport number"
                                        }
                                    />
                                    <label>
                                        Issue Date:
                                        <br/>
                                        <input
                                            type="date"
                                            value={
                                                profileData.passportInfo
                                                    .issueDate
                                            }
                                            onChange={(e) =>
                                                setProfileData({
                                                    ...profileData,
                                                    passportInfo: {
                                                        ...profileData.passportInfo,
                                                        issueDate:
                                                            e.target.value,
                                                    },
                                                })
                                            }
                                            placeholder={
                                                profileData.passportInfo
                                                    .issueDate || ""
                                            }
                                        />
                                    </label>
                                    <label>
                                        Expiry Date:
                                        <br/>
                                        <input
                                            type="date"
                                            value={
                                                profileData.passportInfo
                                                    .expiryDate
                                            }
                                            onChange={(e) =>
                                                setProfileData({
                                                    ...profileData,
                                                    passportInfo: {
                                                        ...profileData.passportInfo,
                                                        expiryDate:
                                                            e.target.value,
                                                    },
                                                })
                                            }
                                            placeholder={
                                                profileData.passportInfo
                                                    .expiryDate || ""
                                            }
                                        />
                                    </label>
                                    <label>
                                        Issuing Country:
                                        <br/>
                                        <input
                                            type="text"
                                            value={
                                                profileData.passportInfo
                                                    .issuingCountry
                                            }
                                            onChange={(e) =>
                                                setProfileData({
                                                    ...profileData,
                                                    passportInfo: {
                                                        ...profileData.passportInfo,
                                                        issuingCountry:
                                                            e.target.value,
                                                    },
                                                })
                                            }
                                            placeholder={
                                                profileData.passportInfo
                                                    .issuingCountry ||
                                                "Enter your issuing country"
                                            }
                                        />
                                    </label>
                                </label>
                                <button
                                    className="upload-btn"
                                    type="button"
                                    onClick={() =>
                                        handleCloudinaryUpload("passport")
                                    }
                                >
                                    Upload Passport
                                </button>

                                {uploadingPassport && (
                                    <p>Uploading Passport...</p>
                                )}
                                {passportFileName && (
                                    <p>{passportFileName} is uploaded.</p>
                                )}
                                {profileData.passportCopy && (
                                    <p>Please press Update Profile button.</p>
                                )}
                            </div>

                            <div className="id-info">
                                {/* ID Info */}
                                <label>
                                    ID Info:
                                    <br/>
                                    <input
                                        type="text"
                                        value={profileData.idInfo.number}
                                        onChange={(e) =>
                                            setProfileData({
                                                ...profileData,
                                                idInfo: {
                                                    ...profileData.idInfo,
                                                    number: e.target.value,
                                                },
                                            })
                                        }
                                        placeholder={
                                            profileData.idInfo.number ||
                                            "Enter your ID number"
                                        }
                                    />
                                    <label>
                                        Issue Date:
                                        <br/>
                                        <input
                                            type="date"
                                            value={profileData.idInfo.issueDate}
                                            onChange={(e) =>
                                                setProfileData({
                                                    ...profileData,
                                                    idInfo: {
                                                        ...profileData.idInfo,
                                                        issueDate:
                                                            e.target.value,
                                                    },
                                                })
                                            }
                                            placeholder={
                                                profileData.idInfo.issueDate ||
                                                ""
                                            }
                                        />
                                    </label>
                                    <label>
                                        Expiry Date:
                                        <br/>
                                        <input
                                            type="date"
                                            value={
                                                profileData.idInfo.expiryDate
                                            }
                                            onChange={(e) =>
                                                setProfileData({
                                                    ...profileData,
                                                    idInfo: {
                                                        ...profileData.idInfo,
                                                        expiryDate:
                                                            e.target.value,
                                                    },
                                                })
                                            }
                                            placeholder={
                                                profileData.idInfo.expiryDate ||
                                                ""
                                            }
                                        />
                                    </label>
                                    <label>
                                        Issuing Place:
                                        <br/>
                                        <input
                                            type="text"
                                            value={
                                                profileData.idInfo.issuingPlace
                                            }
                                            onChange={(e) =>
                                                setProfileData({
                                                    ...profileData,
                                                    idInfo: {
                                                        ...profileData.idInfo,
                                                        issuingPlace:
                                                            e.target.value,
                                                    },
                                                })
                                            }
                                            placeholder={
                                                profileData.idInfo
                                                    .issuingPlace ||
                                                "Enter your issuing place"
                                            }
                                        />
                                    </label>
                                </label>

                                {/* Cloudinary Upload Buttons */}
                                <button
                                    className="upload-btn"
                                    type="button"
                                    onClick={() => handleCloudinaryUpload("id")}
                                >
                                    Upload ID
                                </button>

                                {uploadingId && <p>Uploading ID...</p>}
                                {idFileName && <p>{idFileName} is uploaded.</p>}
                                {profileData.idCopy && (
                                    <p>Please press Update Profile button.</p>
                                )}
                            </div>
                        </div>
                        <button style={{marginTop:"2rem"}} type="submit">Update Profile</button>
                        <Toast
                            onClose={() => setShowProfileUpdateToast(false)}
                            show={showProfileUpdateToast}
                            delay={3000}
                            autohide
                        >
                            <Toast.Body>
                                Profile updated successfully!
                            </Toast.Body>
                        </Toast>
                    </form>
                </>
            )}
        </div>
    );
};

export default PersonalInformation;
