import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCiIttje51LxrLfXIeTU-JBe9MRTsfLSyw",
    authDomain: "jr-btms.firebaseapp.com",
    projectId: "jr-btms",
    storageBucket: "jr-btms.appspot.com",
    messagingSenderId: "1046169483464",
    appId: "1:1046169483464:web:357bd97be5870b63ef8dfd",
    measurementId: "G-TL57G92SM8",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export { auth };
