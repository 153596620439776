import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import cookie from "js-cookie";
import { useEffect } from "react";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import Logo from "../../logo";

const languages = [
    {
        code: "en"
    },
    {
        code: "nl"
    },
    {
        code: "zh"
    },
];

const MobileMenu = ({ show, onClose }) => {
    const { t } = useTranslation();

    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-expand") ||
            target.classList.contains("menu-expand")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };

    const currectLanguageCode = cookie.get("i18next") || "en";
    const currectLanguage = languages.find(
        (l) => l.code === currectLanguageCode
    );

    useEffect(() => {
        document.title = t("app_title");
    }, [currectLanguage, t]);

    return (
        <div className={`offcanvas-modal ${show ? "show" : ""}`}>
            <div className="offcanvas-dialog">
                <div className="menu-content">
                    <div className="offcanvas-header">
                        <Logo
                            classOption="offcanvas-logo d-inline-block"
                            image={`${process.env.PUBLIC_URL}/images/logo/JR_logo_sky.png`}
                        />
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onClose}
                        ></button>
                    </div>

                    <nav id="offcanvasNav" className="offcanvas-menu">
                        <ul>
                            <li>
                                <NavLink exact to="/">
                                    {t("Home")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={process.env.PUBLIC_URL + "/about"}>
                                    {t("About")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={process.env.PUBLIC_URL + "/service"}
                                >
                                    {t("Services")}
                                </NavLink>
                                <span
                                    className="menu-expand"
                                    onClick={onClickHandler}
                                    aria-hidden="true"
                                ></span>
                                <ul>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service"
                                            }
                                        >
                                            {t("Services-all")}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service-details/1"
                                            }
                                        >
                                            {t("Services-detail")}
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            {/* <li>
                                <NavLink to="/">{t("News")}</NavLink>
                                <span
                                    className="menu-expand"
                                    onClick={onClickHandler}
                                    aria-hidden="true"
                                ></span>
                                <ul>
                                    <li>
                                        <NavLink
                                            to={process.env.PUBLIC_URL + "/faq"}
                                        >
                                            faq
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL + "/team"
                                            }
                                        >
                                            team
                                        </NavLink>
                                    </li>
                                </ul>
                            </li> */}
                            <li>
                                <NavLink to={process.env.PUBLIC_URL + "/blog"}>
                                    {t("Insights")}
                                </NavLink>
                                {/* <span
                                    className="menu-expand"
                                    onClick={onClickHandler}
                                    aria-hidden="true"
                                ></span> */}
                                {/* <ul>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL + "/blog"
                                            }
                                        >
                                            blog
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/blog-left-sidebar"
                                            }
                                        >
                                            blog grid left sidebar
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/blog-right-sidebar"
                                            }
                                        >
                                            blog grid right sidebar
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/blog-details/1"
                                            }
                                        >
                                            blog details
                                        </NavLink>
                                    </li>
                                </ul> */}
                            </li>
                            <li>
                                <NavLink
                                    to={process.env.PUBLIC_URL + "/contact"}
                                >
                                    {t("Contact")}
                                </NavLink>
                            </li>
                            <div className="btn-container">
                                <button
                                    onClick={() => i18n.changeLanguage("en")}
                                    disabled={currectLanguageCode === "en"}
                                >
                                    <span
                                        className={`fi fi-gb m-0`}
                                        style={{ opacity: currectLanguageCode === "en" ? 0.5 : 1 }}
                                    ></span>
                                </button>
                                <button
                                    onClick={() => i18n.changeLanguage("nl")}
                                    disabled={currectLanguageCode === "nl"}
                                >
                                    <span
                                        className={`fi fi-nl m-0`}
                                        style={{ opacity: currectLanguageCode === "nl" ? 0.5 : 1 }}
                                    ></span>
                                </button>
                                <button
                                    onClick={() => i18n.changeLanguage("zh")}
                                    disabled={currectLanguageCode === "zh"}
                                >
                                    <span
                                        className={`fi fi-cn m-0`}
                                        style={{ opacity: currectLanguageCode === "zh" ? 0.5 : 1 }}
                                    ></span>
                                </button>
                            </div>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

MobileMenu.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

export default MobileMenu;
