import PropTypes from "prop-types";

const CustomModal = ({ show, message, onClose }) => {
    const handleCloseKeyPress = (event) => {
        if (event.key === "Enter" || event.key === " ") {
            onClose();
        }
    };


    return (
        <div className={`custom-modal ${show ? "show" : ""}`}>
            <div className="modal-content">
                <p>{message}</p>
                <button
                    className="close"
                    onClick={onClose}
                    onKeyDown={handleCloseKeyPress}
                    tabIndex={0}
                >
                    OK
                </button>
            </div>
        </div>
    );
};

CustomModal.propTypes = {
    show: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CustomModal;
